import React from "react";
import { Bullets } from "./Bullets";
import { SummaryTips } from "./Texts/SummaryTips";

export const ExplainTips = () => {
  return (
    <>
      <SummaryTips />
      <Bullets
        header="仕組み"
        texts={[
          "質問者は500円~50,000円のTipsを提示して質問を投稿することができます。",
          "回答者はTips付き質問に回答することでTips額からサービス手数料15%を差し引いた金額を得られます。",
        ]}
      />
      <Bullets
        header="収益の受け取り"
        texts={[
          "各月のQuerie Tips収益は、回答者のStripeアカウントにプールされ、Querie Limited収益を受領している場合はQuerie Limitedの収益も合算した上で月末残高が翌月末に回答者に引き渡されます。",
          "残高が10,000円以上の場合は残高から入金手数料450円を差し引いた金額が銀行口座へ振り込まれます。",
          "残高が10,000円未満の場合はAmazon残高分のギフトカードが電子メールで送付されます。",
        ]}
      />
      <Bullets
        header="その他詳細"
        texts={[
          "Tips付き質問を受けるためには、回答者はStripeアカウントを取得し、Stripeに対する本人確認手続を完了していただく必要があります。",
          "回答者がTipsを受け取るためには質問受領後7日間以内に回答を行う必要があります。この期間に回答を行わない場合、Tips付き質問は、通常の質問として取り扱われることになります。",
          "回答者は質問者に対して提示されたTipsの額を減額することができます。Tipsの受取りを放棄することも可能です。",
          "回答内容は回答時にTips質問者へ電子メールで送付されます。",
          "回答者は回答後に回答を削除することもできますが、Tips質問者へ送信された電子メールは取り消すことができません。",
          "質問募集者が消費税の納税義務者である場合、Tipsは消費税を含むものとして取り扱われます。",
        ]}
      />
    </>
  );
};
