import React from "react";
import { View, Text } from "react-native";
import { tailwind as tw } from "../tailwind";
import { SubmitButton } from "./Buttons/SubmitButton";

type Props = {
  onPressToRegister: () => void;
  onPressToDashboard: () => void;
};

export const IdNotVerified = ({
  onPressToRegister,
  onPressToDashboard,
}: Props) => (
  <>
    <Text
      style={tw(
        "bg-bluegray-800 text-center text-base text-white font-bold p-2"
      )}
    >
      Stripeアカウントの登録が完了していません
    </Text>
    <View style={tw("bg-white w-full p-2")}>
      <Text style={tw("text-base p-2 mb-2")}>
        Tips質問募集やLimited回答を開始するにはStripeアカウント作成が必要です。
      </Text>
      <Text style={tw("text-base p-2 mb-2")}>
        本人確認提出確認待ちの場合は通常24時間以内で確認が完了し、完了時にQuerie.meよりメールでお知らせいたしますので、しばしお待ち下さい。(24時間以上経過しても完了していない場合は下記Stripeダッシュボードリンクから状況をご確認ください。)
      </Text>
      <SubmitButton label="Stripe登録を続ける" onPress={onPressToRegister} />
      <SubmitButton
        label="Stripeダッシュボードへ"
        onPress={onPressToDashboard}
      />
    </View>
  </>
);
