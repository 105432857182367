import { QA } from "../types/question";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { TwitterRequestTokenResponse } from "../types/twitterAuthReqRes";

const KEY_NAME = "@twitter_request_token_response";

export const SetTwitterOAuthToken = async (
  twitterRequestTokenResponse: TwitterRequestTokenResponse
) => {
  await AsyncStorage.setItem(
    KEY_NAME,
    JSON.stringify(twitterRequestTokenResponse)
  );
};

export const DeleteTwitterOAuthToken = async () => {
  await AsyncStorage.removeItem(KEY_NAME);
};

export const GetTwitterOAuthToken = async () => {
  const twitterOAuthToken = await AsyncStorage.getItem(KEY_NAME);
  return twitterOAuthToken === null
    ? undefined
    : (JSON.parse(twitterOAuthToken) as TwitterRequestTokenResponse);
};
