import React from "react";
import { View, Text } from "react-native";
import { tailwind as tw } from "../../tailwind";

type Props = {
  sinceAsked: string;
};

export const SinceAskedIcon = ({ sinceAsked }: Props) => {
  return (
    <View style={tw(`border border-red-700 mr-2 mt-4 px-1`)}>
      <Text style={tw("text-xs text-red-700 text-center")}>{sinceAsked}</Text>
    </View>
  );
};
