import { PaymentSchedule } from "./../../querie-firebase/functions/src/types/paymentSchedule";
import {
  collection,
  query,
  where,
  limit,
  startAfter,
  orderBy,
  getDocs,
} from "firebase/firestore";
import { db } from "./Init";

export const GetPaymentSchedules = async (
  userId: string,
  startAfterMillis: number
): Promise<PaymentSchedule[]> => {
  const snapShot = await getDocs(
    query(
      collection(db, "payout_schedule"),
      where("userId", "==", userId),
      orderBy("earnedMonthMillis", "desc"),
      startAfter(startAfterMillis),
      limit(20)
    )
  );
  const paymentSchedule = snapShot.docs.map(
    (doc) => ({ ...doc.data(), id: doc.id } as PaymentSchedule)
  );
  return paymentSchedule;
};
