import { useEffect, useState } from "react";
import { GetProperTabButtonPosition, ShowToast } from "../lib";
import { ButtonOnUnansweredHeader } from "../components/Buttons/ButtonOnUnansweredHeader";
import { Platform, ScaledSize } from "react-native";
import { GetScreenSize } from "../screens/lib";
import { StackNavigationProp } from "@react-navigation/stack";
import { RootStackParamList } from "../navigation/types";
import { RequestButtonOnUnansweredHeader } from "../components/Buttons/RequestButtonOnUnansweredHeader";
import { User } from "../types/user";

export const UseFilterSortOnQuestionsScreen = (
  onRefresh: () => void,
  OnPressShare: () => void,
  window: ScaledSize,
  navigation: StackNavigationProp<RootStackParamList, "unanswered_questions">,
  user?: User
) => {
  const [sortedNewToOld, setSortedNewToOld] = useState<boolean>();
  const [filterActivated, setFilterActivated] = useState<boolean>();
  const screenSize = GetScreenSize(window);
  const properRequestTweetButtonPosition = GetProperTabButtonPosition(
    Platform.OS,
    screenSize
  );
  const isFilterShown =
    user?.id !== undefined &&
    [
      "guMHUAN0Parf6Qj31XpJhmEewqIB",
      "SqYu27QUpefaiY9bFzeTMKRo6LH3",
      "fh2PFcVeiofRvFN31VYezOxx5su1",
    ].includes(user.id);

  useEffect(() => {
    if (sortedNewToOld === undefined) {
      return;
    }
    ShowToast(
      "info",
      sortedNewToOld ? "新しい順に並び替えました" : "古い順に並び替えました"
    );
    onRefresh();
  }, [sortedNewToOld]);

  useEffect(() => {
    if (filterActivated === undefined) {
      return;
    }
    ShowToast(
      "info",
      filterActivated ? "無料質問だけ表示します" : "すべての質問を表示します"
    );
    onRefresh();
  }, [filterActivated]);

  useEffect(() => {
    let isMounted = true;
    isMounted &&
      navigation.setOptions({
        headerRight: () => {
          return (
            <ButtonOnUnansweredHeader
              sortedNewToOld={
                sortedNewToOld === undefined ? true : sortedNewToOld
              }
              filterActivated={
                filterActivated === undefined ? false : filterActivated
              }
              properButtonPosition={properRequestTweetButtonPosition}
              onPressSort={() =>
                setSortedNewToOld((sortedNewToOld) =>
                  sortedNewToOld === undefined ? false : !sortedNewToOld
                )
              }
              onPressFilter={() =>
                setFilterActivated((filterActivated) =>
                  filterActivated === undefined ? true : !filterActivated
                )
              }
              isFilterShown={isFilterShown}
            />
          );
        },
        headerLeft: () => {
          return properRequestTweetButtonPosition === "0vw" ||
            properRequestTweetButtonPosition === "0%" ? (
            <></>
          ) : (
            <RequestButtonOnUnansweredHeader
              onPress={OnPressShare}
              properButtonPosition={properRequestTweetButtonPosition}
            />
          );
        },
      });
    return () => {
      isMounted = false;
    };
  }, [properRequestTweetButtonPosition, sortedNewToOld, filterActivated]);
  return {
    sortedNewToOld,
    setSortedNewToOld,
    filterActivated,
  };
};
