import { doc, runTransaction } from "firebase/firestore";
import { Question } from "../types/question";
import { db } from "./Init";

type question_collection =
  | "questions_unanswered"
  | "questions_answered"
  | "questions_trashed";

export const ReportQuestion = async (
  question: Question,
  moveFrom: question_collection
) => {
  const questionId = question.id;
  if (questionId === null) {
    return undefined;
  }
  const fromReference = doc(db, moveFrom, questionId);
  await runTransaction(db, async (transaction) => {
    const snapShot = await transaction.get(fromReference);
    transaction.set(doc(db, "reports", questionId), snapShot.data());
  });
};
