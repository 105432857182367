import React from "react";
import { View, ActivityIndicator, Text } from "react-native";
import { tailwind as tw } from "../tailwind";

export const Loading = ({ color = "white", isPayment = false }) => {
  const bgColor = `bg-${color}`;
  return (
    <View
      style={tw(
        `h-full w-full items-center ${bgColor} bg-opacity-50 justify-center absolute`
      )}
    >
      <ActivityIndicator size="large" />
      {isPayment && (
        <View style={tw(`mt-2 items-center`)}>
          <Text>決済処理中です。</Text>
          <Text>しばらくお待ち下さい。</Text>
        </View>
      )}
    </View>
  );
};
