import React, { useContext, useState, useRef, useEffect } from "react";
import {
  FlatList,
  View,
  useWindowDimensions,
  SafeAreaView,
  Text,
} from "react-native";
import { useFocusEffect } from "@react-navigation/native"; // works
import { StackNavigationProp } from "@react-navigation/stack";
import { RootStackParamList } from "../navigation/types";
import { tailwind as tw } from "../tailwind";
import { AlertAndReportError, GetProperWidth } from "./lib";
import { Loading } from "../components/Loading";
import { UserContext } from "../contexts/userContext";
import { useScrollToTop } from "@react-navigation/native";
import { Timestamp } from "firebase/firestore";
import { PaymentSchedule } from "../types/paymentSchedule";
import { GetPaymentSchedules } from "../firebase/PaymentSchedule";
import { UseSetHeaderLeftGoBack } from "../hooks/useSetHeaderLeftGoBack";

type Props = {
  navigation: StackNavigationProp<RootStackParamList, "payout">;
};
export const PaymentScheduleScreen = ({ navigation }: Props) => {
  const window = useWindowDimensions();
  const properWidth = GetProperWidth(window);
  const widthString =
    properWidth.num === 1 && properWidth.denom === 1
      ? "w-full"
      : `w-${properWidth.num}/${properWidth.denom}`;
  const [paymentSchedules, setPaymentSchedules] = useState<PaymentSchedule[]>(
    []
  );
  const [loading, setLoading] = useState(false);
  const startAfterRef = useRef(Timestamp.now().toMillis());
  const { user } = useContext(UserContext);

  UseSetHeaderLeftGoBack(navigation, () => {
    navigation.navigate("home", { screen: "account_setting", params: {} });
  });
  useFocusEffect(
    React.useCallback(() => {
      startAfterRef.current = Timestamp.now().toMillis();
      fetchPaymentSchedules(startAfterRef.current);
    }, [])
  );

  const fetchPaymentSchedules = async (startAfter: number) => {
    if (user === undefined) {
      return;
    }
    if (user.id === undefined) {
      return;
    }
    setLoading(true);
    const additional = await GetPaymentSchedules(user.id, startAfter).catch(
      (e) => {
        console.log(e);
        AlertAndReportError(e, "GetQuestions on QA Screen");
        return [];
      }
    );
    if (additional.length > 0) {
      setPaymentSchedules((paymentSchedules) => [
        ...paymentSchedules,
        ...additional,
      ]);
      startAfterRef.current =
        additional[additional.length - 1].earnedMonthMillis;
    }
    setLoading(false);
    [...paymentSchedules, ...additional].length === 0 &&
      alert("金額が確定した支払い予定はありません。");
  };

  const onEndReached = () => {
    if (loading) return;
    fetchPaymentSchedules(startAfterRef.current);
  };

  const ref = React.useRef(null);
  useScrollToTop(ref);

  type Props = {
    paymentSchedule: PaymentSchedule;
  };
  const PaymentScheduleCard = ({ paymentSchedule }: Props) => {
    const paidMonthMillisDate = Timestamp.fromMillis(
      paymentSchedule.paidMonthMillis
    ).toDate();
    const earnedMonthMillisDate = Timestamp.fromMillis(
      paymentSchedule.earnedMonthMillis
    ).toDate();
    const bankDepositedMonth = new Date(
      paidMonthMillisDate.getFullYear(),
      paidMonthMillisDate.getMonth() + 1,
      1
    );
    return paymentSchedule.paymentMethod === "amazon" ? (
      <View style={tw("bg-white p-2 mx-4 my-2")}>
        <Text style={tw("pl-2 text-base text-black flex-1 font-bold")}>
          {earnedMonthMillisDate.getFullYear()}年
          {earnedMonthMillisDate.getMonth() + 1}
          月確定収益
        </Text>
        <Text style={tw("pl-2 text-sm text-black flex-1")}>
          金額:¥{paymentSchedule.amount.toLocaleString()}
        </Text>

        <Text style={tw("pl-2 text-sm text-black flex-1")}>
          お支払い方法:Amazonギフト券メール送付
        </Text>
        <Text style={tw("pl-2 text-sm text-black flex-1")}>
          着金予定日: {paidMonthMillisDate.getFullYear()}年
          {paidMonthMillisDate.getMonth() + 1}
          月最終営業日
        </Text>
        <Text style={tw("pl-2 text-sm text-black flex-1")}>
          ステータス:{paymentSchedule.paid ? "送付済み" : "未送付"}
        </Text>
      </View>
    ) : (
      <View style={tw("bg-white p-2 mx-4 my-2")}>
        <Text style={tw("pl-2 text-base text-black flex-1 font-bold")}>
          {earnedMonthMillisDate.getFullYear()}年
          {earnedMonthMillisDate.getMonth() + 1}
          月確定収益
        </Text>
        <Text style={tw("pl-2 text-sm text-black flex-1")}>
          金額:¥{paymentSchedule.amount.toLocaleString()}
        </Text>

        <Text style={tw("pl-2 text-xs text-black flex-1")}>
          (上記金額から入金手数料¥450を差し引いてお振り込み)
        </Text>

        <Text style={tw("pl-2 text-sm text-black flex-1")}>
          お支払い方法:銀行振込
        </Text>
        <Text style={tw("pl-2 text-sm text-black flex-1")}>
          着金予定日:${bankDepositedMonth.getFullYear()}年
          {bankDepositedMonth.getMonth() + 1}月第1週
        </Text>
        <Text style={tw("pl-2 text-sm text-black flex-1")}>
          ステータス:{paymentSchedule.paid ? "出金処理済み" : "未出金"}
        </Text>
        {paymentSchedule.paid && (
          <Text style={tw("pl-2 text-xs text-black flex-1")}>
            (出金処理後、登録口座着金まで数日かかります)
          </Text>
        )}
      </View>
    );
  };

  return (
    <SafeAreaView style={tw("w-full items-center bg-gray-300 flex-1")}>
      <View style={tw(`${widthString} flex flex-grow bg-gray-300 h-full`)}>
        <FlatList
          ListHeaderComponent={<View style={tw("py-1")} />}
          data={paymentSchedules}
          renderItem={({ item }) => {
            return <PaymentScheduleCard paymentSchedule={item} />;
          }}
          keyExtractor={(_, index) => index.toString()}
          onEndReached={onEndReached}
          ref={ref}
          removeClippedSubviews={true}
        />
        {loading && <Loading />}
      </View>
    </SafeAreaView>
  );
};
