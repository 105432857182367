import { doc, setDoc, Timestamp } from "firebase/firestore";
import { LastMembershipEndEmailNotif } from "../types/lastMembershipEndEmailNotif";
import { db } from "./Init";

export const ScheduleLastMembershipEndEmailNotif = async (
  userId: string,
  lastMembershipEndAtMilSec: number
) => {
  const lastMembershipEndEmailNotif: LastMembershipEndEmailNotif = {
    limitedHostUserId: userId,
    createdAtMilSec: Timestamp.now().toMillis(),
    lastMembershipEndAtMilSec,
    sent: false,
  };
  await setDoc(
    doc(
      db,
      "last_membership_end_email_notif",
      lastMembershipEndEmailNotif.limitedHostUserId
    ),
    {
      ...lastMembershipEndEmailNotif,
    }
  );
};
