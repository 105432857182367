import AsyncStorage from "@react-native-async-storage/async-storage";

const KEY_NAME = "@will_show_disable_share_dialog";

export const SetWillShowDisableShareDialog = async (
  willShowDisableShareDialog: boolean
) => {
  await AsyncStorage.setItem(KEY_NAME, String(willShowDisableShareDialog));
};

export const GetWillShowDisableShareDialog = async () => {
  const willShowDisableShareDialog = await AsyncStorage.getItem(KEY_NAME);
  return willShowDisableShareDialog === null
    ? true
    : willShowDisableShareDialog === "true";
};
