import {
  FlatList,
  SafeAreaView,
  Text,
  TouchableOpacity,
  useWindowDimensions,
  View,
} from "react-native";
import { tailwind as tw } from "../tailwind";
import { RouteProp } from "@react-navigation/core";
import { StackNavigationProp } from "@react-navigation/stack";
import { RootStackParamList } from "../navigation/types";
import { useEffect, useState } from "react";
import { UseSetHeaderLeftGoBack } from "../hooks/useSetHeaderLeftGoBack";
import {
  GetProperWidth,
  OnPressSubmitAnswer,
  ProcessAnswerViaWebIntent,
  SubmitAnswer,
} from "./lib";
import { SubmitButton } from "../components/Buttons/SubmitButton";
import { AnswerSubmissionFuncs } from "../types/answerSubmission";
import { Loading } from "../components/Loading";
import { DialogBoxWithUpToTwoOptions } from "../components/DialogBoxWithUpToTwoOptions";
import { ApiPostRateLimitedHitDialog } from "../components/Dialogs/ApiPostRateLimitedHitDialog";
import { Timestamp } from "firebase/firestore";
import { SetWillShowApiPostRateLimitHitDialog } from "../local_storage/willShowApiPostRateLimitHitDialog";
import { HasAnsweredLimitedOnly } from "../firebase/Questions";

type Props = {
  navigation: StackNavigationProp<RootStackParamList, "answer_limited_only">;
  route: RouteProp<RootStackParamList, "answer_limited_only">;
};
export const AnswerLimitedOnlyScreen: React.FC<Props> = ({
  navigation,
  route,
}) => {
  const window = useWindowDimensions();
  const properWidth = GetProperWidth(window);
  const widthString =
    properWidth.num === 1 && properWidth.denom === 1
      ? "w-full"
      : `w-${properWidth.num}/${properWidth.denom}`;
  UseSetHeaderLeftGoBack(navigation, navigation.goBack);
  const [answerSubmissionVals, setAnswerSubmissionVals] = useState(
    route.params.answerSubmissionVals
  );
  const [thresholdLineIndex, setThreshholdLineIndex] = useState<number>();
  const [loading, setLoading] = useState(false);
  const [loadingPayment, setLoadingPayment] = useState(false);
  const [isAlertDialogShownTwoOptions, setIsAlertDialogShownTwoOptions] =
    useState(false);
  const [isApiPostRateLimitHitDialog, setIsApiPostRateLimitHitDialog] =
    useState(false);
  const [hasAnsweredLimitedOnly, setHasAnsweredLimitedOnly] = useState(false);

  const answerLines = route.params.answerSubmissionVals.answerText
    .split("\n")
    .reduce(
      (acc: string[], val, index) =>
        index === 0
          ? [val]
          : val === ""
          ? [...acc.slice(0, acc.length - 1), acc[acc.length - 1] + "\n"]
          : [...acc, val],
      []
    );

  useEffect(() => {
    thresholdLineIndex !== undefined &&
      setAnswerSubmissionVals({
        ...answerSubmissionVals,
        answerText: answerLines.slice(0, thresholdLineIndex).join("\n"),
        answerTextLimitedOnly: answerLines.slice(thresholdLineIndex).join("\n"),
      });
  }, [thresholdLineIndex]);

  useEffect(() => {
    const userId = answerSubmissionVals.user?.id;
    userId !== undefined &&
      HasAnsweredLimitedOnly(userId).then((hasAnsweredLimitedOnly) => {
        console.log("hasAnsweredLimitedOnly", hasAnsweredLimitedOnly);
        setHasAnsweredLimitedOnly(hasAnsweredLimitedOnly);
      });
  }, [answerSubmissionVals.questionId]);

  const navigateToHome = () => {
    navigation.navigate("home", {
      screen: "unanswered_questions",
      params: {
        questionToRemove: answerSubmissionVals.questionId,
        showAlertType:
          hasAnsweredLimitedOnly === false
            ? "firstLimitedOnlyAnswer"
            : undefined,
      },
    });
  };

  const answerSubmissionFuncs: AnswerSubmissionFuncs = {
    setLoading,
    setLoadingPayment,
    setIsAlertDialogShownTwoOptions,
    setIsSharePrivateAnsweredDialog: () => {},
    navigateOnFailure: navigateToHome,
    navigateToHome,
    setIsApiPostRateLimitHitDialog,
  };

  type Props = { onPress: () => void };
  const LineNotSelected = ({ onPress }: Props) => (
    <TouchableOpacity
      style={tw("my-2 border border-gray-300 rounded-full")}
      onPress={onPress}
    >
      <Text style={tw("text-base text-center text-gray-600")}>
        ラインをこの改行位置に設定
      </Text>
    </TouchableOpacity>
  );

  const LineSelected = () => (
    <View style={tw(" my-2 border bg-bluegray-800")}>
      <Text style={tw("font-bold text-base text-center text-white")}>
        以下範囲をLimited限定で公開
      </Text>
    </View>
  );
  const TipsExpiredDialog = () => (
    <DialogBoxWithUpToTwoOptions
      title={"Tips期限切れによりTipsが発生しません。"}
      widthString={widthString}
      message={"無料で回答してよろしいですか？"}
      buttons={[
        {
          label: "ＯＫ",
          onPress: () => {
            SubmitAnswer(answerSubmissionVals, answerSubmissionFuncs);
            setIsAlertDialogShownTwoOptions(false);
          },
        },
        {
          label: "キャンセル",
          onPress: () => {
            setIsAlertDialogShownTwoOptions(false);
          },
        },
      ]}
      isShown={isAlertDialogShownTwoOptions}
    />
  );
  const ThisApiPostRateLimitHitDialog = () => {
    const onPressBase = async () => {
      await ProcessAnswerViaWebIntent(
        answerSubmissionVals.questionId,
        answerSubmissionVals.answerText,
        true,
        answerSubmissionVals.user!,
        Timestamp.now().seconds,
        undefined,
        answerSubmissionVals.replyToQA?.tweetId === null
          ? undefined
          : answerSubmissionVals.replyToQA?.tweetId
      );
      setIsApiPostRateLimitHitDialog(false);
      navigateToHome();
    };
    return (
      <ApiPostRateLimitedHitDialog
        isShown={isApiPostRateLimitHitDialog}
        onPresses={[
          onPressBase,
          async () =>
            await onPressBase().then(() =>
              SetWillShowApiPostRateLimitHitDialog(false)
            ),
        ]}
        widthString={widthString}
      />
    );
  };

  return loading ? (
    <Loading />
  ) : loadingPayment ? (
    <Loading isPayment={true} />
  ) : (
    <SafeAreaView style={tw("bg-white w-full items-center flex-1")}>
      <View style={tw(`${widthString} flex flex-grow h-full p-4`)}>
        <FlatList
          data={answerLines}
          ListFooterComponent={() => (
            <View style={tw("mb-2 -mx-2")}>
              <SubmitButton
                label="範囲を確定して投稿"
                onPress={async () => {
                  if (thresholdLineIndex === undefined) {
                    alert(
                      "Limited限定公開範囲が指定されていません。境界となる改行位置のラインを設定してください。"
                    );
                    return;
                  }
                  await OnPressSubmitAnswer(
                    answerSubmissionVals,
                    answerSubmissionFuncs
                  );
                }}
              />
            </View>
          )}
          renderItem={(answerLine) => {
            return (
              <View>
                {thresholdLineIndex === answerLine.index ? (
                  <LineSelected />
                ) : (
                  <LineNotSelected
                    onPress={() => {
                      setThreshholdLineIndex(answerLine.index);
                    }}
                  />
                )}
                <Text style={tw("bg-white my-2 text-lg")}>
                  {answerLine.item}
                </Text>
              </View>
            );
          }}
          keyExtractor={(_, index) => index.toString()}
        />
      </View>
      <TipsExpiredDialog />
      <ThisApiPostRateLimitHitDialog />
    </SafeAreaView>
  );
};
