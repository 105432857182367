import React, { useState, useContext, useEffect } from "react";
import {
  Text,
  ScrollView,
  SafeAreaView,
  useWindowDimensions,
  View,
} from "react-native";
import { SettingDetailItem } from "../components/SettingDetailItem";
import { tailwind as tw } from "../tailwind";
import * as local_storage from "../local_storage/tweetDeletionSetting";
import { tweetDeletionOption } from "../types/tweetDeletionOption";
import { useFocusEffect } from "@react-navigation/native"; // works
import { GetUserActive, UpdateUser } from "../firebase/Users";
import { UserContext } from "../contexts/userContext";
import { User, userDefault } from "../types/user";
import { AlertAndReportError, GetProperWidth } from "./lib";
import { StackNavigationProp } from "@react-navigation/stack";
import { RootStackParamList } from "../navigation/types";
import { SettingSwitch } from "../components/SettingSwitch";
import { UseSetHeaderLeftGoBack } from "../hooks/useSetHeaderLeftGoBack";

type Props = {
  navigation: StackNavigationProp<RootStackParamList, "terms">;
};

export const SettingsDetailScreen = ({ navigation }: Props) => {
  const window = useWindowDimensions();
  const properWidth = GetProperWidth(window);
  const widthString =
    properWidth.num === 1 && properWidth.denom === 1
      ? "w-full"
      : `w-${properWidth.num}/${properWidth.denom}`;
  const [tweetDeletionPolicyOnDelete, setTweetDeletionPolicyOnDelete] =
    useState<tweetDeletionOption>();
  const [tweetDeletionPolicyOnModify, setTweetDeletionPolicyOnModify] =
    useState<tweetDeletionOption>();
  const { user } = useContext(UserContext);
  const [userOnDB, setUserOnDB] = useState<User>(userDefault);
  const [pushAllowed, setPushAllowed] = useState(false);
  const [autoQuestionRequestEnabled, setAutoQuestionRequestEnabled] =
    useState(false);
  const [searchAllowed, setSearchAllowed] = useState(false);
  const [hashTagPosted, setHashTagPosted] = useState(false);

  const tweetDeletionOptions = [
    "毎回確認する",
    "常に削除しない",
    "常に削除する",
  ] as tweetDeletionOption[];

  UseSetHeaderLeftGoBack(navigation, () => {
    navigation.navigate("home", { screen: "account_setting", params: {} });
  });

  useFocusEffect(
    React.useCallback(() => {
      const initializeSettings = async () => {
        const userReceived = await GetUserActive(user!.id).catch((e) => {
          AlertAndReportError(e, "GetUserActive on QuestiSettingsDetailScreen");
          return userDefault;
        });
        if (userReceived === undefined) {
          return;
        }
        setUserOnDB(userReceived);
        setPushAllowed(userReceived.pushAllowed!);
        setAutoQuestionRequestEnabled(userReceived.autoQuestionRequestEnabled!);
        setSearchAllowed(
          userReceived.searchAllowed === undefined
            ? false
            : userReceived.searchAllowed
        );
        setHashTagPosted(
          userReceived.hashTagPosted === undefined
            ? true
            : userReceived.hashTagPosted
        );

        const settingOnDelete = await local_storage
          .getTweetDeletionSetting("Delete")
          .catch((e) => {
            AlertAndReportError(
              e,
              "getTweetDeletionSetting on QuestiSettingsDetailScreen"
            );
            return null;
          });
        if (settingOnDelete === null) {
          setTweetDeletionPolicyOnDelete("毎回確認する");
        } else {
          if (!settingOnDelete.alwaysApply) {
            setTweetDeletionPolicyOnDelete("毎回確認する");
          } else {
            settingOnDelete.willDelete
              ? setTweetDeletionPolicyOnDelete("常に削除する")
              : setTweetDeletionPolicyOnDelete("常に削除しない");
          }
        }
        const settingOnModify = await local_storage
          .getTweetDeletionSetting("Modify")
          .catch((e) => {
            AlertAndReportError(
              e,
              "settingOnModify on QuestiSettingsDetailScreen"
            );
            return null;
          });
        if (settingOnModify === null) {
          setTweetDeletionPolicyOnModify("毎回確認する");
        } else {
          if (!settingOnModify.alwaysApply) {
            setTweetDeletionPolicyOnModify("毎回確認する");
          } else {
            settingOnModify.willDelete
              ? setTweetDeletionPolicyOnModify("常に削除する")
              : setTweetDeletionPolicyOnModify("常に削除しない");
          }
        }
      };
      initializeSettings();
    }, [])
  );

  const onPressDeleteOption = (tweetDeletionOption: tweetDeletionOption) => {
    setTweetDeletionPolicyOnDelete(tweetDeletionOption);
    switch (tweetDeletionOption) {
      case "毎回確認する":
        local_storage.removeTweetDeletionSetting("Delete");
        break;
      case "常に削除しない":
        local_storage.setTweetDeletionSetting("Delete", {
          willDelete: false,
          alwaysApply: true,
        });
        break;
      case "常に削除する":
        local_storage.setTweetDeletionSetting("Delete", {
          willDelete: true,
          alwaysApply: true,
        });
        break;
      default:
        break;
    }
  };
  const onPressModify = (tweetDeletionOption: tweetDeletionOption) => {
    setTweetDeletionPolicyOnModify(tweetDeletionOption);
    switch (tweetDeletionOption) {
      case "毎回確認する":
        local_storage.removeTweetDeletionSetting("Modify");
        break;
      case "常に削除しない":
        local_storage.setTweetDeletionSetting("Modify", {
          willDelete: false,
          alwaysApply: true,
        });
        break;
      case "常に削除する":
        local_storage.setTweetDeletionSetting("Modify", {
          willDelete: true,
          alwaysApply: true,
        });
        break;
      default:
        break;
    }
  };

  return (
    <SafeAreaView style={tw("w-full items-center bg-gray-300 flex-1")}>
      <ScrollView
        style={tw(`${widthString} flex flex-grow bg-gray-300 h-full`)}
      >
        <View style={tw("mx-4 mt-2")}>
          {!!userOnDB!.pushToken && (
            <SettingSwitch
              labels={["質問受領時のプッシュ通知"]}
              key={1}
              value={pushAllowed}
              onValueChange={async () => {
                const isAllowed = !pushAllowed;
                setPushAllowed(isAllowed);
                await UpdateUser(user!.id!, { pushAllowed: isAllowed }).catch(
                  (e) => {
                    AlertAndReportError(
                      e,
                      "UpdateUser2 on QuestiSettingsDetailScreen"
                    );
                  }
                );
              }}
            />
          )}
          {autoQuestionRequestEnabled !== undefined && (
            <SettingSwitch
              labels={["自動質問募集ツイート", "(最終回答2日後に投稿)"]}
              key={2}
              value={autoQuestionRequestEnabled}
              onValueChange={async () => {
                const isAllowed = !autoQuestionRequestEnabled;
                setAutoQuestionRequestEnabled(isAllowed);
                await UpdateUser(user!.id!, {
                  autoQuestionRequestEnabled: isAllowed,
                }).catch((e) => {
                  AlertAndReportError(
                    e,
                    "UpdateUser1 on QuestiSettingsDetailScreen"
                  );
                });
              }}
            />
          )}

          <SettingSwitch
            labels={["閲覧者の質問回答検索許可"]}
            key={3}
            value={searchAllowed}
            onValueChange={async () => {
              const isAllowed = !searchAllowed;
              setSearchAllowed(isAllowed);
              await UpdateUser(user!.id!, {
                searchAllowed: isAllowed,
              }).catch((e) => {
                AlertAndReportError(
                  e,
                  "UpdateUser3 on QuestiSettingsDetailScreen"
                );
              });
            }}
          />
          <SettingSwitch
            labels={["固有ハッシュタグ投稿"]}
            key={4}
            value={hashTagPosted}
            onValueChange={async () => {
              const isPosted = !hashTagPosted;
              setHashTagPosted(isPosted);
              await UpdateUser(user!.id!, {
                hashTagPosted: isPosted,
              }).catch((e) => {
                AlertAndReportError(
                  e,
                  "UpdateUser4 on QuestiSettingsDetailScreen"
                );
              });
            }}
          />
        </View>

        <Text style={tw("text-xl mt-4 ml-4")}>
          回答削除時のTwitter投稿の削除
        </Text>
        {tweetDeletionOptions.map((x, idx) => (
          <SettingDetailItem
            key={idx.toString()}
            option={x}
            onPress={() => onPressDeleteOption(x)}
            isActive={x === tweetDeletionPolicyOnDelete}
          />
        ))}

        <Text style={tw("text-xl mt-4 ml-4")}>
          未回答化時の回答時Twitter投稿の削除
        </Text>
        {tweetDeletionOptions.map((x, idx) => (
          <SettingDetailItem
            key={idx.toString()}
            option={x}
            onPress={() => onPressModify(x)}
            isActive={x === tweetDeletionPolicyOnModify}
          />
        ))}
      </ScrollView>
    </SafeAreaView>
  );
};
