import { tweetDeletionSetting } from "../types/tweetDeletionSetting";
import AsyncStorage from "@react-native-async-storage/async-storage";

type setting = "Modify" | "Delete" | undefined;

export const setTweetDeletionSetting = async (
  setting: setting,
  tweetDeletionSettingToSave: tweetDeletionSetting
) => {
  await AsyncStorage.setItem(
    `@tweetDeletionSettingOn${setting}`,
    JSON.stringify(tweetDeletionSettingToSave)
  );
};

export const getTweetDeletionSetting = async (setting: setting) => {
  const tweetDeletionSetting = await AsyncStorage.getItem(
    `@tweetDeletionSettingOn${setting}`
  );
  return tweetDeletionSetting === null
    ? null
    : (JSON.parse(tweetDeletionSetting) as tweetDeletionSetting);
};

export const removeTweetDeletionSetting = async (setting: setting) => {
  await AsyncStorage.removeItem(`@tweetDeletionSettingOn${setting}`);
};
