import { Timestamp } from "firebase/firestore";
import { TipsLimitDaily } from "./tipsLimitDaily";

export type User = {
  id?: string;
  name: string;
  twitterId: string;
  screenName?: string;
  profile: string;
  message: string;
  iconImageUrl: string;
  pushToken?: string;
  pushAllowed?: boolean;
  autoQuestionRequestEnabled?: boolean;
  searchAllowed?: boolean;
  lastAnswered?: Timestamp;
  countAnswered?: number;
  countUnanswered?: number;
  tipsAccepted: number[];
  tipsPrivateAccepted?: number[];
  tipsPolicy: string;
  limitedPolicy: string;
  isMailNotifActivated?: boolean;
  tipsLimitDaily?: TipsLimitDaily;
  hasShownLimitedInvite?: boolean;
  hashTagPosted?: boolean;
};

export const userDefault = {
  name: "",
  twitterId: "",
  profile: "",
  message: "",
  id: "",
  // TODO:set the url to remotely saved icon
  iconImageUrl: "loading...",
} as User;
