import AsyncStorage from "@react-native-async-storage/async-storage";

type booleanString = "true" | "false";

export const SetPushNeverAsk = async (pushNeverAsk: boolean) => {
  const pushNeverAskString = booleanToString(pushNeverAsk);
  await AsyncStorage.setItem("@pushNeverAsk", pushNeverAskString);
};

export const GetPushNeverAsk = async () => {
  const pushNeverAskString = await AsyncStorage.getItem("@pushNeverAsk");
  if (pushNeverAskString === null) {
    return false;
  } else {
    const pushNeverAsk = stringToBoolean(pushNeverAskString as booleanString);
    return pushNeverAsk;
  }
};

const booleanToString = (boolean: boolean) => {
  return boolean ? "true" : "false";
};

const stringToBoolean = (booleanString: booleanString) => {
  return booleanString === "true";
};
