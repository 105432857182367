import React from "react";
import { View } from "react-native";
import { tailwind as tw } from "../../tailwind";
import { SubmitButton } from "./SubmitButton";

type buttonProps = {
  label: string;
  text: string;
  onPress: () => void;
};
export const ButtonsOnAnswerScreen = ({
  label,
  text,
  onPress,
}: buttonProps) => {
  return (
    <View style={tw("h-16 mx-2")}>
      <SubmitButton
        label={label}
        onPress={() => {
          text.length > 0
            ? onPress()
            : alert("回答が入力されていません。\n回答を入力して下さい。");
        }}
      />
    </View>
  );
};
