import React from "react";
import { View, Text } from "react-native";
import { tailwind as tw } from "../../tailwind";

export const NewIcon = () => {
  return (
    <View style={tw("bg-red-700 mr-2 mt-4 px-1")}>
      <Text style={tw("text-xs text-white text-center")}>New!</Text>
    </View>
  );
};
