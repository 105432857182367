import { MultiAccountAuth } from "../types/multiAccountAuth";
import { doc, getDoc } from "firebase/firestore";
import { db } from "./Init";

export const GetMultiAccountAuth = async (twitterId: string) => {
  const snapShot = await getDoc(doc(db, "multi_account_authes", twitterId));
  const data = snapShot.data();
  if (data === undefined) {
    return undefined;
  }
  const multiAccountAuth = data as MultiAccountAuth;
  return multiAccountAuth;
};
