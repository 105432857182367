import React, { useEffect, useState } from "react";
import { SafeAreaView, Image, Text, View } from "react-native";
import { tailwind as tw } from "../tailwind";
import { MaintenanceConfig } from "../types/remoteConfig";
import { GetMaintenanceConfig } from "../firebase/RemoteConfig";
import { Loading } from "../components/Loading";

export const MaintenanceScreen = () => {
  const [loading, setLoading] = useState(true);
  const [maintenanceConfig, setMaintenanceConfig] =
    useState<MaintenanceConfig>();
  useEffect(() => {
    let isMounted = true;
    const loadMaintenanceConfig = async () => {
      setLoading(true);
      const maintenanceConfig = await GetMaintenanceConfig();
      setMaintenanceConfig(maintenanceConfig);
      setLoading(false);
    };
    isMounted && loadMaintenanceConfig();
    return () => {
      isMounted = false;
    };
  }, []);

  return loading ? (
    <Loading />
  ) : (
    <View style={tw("w-full h-full items-center")}>
      <View
        style={tw(
          "w-full h-full bg-bluegray-800 flex-1 items-center justify-center"
        )}
      >
        <Image source={require("../assets/icon.png")} style={tw("h-72 w-72")} />

        <View style={tw("h-12 w-72 mb-6")}>
          <Image
            source={require("../assets/logo.png")}
            style={tw("h-full w-full")}
          />
        </View>
        <Text style={tw("text-white w-72 text-xl mt-2 text-center font-bold")}>
          {"現在メンテナンス中です。"}
        </Text>
        <Text style={tw("text-white text-center w-80 mt-2 font-bold")}>
          {maintenanceConfig!.message}
        </Text>
      </View>
    </View>
  );
};
