import React from "react";
import { View, Text } from "react-native";
import { tailwind as tw } from "../../tailwind";

export const SummaryTips = () => {
  return (
    <View style={tw("mb-6 w-full")}>
      <Text
        style={tw(
          "font-bold text-xl mb-2 bg-bluegray-800 text-white text-center w-full p-2"
        )}
      >
        Querie Tipsとは
      </Text>

      <Text style={tw("text-lg text-center mb-2")}>
        回答者が自分に寄せられた質問に回答することで、質問者から報酬(Tips)を受け取れる仕組みです
      </Text>
    </View>
  );
};
