import React from "react";
import { View, Text, TouchableOpacity } from "react-native";
import { Feather } from "@expo/vector-icons";
import { tailwind as tw } from "../tailwind";
import { MultiChoiceActionButton } from "./Buttons/MultiChoiceActionButton";

type Props = {
  isReportChosen: boolean;
  isBlockChosen: boolean;
  isDeleteChosen: boolean;
  isExecActive: boolean;
  onPressReport: () => void;
  onPressBlock: () => void;
  onPressDelete: () => void;
  onPressExec: () => void;
  onPressClose: () => void;
};

export const MultiChoiceActions = ({
  isReportChosen,
  isBlockChosen,
  isDeleteChosen,
  isExecActive,
  onPressReport,
  onPressBlock,
  onPressDelete,
  onPressExec,
  onPressClose,
}: Props) => {
  return (
    <>
      <View
        style={tw(
          "flex-row flex px-4 h-12 w-full absolute bottom-14 justify-between bg-transparent"
        )}
      >
        <View style={tw("w-1/3 pr-1")}>
          <MultiChoiceActionButton
            onPress={onPressReport}
            label="報告"
            isActive={isReportChosen}
          />
        </View>
        <View style={tw("w-1/3 px-1")}>
          <MultiChoiceActionButton
            onPress={onPressBlock}
            label="ブロック"
            isActive={isBlockChosen}
          />
        </View>
        <View style={tw("w-1/3 pl-1")}>
          <MultiChoiceActionButton
            onPress={onPressDelete}
            label="削除"
            isActive={isDeleteChosen}
          />
        </View>
      </View>
      <View style={tw("flex-row")}>
        <View style={tw("mr-4 w-full absolute bottom-4")}>
          <TouchableOpacity
            style={tw(
              `px-4 ml-4 mr-16 h-10 rounded-2xl items-center justify-center ${
                isExecActive
                  ? "bg-bluegray-800"
                  : "bg-white border-2 border-bluegray-800 "
              }`
            )}
            onPress={onPressExec}
          >
            <Text
              style={tw(
                `text-center text-base ${
                  isExecActive ? "text-white" : "text-bluegray-800"
                }`
              )}
            >
              実行
            </Text>
          </TouchableOpacity>
        </View>
        <View style={tw("absolute bottom-4 right-4")}>
          <TouchableOpacity
            style={tw(
              `rounded-full bg-bluegray-800 h-10 w-10 border-2 border-bluegray-800 items-center justify-center`
            )}
            onPress={onPressClose}
          >
            <Feather name="x" color={"white"} size={20} style={tw("m-2 ")} />
          </TouchableOpacity>
        </View>
      </View>
    </>
  );
};
