import React from "react";
import { View, Text, TouchableOpacity } from "react-native";
import { tailwind as tw } from "../../tailwind";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { User } from "../../types/user";

type Props = {
  onPress: (user: User) => Promise<void>;
  user: User;
};

export const TwitterShareButton = ({ onPress, user }: Props) => {
  return (
    <TouchableOpacity
      onPress={() => onPress(user)}
      style={tw("rounded-full bg-bluegray-800 p-2 m-4 items-center")}
    >
      <View style={tw("flex-row justify-center")}>
        <MaterialCommunityIcons name="twitter" size={36} color="white" />
        <Text
          style={tw(
            "text-white justify-center items-center font-bold text-xl ml-2 leading-10"
          )}
        >
          質問を募集する
        </Text>
      </View>
    </TouchableOpacity>
  );
};
