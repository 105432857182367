import React, { useState, useRef, useContext, useEffect } from "react";
import { Alert, AppState, AppStateStatus } from "react-native";
import { UserContext } from "../contexts/userContext";
import { createStackNavigator } from "@react-navigation/stack";
import { HomeTabs } from "./HomeTabs";
import { AnswerScreen } from "../screens/AnswerScreen";
import { AnswerLimitedOnlyScreen } from "../screens/AnswerLimitedOnlyScreen";
import { TargetAnswerChoiceScreen } from "../screens/TargetAnswerChoiceScreen";
import { ProfileSettingScreen } from "../screens/ProfileSettingScreen";
import { SettingsDetailScreen } from "../screens/SettingsDetailScreen";
import { TrashScreen } from "../screens/TrashScreen";
import { MultiChoiceScreen } from "../screens/MultiChooseScreen";
import { Platform } from "react-native";
import {
  GetForceUpdateConfig,
  GetMaintenanceConfig,
} from "../firebase/RemoteConfig";
import {
  ForceUpdateConfigByOS,
  MaintenanceConfig,
} from "../types/remoteConfig";
import { MaintenanceScreen } from "../screens/MaintenanceScreen";
import sember from "semver";
import Constants from "expo-constants";
import * as Linking from "expo-linking";
import * as Notifications from "expo-notifications";
import { IsBanned } from "../firebase/Banned";
import { ShowToast } from "../lib";
import { PaymentScheduleScreen } from "../screens/PaymentScheduleScreen";
import { getAuth } from "firebase/auth";
import { GetUserActive } from "../firebase/Users";
import { TipsExplainScreen } from "../screens/tips/TipsExplainScreen";
import { TipsSettingsScreen } from "../screens/tips/TipsSettingsScreen";
import { TipsEmailRegistration } from "../screens/tips/TipsEmailRegistration";
import { TipsStripeRegistration } from "../screens/tips/TipsStripeRegistration";
import { LimitedExplainScreen } from "../screens/limited/LimitedExplainScreen";
import { LimitedSettingsScreen } from "../screens/limited/LimitedSettingsScreen";
import { LimitedEmailRegistration } from "../screens/limited/LimitedEmailRegistration";
import { LimitedStripeRegistration } from "../screens/limited/LimitedStripeRegistration";
import { LimitedTerminateScreen } from "../screens/limited/LimitedTerminateScreen";
import { BlockedScreen } from "../screens/BlockedScreen";

const Stack = createStackNavigator();
export const AppContainer = () => {
  const appState = useRef(AppState.currentState);
  const [maintenanceConfig, setMaintenanceConfig] =
    useState<MaintenanceConfig>();
  const [appStateVisible, setAppStateVisible] = useState(appState.current);
  const [alertShown, setAlertShown] = useState(false);
  const { user, setUser } = useContext(UserContext);

  useEffect(() => {
    let isMounted = true;
    isMounted && AppState.addEventListener("change", _handleAppStateChange);
    isMounted && loadConfig();
    return () => {
      isMounted = false;
    };
  }, []);

  const _handleAppStateChange = (nextAppState: AppStateStatus) => {
    if (
      appState.current.match(/inactive|background/) &&
      nextAppState === "active"
    ) {
      if (Platform.OS === "ios") {
        Notifications.setBadgeCountAsync(0);
      }
    }
    setAppStateVisible(appState.current);
    appState.current = nextAppState;
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted === true) {
      loadConfig();
      Platform.OS === "ios" && Notifications.setBadgeCountAsync(0);
      if (user !== undefined) {
        IsBanned(user.twitterId).then((isBanned) => {
          if (isBanned) {
            setUser(undefined);
            ShowToast("info", `ログアウトしました。`);
          }
        });
      }
    }
    return () => {
      isMounted = false;
    };
  }, [appStateVisible]);

  useEffect(() => {
    let isMounted = true;
    const currentUser = getAuth().currentUser;
    if (
      appStateVisible === "active" &&
      isMounted === true &&
      currentUser !== null
    ) {
      GetUserActive(currentUser.uid).then((user) => setUser(user));
    }
    return () => {
      isMounted = false;
    };
  }, [appStateVisible, getAuth().currentUser]);

  const loadConfig = async () => {
    const maintenanceConfig = await GetMaintenanceConfig();
    setMaintenanceConfig(maintenanceConfig);
    if (Platform.OS === "ios" || Platform.OS === "android") {
      const forceUpdateConfig = await GetForceUpdateConfig(Platform.OS);
      if (forceUpdateConfig === undefined) {
        return;
      }
      if (!sember.valid(forceUpdateConfig.latestVersion)) {
        return;
      }
      sember.gt(
        forceUpdateConfig.latestVersion,
        Constants.manifest!.version!
      ) &&
        !alertShown &&
        (await showAlertToDownloadPage(forceUpdateConfig.url));
    }
  };

  const showAlertToDownloadPage = async (url: ForceUpdateConfigByOS["url"]) => {
    setAlertShown(true);
    Alert.alert("バージョンアップ", "アプリのバージョンを更新してください。", [
      {
        text: "OK",
        onPress: async () => {
          await Linking.openURL(url);
          setAlertShown(false);
        },
      },
    ]);
  };

  return maintenanceConfig?.isActive ? (
    <MaintenanceScreen />
  ) : (
    <Stack.Navigator
      screenOptions={{
        headerShown: false,
        headerStyle: { backgroundColor: "#1E293B" }, //bg-bluegray-800
        headerTintColor: "#fff",
        headerTitleAlign: "center",
        cardStyle: { flex: 1 },
      }}
    >
      <Stack.Screen name="home" component={HomeTabs} />
      {user && (
        <Stack.Screen
          name="multi_choice"
          component={MultiChoiceScreen}
          options={{
            headerShown: true,
            title: "複数選択モード",
            headerStyle: { backgroundColor: "white" },
            headerTintColor: "#1E293B",
          }}
        />
      )}
      {user && (
        <Stack.Screen
          name="answer_question"
          component={AnswerScreen}
          options={{ headerShown: true, title: "質問に回答" }}
        />
      )}
      {user && (
        <Stack.Screen
          name="answer_limited_only"
          component={AnswerLimitedOnlyScreen}
          options={{ headerShown: true, title: "Limited限定範囲を選択" }}
        />
      )}
      {user && (
        <Stack.Screen
          name="answer_question_reply"
          component={TargetAnswerChoiceScreen}
          options={{ headerShown: true, title: "続きの質問として設定" }}
        />
      )}
      {user && (
        <Stack.Screen
          name="profile"
          component={ProfileSettingScreen}
          options={{ headerShown: true, title: "プロフィール設定" }}
        />
      )}
      {user && (
        <Stack.Screen
          name="detail_setting"
          component={SettingsDetailScreen}
          options={{ headerShown: true, title: "詳細設定" }}
        />
      )}
      {user && (
        <Stack.Screen
          name="payout"
          component={PaymentScheduleScreen}
          options={{
            headerShown: true,
            title: "収益支払いスケジュール",
          }}
        />
      )}
      {user && (
        <Stack.Screen
          name="blocked"
          component={BlockedScreen}
          options={{ headerShown: true, title: "最近のブロック" }}
        />
      )}
      {user && (
        <Stack.Screen
          name="trash"
          component={TrashScreen}
          options={{ headerShown: true, title: "ゴミ箱" }}
        />
      )}
      {user && (
        <Stack.Screen
          name="tips_explain"
          component={TipsExplainScreen}
          options={{
            headerShown: true,
            title: "Querie Tipsについて",
          }}
        />
      )}
      {user && (
        <Stack.Screen
          name="tips_settings"
          component={TipsSettingsScreen}
          options={{ headerShown: true, title: "Querie Tips設定" }}
        />
      )}
      {user && (
        <Stack.Screen
          name="tips_email_registration"
          component={TipsEmailRegistration}
          options={{ headerShown: true, title: "Querie Tips登録" }}
        />
      )}
      {user && (
        <Stack.Screen
          name="tips_stripe_registration"
          component={TipsStripeRegistration}
          options={{ headerShown: true, title: "Querie Tips登録" }}
        />
      )}
      {user && (
        <Stack.Screen
          name="limited_explain"
          component={LimitedExplainScreen}
          options={{
            headerShown: true,
            title: "Querie Limitedについて",
          }}
        />
      )}
      {user && (
        <Stack.Screen
          name="limited_settings"
          component={LimitedSettingsScreen}
          options={{ headerShown: true, title: "Querie Limited設定" }}
        />
      )}
      {user && (
        <Stack.Screen
          name="limited_terminate"
          component={LimitedTerminateScreen}
          options={{ headerShown: true, title: "Querie Limited閉鎖" }}
        />
      )}
      {user && (
        <Stack.Screen
          name="limited_email_registration"
          component={LimitedEmailRegistration}
          options={{ headerShown: true, title: "Querie Limited登録" }}
        />
      )}
      {user && (
        <Stack.Screen
          name="limited_stripe_registration"
          component={LimitedStripeRegistration}
          options={{ headerShown: true, title: "Querie Limited登録" }}
        />
      )}
    </Stack.Navigator>
  );
};
