import React, { useContext } from "react";
import { useState, useEffect } from "react";
import {
  View,
  ScrollView,
  TextInput,
  Platform,
  useWindowDimensions,
  SafeAreaView,
  Text,
} from "react-native";
import InputScrollView from "react-native-input-scroll-view";
import * as Analytics from "expo-firebase-analytics";
import { ShowToast } from "../lib";
import { tailwind as tw } from "../tailwind";
import { QuestionCard } from "../components/Cards/QuestionCard";
import { Loading } from "../components/Loading";
import { RouteProp } from "@react-navigation/core";
import { StackNavigationProp } from "@react-navigation/stack";
import { QA, Question } from "../types/question";
import { RootStackParamList } from "../navigation/types";
import { CheckBoxItem } from "../types/checkBoxItem";
import { ActionSheetAction, ActionSheetItem } from "../types/actionSheet";
import {
  connectActionSheet,
  useActionSheet,
} from "@expo/react-native-action-sheet";
import { GetQuestion, TrashQuestionUnanswered } from "../firebase/Questions";
import { ReportQuestion } from "../firebase/Reports";
import { AddQuestionBlockHistory } from "../firebase/Questions";
import * as storageAnswerDraft from "../local_storage/answerDraft";
import * as storageShareOnTwitter from "../local_storage/shareOnTwitter";
import { UserContext } from "../contexts/userContext";
import {
  AlertAndReportError,
  GetProperWidth,
  OnPressSubmitAnswer,
  ProcessAnswerViaWebIntent,
  ShareLinkOnTwitter,
  SubmitAnswer,
} from "./lib";
import { TipsPricesPublicDefault } from "../types/lib";
import * as Clipboard from "expo-clipboard";
import { ButtonsOnAnswerScreen } from "../components/Buttons/ButtonsOnAnswerScreen";
import { BackButton } from "../components/Buttons/BackButton";
import { TwitterShareCheckBox } from "../components/Checkboxes/TwitterShareCheckBox";
import { QACardReplyTo } from "../components/Cards/QACardReplyTo";
import { DialogBoxWithUpToTwoOptions } from "../components/DialogBoxWithUpToTwoOptions";
import { Ionicons } from "@expo/vector-icons";
import { SubmitButtonInactive } from "../components/Buttons/SubmitButtonInactive";
import { LikeButton } from "../components/Buttons/LikeButton";
import { DeadlineTips } from "../components/Texts/DeadlineTipsText";
import { AskedAsPrivateText } from "../components/Texts/AskedAsPrivateText";
import { AnswerAsPrivateCheckBox } from "../components/Checkboxes/AnswerAsPrivateCheckBox";
import { UseTipsSettingsOnFocus } from "../hooks/useTipsSettingsOnFocus";
import {
  AnswerSubmissionVals,
  AnswerSubmissionFuncs,
} from "../types/answerSubmission";
import { ApiPostRateLimitedHitDialog } from "../components/Dialogs/ApiPostRateLimitedHitDialog";
import { Timestamp } from "firebase/firestore";
import { SetWillShowApiPostRateLimitHitDialog } from "../local_storage/willShowApiPostRateLimitHitDialog";
import { GetMultiAccountAuth } from "../firebase/MultiAccountAuthes";
import { MultiAccountAuth } from "../types/multiAccountAuth";
import { DisableShareDialog } from "../components/Dialogs/DisableShareDialog";
import {
  GetWillShowDisableShareDialog,
  SetWillShowDisableShareDialog,
} from "../local_storage/willShowDisableShareDIalog";

type Props = {
  navigation: StackNavigationProp<RootStackParamList, "answer_question">;
  route: RouteProp<RootStackParamList, "answer_question">;
};
export const AnswerScreenNoAS: React.FC<Props> = ({ navigation, route }) => {
  const window = useWindowDimensions();
  const properWidth = GetProperWidth(window);
  const widthString =
    properWidth.num === 1 && properWidth.denom === 1
      ? "w-full"
      : `w-${properWidth.num}/${properWidth.denom}`;
  const { user } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [loadingPayment, setLoadingPayment] = useState(false);
  const questionId = route.params.question_id;
  const [question, setQuestion] = useState<Question>();
  const [replyToQA, setReplyToQA] = useState<QA>();
  const [text, onChangeText] = useState<string>("");
  const [isTweetChecked, setIsTweetChecked] = useState(true);
  const [isAnswerAsPrivateChecked, setIsAnswerAsPrivateChecked] =
    useState(false);
  const [isAlertDialogShown, setIsAlertDialogShown] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("");
  const [dialogMessage, setDialogMessage] = useState("");
  const [isAlertDialogShownTwoOptions, setIsAlertDialogShownTwoOptions] =
    useState(false);
  const [isSharePrivateAnsweredDialog, setIsSharePrivateAnsweredDialog] =
    useState(false);
  const [isConfirmTipsAnswerDialogShown, setIsConfirmTipsAnswerDialogShown] =
    useState(false);
  const [isLiked, setIsLiked] = useState(false);
  const [multiAccountAuth, setMultiAccountAuth] = useState<MultiAccountAuth>();
  const [answerSubmissionVals, setAnswerSubmissionVals] =
    useState<AnswerSubmissionVals>();
  const [isApiPostRateLimitHitDialog, setIsApiPostRateLimitHitDialog] =
    useState(false);
  const [isDisableShareDialog, setIsDisableShareDialog] = useState(false);

  const navigateToHome = () => {
    navigation.navigate("home", {
      screen: "unanswered_questions",
      params: {
        questionToRemove: questionId,
      },
    });
  };

  const answerSubmissionFuncs: AnswerSubmissionFuncs = {
    setLoading,
    setLoadingPayment,
    setIsAlertDialogShownTwoOptions,
    setIsSharePrivateAnsweredDialog,
    navigateOnFailure: navigateToHome,
    navigateToHome,
    setIsApiPostRateLimitHitDialog,
  };

  const checkBoxItem: CheckBoxItem = {
    isChecked: false,
    onPressCheck: () => {},
  };
  useEffect(() => {
    setLoading(true);
    let isMounted = true;
    const setDraft = async () => {
      const answerDraft = await storageAnswerDraft
        .GetAnswerDraft(questionId)
        .catch((e) => {
          AlertAndReportError(e, "GetAnswerDraft on AnswerScreen");
          return "";
        });
      onChangeText(answerDraft);
    };
    isMounted && setDraft();
    setLoading(false);
    return () => {
      isMounted = false;
      onChangeText("");
    };
  }, [route]);

  useEffect(() => {
    setLoading(true);
    let isMounted = true;
    isMounted &&
      storageShareOnTwitter
        .GetShareOnTwitter()
        .then((shareOnTwitter) => {
          setIsTweetChecked(shareOnTwitter);
        })
        .catch((e) => {
          AlertAndReportError(e, "GetAnswerDraft on AnswerScreen");
          return false;
        });

    setLoading(false);
    return () => {
      isMounted = false;
      setIsTweetChecked(true);
    };
  }, [route]);

  useEffect(() => {
    storageShareOnTwitter.SetShareOnTwitter(isTweetChecked);
  }, [isTweetChecked]);

  useEffect(() => {
    setLoading(true);
    let isMounted = true;
    const initReplyToQA = async () => {
      if (route.params.reply_qa_id === undefined) {
        setReplyToQA(undefined);
      } else {
        const thisReplyToQA = (await GetQuestion(
          route.params.reply_qa_id,
          "questions_answered"
        )) as QA | undefined;
        thisReplyToQA === undefined || thisReplyToQA.text === undefined
          ? setReplyToQA(undefined)
          : setReplyToQA(thisReplyToQA);
      }
    };
    isMounted && initReplyToQA();
    setLoading(false);
    return () => {
      isMounted = false;
      setReplyToQA(undefined);
    };
  }, [route]);

  useEffect(() => {
    setLoading(true);
    let isMounted = true;
    const onPress = () => {
      navigation.navigate("home", {
        screen: "unanswered_questions",
        params: {},
      });
    };
    isMounted &&
      navigation.setOptions({
        headerLeft: (props) => <BackButton onPress={onPress} />,
      });
    setLoading(false);
    return () => {
      isMounted = false;
    };
  }, [route]);

  useEffect(() => {
    let isMounted = true;
    isMounted && storageAnswerDraft.SetAnswerDraft(questionId, text);
    return () => {
      isMounted = false;
    };
  }, [text]);

  useEffect(() => {
    setLoading(true);
    let isMounted = true;
    const initialize = async () => {
      const question = await GetQuestion(
        questionId,
        "questions_unanswered"
      ).catch((e) => {
        ShowToast("info", "回答済みか削除済みの質問です。");
        navigation.navigate("home", {
          screen: "unanswered_questions",
          params: {
            questionToRemove: questionId,
          },
        });
        return undefined;
      });
      setQuestion(question);
    };
    isMounted && initialize();
    setLoading(false);
    return () => {
      isMounted = false;
      setQuestion(undefined);
    };
  }, [route]);

  useEffect(() => {
    setLoading(true);
    let isMounted = true;
    const initialize = async () => {
      const multiAccountAuth = await GetMultiAccountAuth(user!.twitterId).catch(
        (e) => {
          ShowToast("info", "ログインしていません。再ログインしてください。");
          navigation.navigate("home", {
            screen: "unanswered_questions",
            params: {},
          });
          return undefined;
        }
      );
      setMultiAccountAuth(multiAccountAuth);
    };
    isMounted && initialize();
    setLoading(false);
    return () => {
      isMounted = false;
      setMultiAccountAuth(undefined);
    };
  }, [route]);

  useEffect(() => {
    setIsLiked(false);
    setIsAnswerAsPrivateChecked(false);
    return () => {
      setIsLiked(false);
      setIsAnswerAsPrivateChecked(false);
    };
  }, [route]);

  useEffect(() => {
    question !== undefined &&
      setAnswerSubmissionVals({
        questionId,
        question,
        isLiked,
        isLimitedOnly: false,
        isTweetChecked,
        isAnswerAsPrivateChecked,
        answerText: text,
        user,
        replyToQA,
        multiAccountAuth,
      });
  }, [
    route,
    questionId,
    question,
    isLiked,
    isTweetChecked,
    isAnswerAsPrivateChecked,
    text,
    user,
    replyToQA,
    multiAccountAuth,
  ]);

  const { loadingTipsSetting, tipsSetting } = UseTipsSettingsOnFocus(() => {},
  user);

  const { showActionSheetWithOptions } = useActionSheet();
  const openActionSheet = (actionSheetItem: ActionSheetItem) => {
    const options = actionSheetItem.actions.map((x) => x.label);
    const cancelButtonIndex = actionSheetItem.cancel_index;
    const destructiveButtonIndex = actionSheetItem.destructive_index;

    showActionSheetWithOptions(
      {
        options,
        cancelButtonIndex,
        destructiveButtonIndex,
      },
      (buttonIndex) => {
        buttonIndex !== undefined &&
          actionSheetItem.actions[buttonIndex].onPress(question!);
      }
    );
  };

  const actions: ActionSheetAction[] = [
    {
      label: "質問内容をコピー",
      onPress: async (question) => {
        if (question.isAskedAsPrivate === true) {
          ShowToast("error", "非公開質問のためコピーできません。");
          return;
        }
        await Clipboard.getStringAsync()
          .then(() => {
            Clipboard.setString(question.text);
            ShowToast("info", "クリップボードにコピーしました。");
            Analytics.logEvent("copied", { at: "AnswerScreen" });
          })
          .catch(() =>
            ShowToast(
              "error",
              "ご利用の環境ではコピーできません。機能を利用するにはアプリ版をご利用ください。"
            )
          );
      },
    },
    {
      label: "「続きの質問」として設定",
      onPress: (question) => {
        const id = question.id;
        id !== null &&
          navigation.navigate("answer_question_reply", { qa_id: id });
      },
    },

    {
      label: "不適切な質問を運営に報告",
      onPress: async (question) => {
        // setLoading(true);
        await ReportQuestion(question, "questions_unanswered").catch((e) => {
          AlertAndReportError(e, "ReportQuestion on AnswerScreen");
        });
        // setLoading(false);
        Analytics.logEvent("reported", { at: "AnswerScreen" });
        ShowToast("info", "不適切な質問として運営に報告しました。");
      },
    },
    {
      label: "質問者をブロック",
      onPress: async (question) => {
        setLoading(true);
        if (question.id === null) {
          return;
        }
        await AddQuestionBlockHistory(question.id, question).catch((e) =>
          AlertAndReportError(e, "AddBlock on AnswerScreen")
        );
        setLoading(false);
        Analytics.logEvent("blocked", { at: "AnswerScreen" });
        ShowToast("info", "この質問者をブロックしました。");
      },
    },
    {
      label: "質問をゴミ箱へ移動",
      onPress: async (question) => {
        setLoading(true);
        await TrashQuestionUnanswered(question.id!).catch((e) => {
          AlertAndReportError(e, "TrashQuestionUnanswered on AnswerScreen");
        });
        await storageAnswerDraft.DeleteAnswerDraft(question.id).catch((e) => {
          AlertAndReportError(e, "DeleteAnswerDraft on AnswerScreen");
        });
        navigation.navigate("home", {
          screen: "unanswered_questions",
          params: {
            questionToRemove: questionId,
          },
        });
        setLoading(false);
        Analytics.logEvent("trashed", { at: "AnswerScreen" });
        ShowToast(
          "info",
          "質問をゴミ箱に投入しました。\n 7日後に自動的に消去されます。"
        );
      },
    },
    {
      label: "キャンセル",
      onPress: () => {},
    },
  ];
  const actionSheetItem: ActionSheetItem = {
    actions: actions,
    cancel_index: 5,
    destructive_index: 4,
  };
  const actionSheetItemChangeTips: ActionSheetItem =
    question === undefined
      ? {
          actions: [],
          cancel_index: 0,
          destructive_index: 0,
        }
      : {
          actions: [
            ...TipsPricesPublicDefault.filter((x) => x <= question.tips!).map(
              (y) => ({
                label: `¥${y.toLocaleString()}に変更(¥${(
                  question!.tips! - y
                ).toLocaleString()}を返却)`,
                onPress: (question: Question) => {
                  setQuestion({ ...question, tipsChosen: y });
                },
              })
            ),
            { label: "キャンセル", onPress: () => {} },
          ],
          cancel_index: TipsPricesPublicDefault.filter(
            (x) => x <= question.tips!
          ).length,
          destructive_index: TipsPricesPublicDefault.filter(
            (x) => x <= question.tips!
          ).length,
        };

  const onDismissReply = () => {
    setReplyToQA(undefined);
    navigation.setParams({ reply_tweet_id: undefined, reply_qa_id: undefined });
  };

  type qCardProps = { question: Question; isLiked: boolean };
  const QCard = ({ question, isLiked }: qCardProps) => {
    return (
      <QuestionCard
        question={question}
        isNew={false}
        checkBoxItem={checkBoxItem}
        isPressable={false}
        isCaretVisible={true}
        onPress={() => {}}
        onPressCaret={() => {
          openActionSheet(actionSheetItem);
        }}
        isMultiChoiceActive={false}
        showReplyToIcon={false}
        isLiked={isLiked}
      />
    );
  };

  const dialogMessageReplyToQA =
    "こちらの質問回答の「続き」として回答が投稿されます。\n\n" +
    "ツイッター上ではこちらの質問回答ツイートへのリプライとして、querie.meウェブサイト上でも関連質問として近くに表示され、" +
    "ご覧になるフォロワーの方々が文脈を辿りやすくなります。\n\n" +
    "下部の「続きの質問設定を解除」ボタンをタップすることで設定を解除できます。";

  const dialogMessageLiked =
    "「いい質問」に設定された質問はTwitterへの質問共有画像に特別な赤枠が付きます。\n\n" +
    "「答えやすかった質問」や「嬉しかった質問」を「いい質問」に設定して回答を投稿することで、" +
    "よりいい質問を受け取りやすくなります。";

  const dialogMessageChangeTips =
    "提示されたTips金額から一部を返却できます。\n\n" +
    "質問者から提示されたTips金額に見合う回答ができない場合など、" +
    "Tipsの金額を変更することで一部もしくは全額を返却することができます。";

  const dialogMessageAnswerAsPrivate =
    "一般に無料で公開したくない情報を含む回答をTwitterやQuerie.meで公開せずに質問者へ届けることができます。\n\n" +
    "回答内容はQuerie.meから質問者へメールで送付されます。" +
    "(Tips質問のみ非公開での回答を行うことができます。)";

  type LikeButtonsProps = { isLiked: boolean };
  const LikeButtons = ({ isLiked }: LikeButtonsProps) => (
    <View style={tw("h-14 flex-row mx-4 items-center")}>
      <View style={tw("flex-1")}>
        <LikeButton
          isLiked={isLiked}
          onPress={() => {
            setIsLiked(!isLiked);
          }}
        />
      </View>
      <Ionicons
        name="help-circle-outline"
        style={tw("ml-1 mt-1")}
        size={32}
        color="gray"
        onPress={() => {
          setDialogTitle("「いい質問設定」とは？");
          setDialogMessage(dialogMessageLiked);
          setIsAlertDialogShown(true);
        }}
      />
    </View>
  );

  const ChangeTipsButtons = () => (
    <View style={tw("h-14 flex-row mx-4 justify-center items-center")}>
      <View style={tw("flex-1")}>
        <SubmitButtonInactive
          label="Tips額を変更"
          onPress={() => {
            openActionSheet(actionSheetItemChangeTips);
          }}
        />
      </View>
      <Ionicons
        name="help-circle-outline"
        style={tw("ml-1 mt-1")}
        size={32}
        color="gray"
        onPress={() => {
          setDialogTitle("「Tips額の変更」とは？");
          setDialogMessage(dialogMessageChangeTips);
          setIsAlertDialogShown(true);
        }}
      />
    </View>
  );
  const TipsExpiredDialog = () => (
    <DialogBoxWithUpToTwoOptions
      title={"Tips期限切れによりTipsが発生しません。"}
      widthString={widthString}
      message={"無料で回答してよろしいですか？"}
      buttons={[
        {
          label: "ＯＫ",
          onPress: () => {
            if (question === undefined) {
              return;
            }
            if (answerSubmissionVals === undefined) {
              return;
            }
            SubmitAnswer(answerSubmissionVals, answerSubmissionFuncs);
            setIsAlertDialogShownTwoOptions(false);
          },
        },
        {
          label: "キャンセル",
          onPress: () => {
            setIsAlertDialogShownTwoOptions(false);
          },
        },
      ]}
      isShown={isAlertDialogShownTwoOptions}
    />
  );

  type ReplyToQACardProps = {
    replyToQA: QA;
  };
  const ReplyToQACard = ({ replyToQA }: ReplyToQACardProps) => (
    <View style={tw("mx-4")}>
      <QACardReplyTo
        qa={replyToQA}
        answerUserName={user?.name!}
        answerUserIconUrl={user?.iconImageUrl!}
        label={"こちらの質問回答の続きの質問です"}
        isPressable={false}
        isExpanded={true}
        onDismiss={onDismissReply}
        onPressDetail={() => {
          setDialogTitle("「続きとして投稿」とは？");
          setDialogMessage(dialogMessageReplyToQA);
          setIsAlertDialogShown(true);
        }}
      />
    </View>
  );

  const ExplainDialog = () => (
    <DialogBoxWithUpToTwoOptions
      title={dialogTitle}
      widthString={widthString}
      message={dialogMessage}
      buttons={[
        {
          label: "OK",
          onPress: () => {
            setIsAlertDialogShown(false);
            setDialogTitle("");
            setDialogMessage("");
          },
        },
      ]}
      isShown={isAlertDialogShown}
    />
  );

  const clearSharePrivateAnsweredDialog = () => {
    setIsSharePrivateAnsweredDialog(false);
    setDialogTitle("");
    setDialogMessage("");
  };

  const SharePrivateAnsweredDialog = () => (
    <DialogBoxWithUpToTwoOptions
      title={"非公開質問に回答しました🎉"}
      widthString={widthString}
      message={
        "回答内容はQuerie.meから質問者へメールで送付されます。\n\n" +
        "非公開質問に回答したことをTwitterでシェアして、質問を募集しましょう! \n" +
        "(回答内容は共有されません。)"
      }
      buttons={[
        {
          label: "シェアする",
          onPress: () => {
            if (user === undefined || question === undefined) {
              return;
            }
            const tips =
              question.tipsChosen === undefined
                ? question.tips
                : question.tipsChosen;
            user !== undefined &&
              question !== undefined &&
              ShareLinkOnTwitter(
                user,
                `非公開の¥${tips.toLocaleString()}Tips質問に回答しました！Querie.meで質問を募集しています!`
              );
            clearSharePrivateAnsweredDialog();
            navigateToHome();
          },
        },
        {
          label: "シェアしない",
          onPress: () => {
            clearSharePrivateAnsweredDialog();
            navigateToHome();
          },
        },
      ]}
      isShown={isSharePrivateAnsweredDialog}
    />
  );

  const clearConfirmTipsAnswerDialog = () => {
    setIsConfirmTipsAnswerDialogShown(false);
    setDialogTitle("");
    setDialogMessage("");
  };

  const ConfirmTipsAnswerDialog = () => (
    <DialogBoxWithUpToTwoOptions
      title={"Tips質問に回答します"}
      widthString={widthString}
      message={"よろしいですか?"}
      buttons={[
        {
          label: "ＯＫ",
          onPress: () => {
            answerSubmissionVals !== undefined &&
              OnPressSubmitAnswer(answerSubmissionVals, answerSubmissionFuncs);
            clearConfirmTipsAnswerDialog();
          },
        },
        {
          label: "キャンセル",
          onPress: () => {
            clearConfirmTipsAnswerDialog();
          },
        },
      ]}
      isShown={isConfirmTipsAnswerDialogShown}
    />
  );

  const ThisApiPostRateLimitHitDialog = () => {
    const onPressBase = async () => {
      await ProcessAnswerViaWebIntent(
        questionId,
        text,
        false,
        user!,
        Timestamp.now().seconds,
        undefined,
        replyToQA?.tweetId === null ? undefined : replyToQA?.tweetId
      );
      setIsApiPostRateLimitHitDialog(false);
      navigateToHome();
    };
    return (
      <ApiPostRateLimitedHitDialog
        isShown={isApiPostRateLimitHitDialog}
        onPresses={[
          onPressBase,
          async () =>
            await onPressBase().then(() =>
              SetWillShowApiPostRateLimitHitDialog(false)
            ),
        ]}
        widthString={widthString}
      />
    );
  };

  const ThisDisableShareDialog = () => (
    <DisableShareDialog
      isShown={isDisableShareDialog}
      onPresses={[
        async () => setIsDisableShareDialog(false),
        async () =>
          await SetWillShowDisableShareDialog(false).then(() =>
            setIsDisableShareDialog(false)
          ),
      ]}
      widthString={widthString}
    />
  );

  const CheckBoxes = () =>
    question === undefined ? (
      <></>
    ) : question.isAskedAsPrivate === true ? (
      <></>
    ) : (
      <>
        {question.tips > 0 && (
          <AnswerAsPrivateCheckBox
            isChecked={isAnswerAsPrivateChecked}
            onCheckPressed={() =>
              setIsAnswerAsPrivateChecked(!isAnswerAsPrivateChecked)
            }
            onPressDetail={() => {
              setDialogTitle("「非公開で回答」とは？");
              setDialogMessage(dialogMessageAnswerAsPrivate);
              setIsAlertDialogShown(true);
            }}
          />
        )}
        {isAnswerAsPrivateChecked === false ? (
          <TwitterShareCheckBox
            isChecked={isTweetChecked}
            onCheckPressed={async () => {
              if (isTweetChecked) {
                const willShow = await GetWillShowDisableShareDialog();
                if (willShow) {
                  setIsDisableShareDialog(true);
                }
              }
              setIsTweetChecked(!isTweetChecked);
            }}
          />
        ) : (
          <View style={tw("h-8")}></View>
        )}
      </>
    );

  return question === undefined || loading || loadingTipsSetting ? (
    <Loading />
  ) : loadingPayment ? (
    <Loading isPayment={true} />
  ) : Platform.OS === "ios" ? (
    <InputScrollView
      keyboardOffset={100}
      multilineInputStyle={{ ...tw("p-4"), fontSize: 16, lineHeight: 24 }}
      style={tw(`${widthString} w-full bg-gray-300 flex-1 flex flex-grow`)}
      useAnimatedScrollView={true}
      scrollIndicatorInsets={{ right: 1 }}
    >
      <View style={tw("mt-2")}></View>
      {replyToQA !== undefined && <ReplyToQACard replyToQA={replyToQA} />}
      {question.hasTips === true && <DeadlineTips question={question} />}
      {question.isAskedAsPrivate === true && <AskedAsPrivateText />}
      {question !== undefined && (
        <QCard question={question} isLiked={isLiked} />
      )}
      <LikeButtons isLiked={isLiked} />
      {question.hasTips === true && <ChangeTipsButtons />}
      <View style={tw("rounded-xl bg-white mx-4 my-2 p-4")}>
        <TextInput
          style={{
            // ...tw("text-base text-gray-800 bg-white mx-4 my-2 p-4"),
            ...tw("text-base text-gray-800 bg-white"),
            minHeight: 160,
          }}
          onChangeText={(text) => onChangeText(text)}
          value={text}
          placeholder={"回答を入力してください。"}
          placeholderTextColor="gray"
          multiline={true}
        />
      </View>
      <ButtonsOnAnswerScreen
        label={
          tipsSetting === undefined ||
          tipsSetting.limitedPriceId === undefined ||
          question.isAskedAsPrivate === true ||
          isAnswerAsPrivateChecked === true
            ? "回答を送信"
            : "全体公開で回答を送信"
        }
        text={text}
        onPress={() =>
          question.tips !== 0 && question.tipsAlreadyCaptured !== true
            ? setIsConfirmTipsAnswerDialogShown(true)
            : answerSubmissionVals !== undefined &&
              OnPressSubmitAnswer(answerSubmissionVals, answerSubmissionFuncs)
        }
      />
      {tipsSetting !== undefined &&
        tipsSetting.limitedPriceId !== undefined &&
        question.isAskedAsPrivate === false &&
        isAnswerAsPrivateChecked === false && (
          <>
            <ButtonsOnAnswerScreen
              label="Limited限定で回答を送信"
              text={text}
              onPress={() =>
                answerSubmissionVals !== undefined &&
                navigation.navigate("answer_limited_only", {
                  answerSubmissionVals: {
                    ...answerSubmissionVals,
                    isLimitedOnly: true,
                  },
                })
              }
            />
            {question.tips > 0 && (
              <Text style={tw("text-center text-sm text-gray-500 mb-4 mx-4")}>
                Tips質問の場合は質問者へはLimited登録有無に関わらず回答全文がメールで送付されます
              </Text>
            )}
          </>
        )}
      <CheckBoxes />
      <View style={tw("h-40")} />
      {loading && <Loading />}
      <ExplainDialog />
      <TipsExpiredDialog />
      <SharePrivateAnsweredDialog />
      <ConfirmTipsAnswerDialog />
      <ThisApiPostRateLimitHitDialog />
      <ThisDisableShareDialog />
    </InputScrollView>
  ) : (
    <SafeAreaView style={tw("w-full items-center bg-gray-300 flex-1")}>
      <ScrollView style={tw(`${widthString} flex flex-grow`)}>
        {replyToQA !== undefined && <ReplyToQACard replyToQA={replyToQA} />}
        <View style={tw("mt-2")} />
        {question.hasTips === true && <DeadlineTips question={question} />}
        {question.isAskedAsPrivate === true && <AskedAsPrivateText />}
        {question !== undefined && (
          <QCard question={question} isLiked={isLiked} />
        )}
        <LikeButtons isLiked={isLiked} />
        {question.hasTips === true && <ChangeTipsButtons />}
        <View style={tw("flex-1 p-4 mx-4 my-2 bg-white rounded-lg")}>
          <TextInput
            style={{
              ...tw("text-base text-gray-800"),
              minHeight: Platform.OS === "android" ? 130 : 300,
              //@ts-ignore
              outlineWidth: 0,
            }}
            onChangeText={(text) => onChangeText(text)}
            value={text}
            placeholder={"回答を入力してください。"}
            placeholderTextColor="gray"
            multiline={true}
          />
        </View>
        <ButtonsOnAnswerScreen
          label={
            tipsSetting === undefined ||
            tipsSetting.limitedPriceId === undefined ||
            question.isAskedAsPrivate === true ||
            isAnswerAsPrivateChecked === true
              ? "回答を送信"
              : "全体公開で回答を送信"
          }
          text={text}
          onPress={() =>
            question.tips !== 0 && question.tipsAlreadyCaptured !== true
              ? setIsConfirmTipsAnswerDialogShown(true)
              : answerSubmissionVals !== undefined &&
                OnPressSubmitAnswer(answerSubmissionVals, answerSubmissionFuncs)
          }
        />
        {tipsSetting !== undefined &&
          tipsSetting.limitedPriceId !== undefined &&
          question.isAskedAsPrivate === false &&
          isAnswerAsPrivateChecked === false && (
            <>
              <ButtonsOnAnswerScreen
                label="Limited限定公開範囲を設定"
                text={text}
                onPress={() =>
                  answerSubmissionVals !== undefined &&
                  navigation.navigate("answer_limited_only", {
                    answerSubmissionVals: {
                      ...answerSubmissionVals,
                      isLimitedOnly: true,
                    },
                  })
                }
              />
              {question.tips > 0 && (
                <Text style={tw("text-center text-sm text-gray-500 mb-4 mx-4")}>
                  Tips質問の場合は質問者へはLimited登録有無に関わらず回答全文がメールで送付されます
                </Text>
              )}
            </>
          )}
        <CheckBoxes />
        <View style={tw("h-40")} />
        {loading && <Loading />}
        <ExplainDialog />
        <TipsExpiredDialog />
        <SharePrivateAnsweredDialog />
        <ConfirmTipsAnswerDialog />
        <ThisApiPostRateLimitHitDialog />
        <ThisDisableShareDialog />
      </ScrollView>
    </SafeAreaView>
  );
};

export const AnswerScreen = connectActionSheet(AnswerScreenNoAS);
