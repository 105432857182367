import { QA } from "../types/question";
import AsyncStorage from "@react-native-async-storage/async-storage";

export const SetAnswerDraft = async (
  questionId: QA["id"],
  answerDraft: QA["answer"]
) => {
  if (answerDraft !== undefined) {
    await AsyncStorage.setItem(`@answer_draft:${questionId}`, answerDraft);
  }
};

export const DeleteAnswerDraft = async (questionId: QA["id"]) => {
  await AsyncStorage.removeItem(`@answer_draft:${questionId}`);
};

export const GetAnswerDraft = async (questionId: QA["id"]) => {
  const answerDraft = await AsyncStorage.getItem(`@answer_draft:${questionId}`);
  return answerDraft === null ? "" : answerDraft;
};
