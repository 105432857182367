import React from "react";
import { View, Text } from "react-native";
import { tailwind as tw } from "../../tailwind";

type Props = {
  limit: number;
};

export const MoreThanLimitHitsCard = ({ limit }: Props) => (
  <View style={tw("px-2 mx-4 my-2 items-center")}>
    <Text style={tw("text-sm text-gray-500 leading-7")}>
      {limit}
      件以上が該当しています。
    </Text>
    <Text style={tw("text-sm text-gray-500 leading-7")}>
      表示するにはスペース区切りで検索を絞り込んで下さい。
    </Text>
  </View>
);
