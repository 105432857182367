import React, { useContext, useState, useEffect, useRef } from "react";
import {
  FlatList,
  View,
  RefreshControl,
  useWindowDimensions,
  SafeAreaView,
} from "react-native";
import { RouteProp } from "@react-navigation/core";
import { StackNavigationProp } from "@react-navigation/stack";
import { ShowToast } from "../lib";
import { tailwind as tw } from "../tailwind";
import { QACard } from "../components/Cards/QACard";
import { Loading } from "../components/Loading";
import {
  GetQuestion,
  GetQuestions,
  SearchQuestion,
} from "../firebase/Questions";
import { UserContext } from "../contexts/userContext";
import { QA } from "../types/question";
import { RootStackParamList } from "../navigation/types";
import { AlertAndReportError, GetProperWidth } from "./lib";
import * as storage from "../local_storage/answerDraft";
import { SearchButton } from "../components/Buttons/SearchButton";
import { SearchResultEndCard } from "../components/Cards/SearchResultEndCard";
import { NoSearchResultCard } from "../components/Cards/NoSearchResultCard";
import { MoreThanLimitHitsCard } from "../components/Cards/MoreThanLimitHitsCard";
import { Timestamp } from "firebase/firestore";
import { UseSetHeaderLeftGoBack } from "../hooks/useSetHeaderLeftGoBack";

type Props = {
  navigation: StackNavigationProp<RootStackParamList, "answer_question_reply">;
  route: RouteProp<RootStackParamList, "answer_question_reply">;
};
export const TargetAnswerChoiceScreen: React.FC<Props> = ({
  navigation,
  route,
}) => {
  const window = useWindowDimensions();
  const properWidth = GetProperWidth(window);
  const widthString =
    properWidth.num === 1 && properWidth.denom === 1
      ? "w-full"
      : `w-${properWidth.num}/${properWidth.denom}`;
  const [answeredQuestions, setAnsweredQuestions] = useState<QA[]>([]);
  const [loadingQuestions, setLoadingQuestions] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [isSearchActive, setIsSearchActive] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchHitQuestions, setSearchHitQuestions] = useState<QA[]>([]);
  const [moreThanLimitHits, setMoreThanLimitHits] = useState(false);
  const [qa, setQA] = useState<QA>();
  const startAfterRef = useRef(Timestamp.now());
  const maxIdRef = useRef("");
  const { user } = useContext(UserContext);
  const qa_id = route.params.qa_id;

  const SEARCH_RESULT_COUNT = 100;

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      startAfterRef.current = Timestamp.now();
      fetchQuestions(startAfterRef.current);
      fetchQuestionsSearch(searchTerm);
      maxIdRef.current = "";
    }
    return () => {
      isMounted = false;
    };
  }, []);

  UseSetHeaderLeftGoBack(navigation, () => {
    navigation.navigate("answer_question", { question_id: qa_id });
  });

  useEffect(() => {
    let isMounted = true;
    const initialize = async () => {
      const question = await GetQuestion(qa_id, "questions_unanswered").catch(
        (e) => {
          ShowToast("info", "回答済みか削除済みの質問です。");
          navigation.navigate("home", {
            screen: "unanswered_questions",
            params: {
              questionToRemove: qa_id,
            },
          });
          return undefined;
        }
      );

      const answer_draft = await storage.GetAnswerDraft(qa_id);
      setQA({ ...question, answer: answer_draft } as QA);
    };
    isMounted && initialize();
    return () => {
      isMounted = false;
    };
  }, []);

  const fetchQuestions = async (startAfter: Timestamp) => {
    if (user === undefined) {
      return;
    }
    if (user.id === undefined) {
      return;
    }
    setLoadingQuestions(true);
    const additional = (await GetQuestions(
      "questions_answered",
      user.id,
      startAfter,
      "answeredAt",
      20
    ).catch((e) =>
      AlertAndReportError(e, "GetQuestions on TargetChoiceScreen")
    )) as QA[];
    if (additional.length > 0) {
      setAnsweredQuestions((answeredQuestions) => [
        ...answeredQuestions,
        ...additional,
      ]);
      startAfterRef.current = additional[additional.length - 1].answeredAt;
    }
    setLoadingQuestions(false);
  };

  const fetchQuestionsSearch = async (text: string) => {
    setLoadingQuestions(true);
    const results = await SearchQuestion(
      "questions_answered",
      user?.id!,
      text,
      SEARCH_RESULT_COUNT
    );
    if (results.length >= SEARCH_RESULT_COUNT) {
      setMoreThanLimitHits(true);
      setSearchHitQuestions([]);
    } else {
      results.sort((a, b) => (a.answeredAt > b.answeredAt ? -1 : 1));
      setSearchHitQuestions(results);
    }
    setLoadingQuestions(false);
  };

  const onEndReachedQuestions = () => {
    fetchQuestions(startAfterRef.current);
  };

  const onRefreshQuestions = async () => {
    setRefreshing(true);
    setAnsweredQuestions([]);
    startAfterRef.current = Timestamp.now();
    await fetchQuestions(startAfterRef.current).catch((e) =>
      AlertAndReportError(e, "fetchQuestions on TargetChoiceScreen")
    );
    setRefreshing(false);
  };

  const onPressQuestion = async (qaTarget: QA) => {
    navigation.navigate("answer_question", {
      question_id: qa!.id!,
      reply_qa_id: qaTarget.id!,
      reply_tweet_id: undefined,
    });
  };

  const onChangeSearchTerm = async (text: string) => {
    setMoreThanLimitHits(false);
    setSearchHitQuestions([]);
    setSearchTerm(text);
    if (!text) {
      setSearchHitQuestions([]);
    } else {
      fetchQuestionsSearch(text);
    }
  };

  const onPressSearchButton = async () => {
    setSearchHitQuestions([]);
    setSearchTerm("");
    setIsSearchActive(!isSearchActive);
  };

  return qa === undefined ? (
    <Loading />
  ) : (
    <SafeAreaView style={tw("w-full items-center bg-gray-300 flex-1")}>
      <View style={tw(`${widthString} flex flex-grow bg-gray-300 h-full`)}>
        <FlatList
          ListFooterComponent={
            <View style={tw("py-1 mb-80")}>
              {isSearchActive &&
                searchHitQuestions.length === SEARCH_RESULT_COUNT && (
                  <SearchResultEndCard
                    searchResultCount={SEARCH_RESULT_COUNT}
                  />
                )}
              {isSearchActive &&
                searchHitQuestions.length === 0 &&
                moreThanLimitHits === false && (
                  <NoSearchResultCard text={searchTerm} />
                )}
              {isSearchActive && moreThanLimitHits && (
                <MoreThanLimitHitsCard limit={SEARCH_RESULT_COUNT} />
              )}
            </View>
          }
          ListHeaderComponent={<View style={tw("py-1")} />}
          contentContainerStyle={{ paddingTop: 48 }}
          data={isSearchActive ? searchHitQuestions : answeredQuestions}
          removeClippedSubviews={true}
          renderItem={({ item }) => {
            return (
              <QACard
                qa={item}
                answerUserName={user!.name}
                answerUserIconUrl={user!.iconImageUrl}
                isPressable={true}
                isCaretVisible={false}
                onPressCaret={() => {}}
                onPress={() => onPressQuestion(item)}
              />
            );
          }}
          keyExtractor={(_, index) => index.toString()}
          onEndReached={onEndReachedQuestions}
          refreshControl={
            <RefreshControl
              refreshing={refreshing}
              onRefresh={onRefreshQuestions}
            />
          }
        />
        {loadingQuestions && <Loading />}
        <SearchButton
          isActive={isSearchActive}
          onPressSearchButton={onPressSearchButton}
          text={searchTerm}
          onChangeText={onChangeSearchTerm}
        />
      </View>
    </SafeAreaView>
  );
};
