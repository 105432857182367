import React from "react";
import { View, Text } from "react-native";
import { tailwind as tw } from "../../tailwind";

type Props = {
  searchResultCount: number;
};

export const SearchResultEndCard = ({ searchResultCount }: Props) => (
  <View style={tw("bg-white px-4 mx-4 my-2")}>
    <Text style={tw("text-xs text-gray-500 leading-7 mt-2 text-center")}>
      表示中の{searchResultCount}件以上の検索結果がヒットしています。
    </Text>
    <Text style={tw("text-xs text-gray-500 leading-7 mb-2 text-center")}>
      スペース区切りのAND検索で絞り込んで下さい。
    </Text>
  </View>
);
