import AsyncStorage from "@react-native-async-storage/async-storage";

type booleanString = "true" | "false";

export const SetShareOnTwitter = async (shareOnTwitter: boolean) => {
  const shareOnTwitterString = booleanToString(shareOnTwitter);
  await AsyncStorage.setItem("@shareOnTwitter", shareOnTwitterString);
};

export const GetShareOnTwitter = async () => {
  const shareOnTwitterString = await AsyncStorage.getItem("@shareOnTwitter");
  if (shareOnTwitterString === null) {
    return true;
  } else {
    const shareOnTwitter = stringToBoolean(
      shareOnTwitterString as booleanString
    );
    return shareOnTwitter;
  }
};

const booleanToString = (boolean: boolean) => {
  return boolean ? "true" : "false";
};

const stringToBoolean = (booleanString: booleanString) => {
  return booleanString === "true";
};
