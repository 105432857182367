import React from "react";
import { TouchableOpacity, Text } from "react-native";
import { tailwind as tw } from "../../tailwind";

type Props = {
  labelAbove: string;
  labelBelow: string;
  onPress: () => void;
};

export const SubmitButtonTwoLines = ({
  labelAbove,
  labelBelow,
  onPress,
}: Props) => {
  return (
    <TouchableOpacity
      style={tw("flex-1 bg-bluegray-800 rounded-full justify-center")}
      onPress={onPress}
    >
      <Text style={tw(`text-base font-bold text-center text-white`)}>
        {labelAbove}
      </Text>
      <Text style={tw(`text-base font-bold text-center text-white`)}>
        {labelBelow}
      </Text>
    </TouchableOpacity>
  );
};
