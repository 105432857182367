import React from "react";
import { View, Text } from "react-native";
import { tailwind as tw } from "../tailwind";
import { SubmitButton } from "./Buttons/SubmitButton";

type Props = {
  onPressStripe: () => void;
  onPressInquiry: () => void;
};

export const AccountBlocked = ({ onPressStripe, onPressInquiry }: Props) => (
  <>
    <Text
      style={tw(
        "bg-bluegray-800 text-center text-base text-white font-bold p-2"
      )}
    >
      Stripeアカウントが利用できません。
    </Text>
    <View style={tw("bg-white w-full p-2")}>
      <Text style={tw("text-base p-2 mb-2")}>
        決済委託先Stripeにてお客様の決済用アカウントが利用できない状態となっております。
      </Text>
      <Text style={tw("text-base p-2 mb-2")}>
        下記Stripeダッシュボードより状況をご確認いただき、もし解決が難しい場合は当社までご連絡下さい。
      </Text>
      <Text style={tw("text-base p-2 mb-2")}>
        なお7日間経過しても当社より連絡がない場合は御手数ですが下記よりお問い合わせください。
      </Text>
      <SubmitButton label="Stripeへ" onPress={onPressStripe} />
      <SubmitButton label="問い合わせへ" onPress={onPressInquiry} />
    </View>
  </>
);
