import React from "react";
import { View, Text } from "react-native";
import { tailwind as tw } from "../tailwind";
import { User } from "../types/user";
import { TwitterShareButton } from "./Buttons/TwitterShareButton";

type Props = {
  onPressShare: (user: User) => Promise<void>;
  user: User;
  showTipsSignupGuide: boolean;
};

export const TwitterSharePrompt = ({
  onPressShare,
  user,
  showTipsSignupGuide,
}: Props) => {
  return (
    <View
      style={{
        ...tw("rounded-lg bg-white w-3/4 mt-24"),
        left: "12.5%",
      }}
    >
      <Text style={tw("text-bluegray-800 font-bold text-2xl m-4 text-center")}>
        匿名質問箱サービス{"\n"}Querieへようこそ！
      </Text>
      <Text style={tw("text-bluegray-800 text-xl mx-4 text-center")}>
        Twitterでシェアして{"\n"}質問を募集しましょう！
      </Text>
      <TwitterShareButton onPress={onPressShare} user={user} />
      {/* {user.tipsAccepted.length === 1 && user.tipsAccepted.includes(0) && ( */}
      {showTipsSignupGuide && (
        <Text style={tw("text-bluegray-800 text-xs mx-4 mb-4 text-center")}>
          現在収益化プログラムは有効化されていません。{"\n"}
          有効化するには画面右下のアカウント設定から{"\n"}
          「Querie Limited設定」「Querie Tips設定」に進み設定を行ってください。
        </Text>
      )}
    </View>
  );
};
