import { MultiAccountAuth } from "./../types/multiAccountAuth";
import { StripeSettingType } from "./../types/stripeSettingType";
import { functions } from "./Init";
import { Question } from "../types/question";
import { OnCallResponse } from "../types/onCallResponse";
import { Platform } from "react-native";
import * as Linking from "expo-linking";
import { httpsCallable } from "firebase/functions";
import { TweetParamsMultiAuth } from "../types/tweetParams";

export const PostTweetOnCall = async (tweetParams: TweetParamsMultiAuth) => {
  const tweetWithPingOnCall = httpsCallable<unknown, OnCallResponse>(
    functions,
    "onRequest-PostTweetOnCall"
  );
  const result = await tweetWithPingOnCall(tweetParams)
    .then((result) => {
      if (result.data.code === 200 || result.data.code === 429) {
        return result.data;
      } else {
        throw new Error(
          `${result.data.code} ${result.data.status} ${result.data.response}`
        );
      }
    })
    .catch((e) => {
      throw new Error(`${e.code} ${e.message} ${e.details}`);
    });
  return result;
};

export const PostCaptureFund = async (question: Question) => {
  const captureFundOnCall = httpsCallable<unknown, OnCallResponse>(
    functions,
    "onRequestStripe-CaptureFund"
  );
  const response = await captureFundOnCall(question);
  return response;
};

export const GenerateAccoutCreateLink = async () => {
  const generateAccountCreateLink = httpsCallable<unknown, OnCallResponse>(
    functions,
    "onRequestStripe-GenerateAccountCreateLink"
  );
  const result = await generateAccountCreateLink();
  const link = result.data;
  return link;
};

export const GenerateAccoutDashboardLink = async () => {
  const generateAccoutDashboardLink = httpsCallable<unknown, OnCallResponse>(
    functions,
    "onRequestStripe-GenerateAccountDashboardLink"
  );
  const result = await generateAccoutDashboardLink();
  const resultData = result.data;
  return resultData;
};

export const SubmitEmail = async (
  email: string,
  stripeSettingType: StripeSettingType
) => {
  const submit = httpsCallable<unknown, OnCallResponse>(
    functions,
    "onRequest-SubmitEmailToVerify"
  );
  const urlIntroduceTips = Linking.createURL("tips_registration");
  const platform = Platform.OS;
  if (platform === "android" || platform === "ios" || platform === "web") {
    const res = await submit({
      email,
      platform,
      urlIntroduceTips,
      stripeSettingType,
    });
    const resultData = res.data;
    return resultData;
  } else {
    const resultData: OnCallResponse = {
      status: "error",
      code: 400,
      response: "not allowed on this platform",
    };
    return resultData;
  }
};

export const CheckHasEnoughFollowers = async (
  multiAccountAuth: MultiAccountAuth
) => {
  const func = httpsCallable<unknown, OnCallResponse>(
    functions,
    "onRequest-HasEnoughFollowersForTipsMultiAccoutAuth"
  );
  const res = await func(multiAccountAuth);
  const resultData = res.data;
  return resultData.response === "true";
};

export const CheckShouldShowLimitedInvite = async (
  multiAccountAuth: MultiAccountAuth
) => {
  const func = httpsCallable<unknown, boolean>(
    functions,
    "onRequest-CheckShouldShowLimitedInvite"
  );
  const res = await func(multiAccountAuth);
  return res.data;
};

export const CheckAndUpdateIdVerification = async () => {
  const func = httpsCallable<unknown, OnCallResponse>(
    functions,
    "onRequestStripe-CheckAndUpdateIdVerification"
  );
  const response = await func();
  return response;
};

export const CreateQuerieLimited = async (amount: number) => {
  const func = httpsCallable<unknown, OnCallResponse>(
    functions,
    "onRequestStripe-CreateQuerieLimited"
  );
  const response = await func({ amount: amount });
  return response;
};

export const GetLimitedPrice = async () => {
  const func = httpsCallable<unknown, number>(
    functions,
    "onRequestStripe-GetLimitedPrice"
  );
  const response = await func();
  return response;
};

export const TerminateLimited = async () => {
  const func = httpsCallable<unknown, OnCallResponse>(
    functions,
    "onRequestStripe-TerminateLimited"
  );
  const response = await func();
  return response;
};

export const GetSubscriptionsCounts = async () => {
  const func = httpsCallable<unknown, OnCallResponse>(
    functions,
    "onRequestStripe-GetSubscriptionCounts"
  );
  const response = await func();
  return response;
};

export const GetLastMembershipEndAtMilSec = async () => {
  const func = httpsCallable<unknown, number | null>(
    functions,
    "onRequestStripe-GetLastMembershipEndAtMilSec"
  );
  const response = await func();
  return response;
};

export const NotifyLimitedInviteResult = async (didProceed: boolean) => {
  const func = httpsCallable<unknown, OnCallResponse>(
    functions,
    "onRequest-NotifyLimitedInviteResult"
  );
  const response = await func({ didProceed });
  return response;
};
