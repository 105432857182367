import React from "react";
import { View, Text } from "react-native";
import { tailwind as tw } from "../tailwind";
import Unorderedlist from "react-native-unordered-list";
import { StripeSettingType } from "../types/stripeSettingType";

type Props = {
  stripeSettingType: StripeSettingType;
};

export const ExplainStripeRegisterSteps = ({ stripeSettingType }: Props) => {
  return (
    <View style={tw("w-full mb-6")}>
      <View style={tw("border-2 border-bluegray-800 mb-2")}>
        <Text
          style={tw(
            "font-bold text-xl my-1 text-bluegray-800 text-center w-full p-1"
          )}
        >
          {stripeSettingType === "tips" ? "Querie Tips" : "Querie Limited"}
          設定手順
        </Text>
      </View>

      <Unorderedlist
        style={tw("text-lg font-bold mb-2 ml-2")}
        bulletUnicode={0x0031}
      >
        <Text style={tw("text-lg mb-2")}>メールアドレスの登録</Text>
      </Unorderedlist>
      <Unorderedlist
        style={tw("text-lg font-bold mb-2 ml-2")}
        bulletUnicode={0x0032}
      >
        <Text style={tw("text-lg mb-2")}>受け取り銀行の設定</Text>
      </Unorderedlist>
      <Unorderedlist
        style={tw("text-lg font-bold mb-2 ml-2")}
        bulletUnicode={0x0033}
      >
        <Text style={tw("text-lg mb-2")}>
          {stripeSettingType === "tips"
            ? "受け付けるTipsの選択"
            : "Limited月額料金等の設定"}
        </Text>
      </Unorderedlist>
    </View>
  );
};
