import React, { useContext, useState, useRef, useEffect } from "react";
import {
  FlatList,
  View,
  RefreshControl,
  useWindowDimensions,
  SafeAreaView,
} from "react-native";
import * as Analytics from "expo-firebase-analytics";
import { UserContext } from "../contexts/userContext";
import { useFocusEffect } from "@react-navigation/native"; // works
import "firebase/auth";
import { AlertAndReportError, GetProperWidth } from "./lib";
import { tailwind as tw } from "../tailwind";
import { QuestionCard } from "../components/Cards/QuestionCard";
import { Loading } from "../components/Loading";
import { StackNavigationProp } from "@react-navigation/stack";
import { RootStackParamList } from "../navigation/types";
import { RouteProp } from "@react-navigation/core";
import { Question } from "../types/question";
import { CheckBoxItem } from "../types/checkBoxItem";
import {
  GetQuestionsUnanswered,
  TrashQuestionUnanswered,
} from "../firebase/Questions";
import { MutableRefObject } from "hoist-non-react-statics/node_modules/@types/react";
import { BackButton } from "../components/Buttons/BackButton";
import { MultiChoiceActions } from "../components/MultiChoiceActions";
import { ReportQuestion } from "../firebase/Reports";
import * as storage from "../local_storage/answerDraft";
import { AddQuestionBlockHistory } from "../firebase/Questions";
import { Block } from "../types/block";
import { ShowToast } from "../lib";
import { Timestamp } from "firebase/firestore";

type Props = {
  navigation: StackNavigationProp<RootStackParamList, "multi_choice">;
  route: RouteProp<RootStackParamList, "multi_choice">;
};

export const MultiChoiceScreen = ({ navigation, route }: Props) => {
  const { sortedNewToOld, filterActivated } = route.params;
  const window = useWindowDimensions();
  const properWidth = GetProperWidth(window);
  const widthString =
    properWidth.num === 1 && properWidth.denom === 1
      ? "w-full"
      : `w-${properWidth.num}/${properWidth.denom}`;
  const [checkedQuestions, setCheckedQuestions] = useState<Question[]>([]);
  const { user } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [unansweredQuestions, setUnansweredQuestions] = useState<Question[]>(
    []
  );
  const [refreshing, setRefreshing] = useState(false);
  const startAfterRef = useRef(Timestamp.now());
  const flatListRef = useRef() as MutableRefObject<FlatList<Question>>;
  const [isReportChosen, setIsReportChosen] = useState(false);
  const [isBlockChosen, setIsBlockChosen] = useState(false);
  const [isDeleteChosen, setIsDeleteChosen] = useState(false);

  useFocusEffect(
    React.useCallback(() => {
      startAfterRef.current = Timestamp.now();
      fetchQuestions(
        unansweredQuestions[unansweredQuestions.length - 1],
        "useFocusEffect"
      );
      return () => {
        setUnansweredQuestions([]);
      };
    }, [])
  );
  useEffect(() => {
    let isMounted = true;
    const onPress = () => {
      navigation.navigate("home", {
        screen: "unanswered_questions",
        params: {},
      });
    };
    isMounted &&
      navigation.setOptions({
        headerLeft: (props) => <BackButton onPress={onPress} bluegray={true} />,
      });
    return () => {
      isMounted = false;
    };
  }, []);

  const questionChosen = checkedQuestions.length !== 0;
  const actionChosen = isReportChosen || isBlockChosen || isDeleteChosen;
  const isSearchActive = questionChosen && actionChosen;

  const fetchQuestions = async (
    startAfterQuestion: Question | undefined,
    from: string
  ) => {
    if (user === undefined) {
      return;
    }
    if (user.id === undefined) {
      return;
    }
    if (from !== "onEndReached") setLoading(true);
    const additional = await GetQuestionsUnanswered(
      user.id,
      startAfterQuestion,
      20,
      sortedNewToOld === undefined ? true : sortedNewToOld,
      filterActivated === undefined ? false : filterActivated
    ).catch((e) => {
      AlertAndReportError(e, "GetQuestions on QuestionsScreen");
      return [];
    });
    if (additional.length > 0) {
      setUnansweredQuestions((unansweredQuestions) => [
        ...unansweredQuestions,
        ...additional.filter((add) => {
          return !unansweredQuestions.map((x) => x.id).includes(add.id);
        }),
      ]);
      startAfterRef.current = additional[additional.length - 1].askedAt;
    }
    setLoading(false);
  };

  const onEndReached = () => {
    fetchQuestions(
      unansweredQuestions[unansweredQuestions.length - 1],
      "onEndReached"
    );
  };

  const onRefresh = async () => {
    setRefreshing(true);
    resetUnansweredQuestions();
    setRefreshing(false);
  };

  const resetUnansweredQuestions = async () => {
    setUnansweredQuestions([]);
    startAfterRef.current = Timestamp.now();
    await fetchQuestions(
      unansweredQuestions[unansweredQuestions.length - 1],
      "resetUnansweredQuestions"
    ).catch((e) => {
      AlertAndReportError(e, "fetchQuestions on QuestionsScreen");
    });
  };

  const onPressCheck = (question: Question, isChecked: boolean) => {
    isChecked
      ? setCheckedQuestions(
          checkedQuestions.filter((item: Question) => item.id !== question.id)
        )
      : setCheckedQuestions([...checkedQuestions, question]);
  };

  const onPressQuestion = (question: Question) => {
    checkedQuestions
      .map((checkedQuestion) => checkedQuestion.id)
      .includes(question.id)
      ? setCheckedQuestions(
          checkedQuestions.filter((item: Question) => item.id !== question.id)
        )
      : setCheckedQuestions([...checkedQuestions, question]);
  };
  const onPressReport = () => {
    setIsReportChosen(!isReportChosen);
  };
  const onPressBlock = () => {
    setIsBlockChosen(!isBlockChosen);
  };
  const onPressDelete = () => {
    setIsDeleteChosen(!isDeleteChosen);
  };

  const reportMulti = async () => {
    await Promise.all(
      checkedQuestions.map((question) =>
        ReportQuestion(question, "questions_unanswered")
      )
    ).catch((e) => AlertAndReportError(e, "ReportQuestion on AppContainer"));
    Analytics.logEvent("mutiReport");
  };

  const blockMulti = async () => {
    await Promise.all(
      checkedQuestions.map(
        (question) =>
          question.id !== null && AddQuestionBlockHistory(question.id, question)
      )
    ).catch((e) => AlertAndReportError(e, "AddBlock on AppContainer"));
    Analytics.logEvent("mutiBlock");
  };

  const deleteMulti = async () => {
    await Promise.all(
      checkedQuestions.map((question) => TrashQuestionUnanswered(question.id!))
    ).catch((e) =>
      AlertAndReportError(e, "TrashQuestionUnanswered on AppContainer")
    );
    await Promise.all(
      checkedQuestions.map((question) => storage.DeleteAnswerDraft(question.id))
    ).catch((e) => AlertAndReportError(e, "DeleteAnswerDraft on AppContainer"));
    Analytics.logEvent("mutiDelete");
  };

  const onPressExec = async () => {
    if (!questionChosen) {
      alert("対象質問を一つ以上選択して下さい。");
      return;
    }
    if (!actionChosen) {
      alert(
        "処理を「報告」「ブロック」「削除」の中から一つ以上選択して下さい。"
      );
      return;
    }
    setLoading(true);
    Promise.all([
      isReportChosen && reportMulti(),
      isBlockChosen && blockMulti(),
      isDeleteChosen && deleteMulti(),
    ]);

    ShowToast(
      "info",
      `${checkedQuestions.length}件の質問に関して${
        isReportChosen ? "「報告」" : ""
      }${isBlockChosen ? "「質問者をブロック」" : ""}${
        isDeleteChosen ? "「削除」" : ""
      }しました。\n ${
        isDeleteChosen ? "(削除された質問は7日後に自動的に消去されます。)" : ""
      }`
    );
    setCheckedQuestions([]);
    setIsReportChosen(false);
    setIsBlockChosen(false);
    setIsDeleteChosen(false);
    setLoading(false);
    const params = isDeleteChosen
      ? { questionsToRemove: checkedQuestions.map((x) => x.id!) }
      : {};
    navigation.navigate("home", {
      screen: "unanswered_questions",
      params: isDeleteChosen
        ? { questionsToRemove: checkedQuestions.map((x) => x.id!) }
        : {},
    });
  };

  const onPressClose = () => {
    setCheckedQuestions([]);
    setIsReportChosen(false);
    setIsBlockChosen(false);
    setIsDeleteChosen(false);
    navigation.navigate("home", { screen: "unanswered_questions", params: {} });
  };

  return (
    <SafeAreaView style={tw("w-full items-center bg-gray-300 flex-1")}>
      <View style={tw(`${widthString} flex flex-grow bg-gray-300 h-full`)}>
        <FlatList
          ListHeaderComponent={<View style={tw("py-1")} />}
          ListFooterComponent={<View style={tw("h-28")} />}
          data={unansweredQuestions}
          ref={flatListRef}
          removeClippedSubviews={true}
          renderItem={({ item }) => {
            const isChecked = checkedQuestions
              .map((checkedQuestion) => checkedQuestion.id)
              .includes(item.id);
            const question: Question = item;
            const checkBoxItem: CheckBoxItem = {
              isChecked: isChecked,
              onPressCheck: () => onPressCheck(item, isChecked),
            };
            return (
              <QuestionCard
                question={question}
                isNew={false}
                checkBoxItem={checkBoxItem}
                isPressable={true}
                isCaretVisible={true}
                onPress={() => onPressQuestion(item)}
                onPressCaret={() => {}}
                isMultiChoiceActive={true}
                showReplyToIcon={
                  item.replyTo !== undefined && item.replyTo !== null
                }
              />
            );
          }}
          keyExtractor={(_, index) => index.toString()}
          onEndReached={onEndReached}
          refreshControl={
            <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
          }
        />
        <MultiChoiceActions
          isReportChosen={isReportChosen}
          isBlockChosen={isBlockChosen}
          isDeleteChosen={isDeleteChosen}
          isExecActive={isSearchActive}
          onPressReport={onPressReport}
          onPressBlock={onPressBlock}
          onPressDelete={onPressDelete}
          onPressExec={onPressExec}
          onPressClose={onPressClose}
        />
        {loading && <Loading />}
      </View>
    </SafeAreaView>
  );
};
