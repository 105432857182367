import {
  Timestamp,
  collection,
  doc,
  getDoc,
  query,
  where,
  limit,
  startAfter,
  orderBy,
  getDocs,
  runTransaction,
  updateDoc,
  setDoc,
  deleteDoc,
} from "firebase/firestore";
import { db } from "./Init";
import { Question, Answer, QA, QOrQaBlocked } from "../types/question";
import { BiGramize } from "../libShared";
import { GetCurrentUserId } from "../lib";

type question_collection =
  | "questions_unanswered"
  | "questions_answered"
  | "questions_trashed"
  | "questions_deleted"
  | "questions_all";

export const GetQuestion = async (
  id: string | undefined,
  question_collection: question_collection
) => {
  if (id === undefined) {
    return undefined;
  }
  const snapShot = await getDoc(doc(db, question_collection, id));
  const data = snapShot.data();
  if (data === undefined) {
    return undefined;
  }
  const question = { id: id, ...snapShot.data() } as Question | QA;
  return question;
};

export const GetQuestions = async (
  question_collection: question_collection,
  askedTo: Question["askedTo"],
  startAfterTimestamp: Timestamp,
  orderByColumn: "askedAt" | "answeredAt",
  count: number
): Promise<(Question | QA)[]> => {
  const snapShot = await getDocs(
    query(
      collection(db, question_collection),
      where("askedTo", "==", askedTo),
      orderBy(orderByColumn, "desc"),
      startAfter(startAfterTimestamp),
      limit(count)
    )
  );
  const questions = snapShot.docs.map(
    (doc) => ({ ...doc.data(), id: doc.id } as Question | QA)
  );
  return questions;
};

export const GetQuestionsUnanswered = async (
  askedTo: Question["askedTo"],
  startAfterQuestion: Question | undefined,
  count: number,
  sortedNewToOld: boolean,
  filterActivated: boolean
): Promise<(Question | QA)[]> => {
  let snapShot;
  if (startAfterQuestion === undefined || startAfterQuestion.id === null) {
    snapShot = await getDocs(
      filterActivated
        ? query(
            collection(db, "questions_unanswered"),
            where("askedTo", "==", askedTo),
            where("hasTips", "==", false),
            orderBy("askedAt", sortedNewToOld ? "desc" : "asc"),
            limit(count)
          )
        : query(
            collection(db, "questions_unanswered"),
            where("askedTo", "==", askedTo),
            orderBy("hasTips", "desc"),
            orderBy("askedAt", sortedNewToOld ? "desc" : "asc"),
            limit(count)
          )
    ).catch((e) => {
      throw new Error(e);
    });
  } else {
    const startAfterDoc = await getDoc(
      doc(db, "questions_unanswered", startAfterQuestion.id)
    );
    snapShot = await getDocs(
      filterActivated
        ? query(
            collection(db, "questions_unanswered"),
            where("askedTo", "==", askedTo),
            where("hasTips", "==", false),
            orderBy("askedAt", sortedNewToOld ? "desc" : "asc"),
            limit(count),
            startAfter(startAfterDoc)
          )
        : query(
            collection(db, "questions_unanswered"),
            where("askedTo", "==", askedTo),
            orderBy("hasTips", "desc"),
            orderBy("askedAt", sortedNewToOld ? "desc" : "asc"),
            limit(count),
            startAfter(startAfterDoc)
          )
    ).catch((e) => {
      throw new Error(e);
    });
  }
  const questions = snapShot.docs.map(
    (doc) => ({ ...doc.data(), id: doc.id } as Question | QA)
  );
  return questions;
};

export const HasAnsweredLimitedOnly = async (
  askedTo: string
): Promise<boolean> => {
  const snapShot = await getDocs(
    query(
      collection(db, "questions_answered"),
      where("askedTo", "==", askedTo),
      where("isLimitedOnly", "==", true),
      limit(1)
    )
  ).catch((e) => {
    throw new Error(e);
  });
  return snapShot.docs.length > 0;
};

export const SearchQuestion = async (
  question_collection: "questions_unanswered" | "questions_answered",
  userId: string,
  searchWord: string,
  count: number
) => {
  if (searchWord.length < 2) {
    return [];
  }
  //split by space and multi-byte space and remove empty element
  const searchWordsSplit = sanitizeSearchText(searchWord)
    .split(/[ 　]+/)
    .filter((x) => {
      return x !== "";
    });
  const bigramized = searchWordsSplit.map((x) => BiGramize(x)).flat();
  const whereArgs = bigramized.map((word) =>
    where(`tokens.${word}`, "==", true)
  );
  const snapShot = await getDocs(
    query(
      collection(db, question_collection),
      where("askedTo", "==", userId),
      limit(count),
      ...whereArgs
    )
  );
  const questions = snapShot.docs.map(
    (doc) => ({ ...doc.data(), id: doc.id } as QA)
  );
  questions.sort((a, b) => (a.answeredAt > b.answeredAt ? -1 : 1));
  return questions as QA[];
};

const sanitizeSearchText = (text: string) => {
  return text.replace(/[.~*\/\[\]]/g, " ");
};

export const AnswerQuestion = async (question: Question, answer: Answer) => {
  const questionId = question.id;
  if (questionId === undefined || questionId === null) {
    throw new Error("question not defined");
  }
  await runTransaction(db, async (transaction) => {
    const unansweredReference = doc(db, "questions_unanswered", questionId);
    const answeredQuestion = {
      ...question,
      ...answer,
    };
    transaction.set(doc(db, "questions_answered", questionId), {
      ...answeredQuestion,
      createdAt: Timestamp.now(),
    });
    transaction.delete(unansweredReference);
  });
  return "success";
};

export const TrashQuestionUnanswered = async (id: string) => {
  const fromReference = doc(db, "questions_unanswered", id);
  await runTransaction(db, async (transaction) => {
    const snapShot = await transaction.get(fromReference);
    transaction.set(doc(db, "questions_trashed", id), {
      ...snapShot.data(),
      createdAt: Timestamp.now(),
    });
    transaction.delete(fromReference);
  });
};

export const TrashQuestionAnswered = async (
  id: string,
  deleteTweet: boolean
) => {
  const fromReference = doc(db, "questions_answered", id);
  await runTransaction(db, async (transaction) => {
    const snapShot = await transaction.get(fromReference);
    transaction.set(doc(db, "questions_trashed/", id), {
      ...snapShot.data(),
      createdAt: Timestamp.now(),
      deleteTweet: deleteTweet,
    });
    transaction.delete(fromReference);
  });
};

export const SetDeleteTweetOnModify = async (
  id: string,
  deleteTweetOnModify: boolean
) => {
  await updateDoc(doc(db, "questions_answered", id), {
    deleteTweetOnModify: deleteTweetOnModify,
  });
};

export const UpdateAnswer = async (id: string, data: any) => {
  await updateDoc(doc(db, "questions_answered", id), data);
};

export const MoveQuestionFromTrash = async (
  id: string,
  moveTo: "questions_unanswered" | "questions_answered"
) => {
  const fromReference = doc(db, "questions_trashed", id);
  await runTransaction(db, async (transaction) => {
    const snapShot = await transaction.get(fromReference);
    transaction.set(doc(db, moveTo, id), {
      ...snapShot.data(),
      createdAt: Timestamp.now(),
    });
    transaction.delete(fromReference);
  });
};

export const DeleteQuestion = async (
  id: string,
  moveFrom: question_collection
) => {
  const fromReference = doc(db, moveFrom, id);
  await runTransaction(db, async (transaction) => {
    const snapShot = await transaction.get(fromReference);
    transaction.set(doc(db, "questions_deleted", id), {
      ...snapShot.data(),
      createdAt: Timestamp.now(),
    });
    transaction.delete(fromReference);
  });
};

export const GetQuestionBlockHistory = async (
  askedTo: string,
  startAfterTimestamp: number
) =>
  await getDocs(
    query(
      collection(db, "question_block_history"),
      where("askedTo", "==", askedTo),
      where("isVisible", "==", true),
      orderBy("blockedAtMilSec", "desc"),
      startAfter(startAfterTimestamp),
      limit(10)
    )
  ).then((snapshot) =>
    snapshot.docs.map((doc) => {
      return { ...doc.data(), id: doc.id } as QOrQaBlocked;
    })
  );

export const AddQuestionBlockHistory = async (
  questionId: string,
  qOrQa: Question | QA
) => {
  const qOrQaBlocked: QOrQaBlocked = {
    ...qOrQa,
    isVisible: true,
    blockedAtMilSec: Timestamp.now().toMillis(),
  };
  await setDoc(doc(db, "question_block_history", questionId), qOrQaBlocked)
    .then(() => console.log(qOrQaBlocked))
    .catch((e) => console.log(e));
};

export const DeleteQuestionBlockHistory = async (questionId: string) => {
  await deleteDoc(doc(db, "question_block_history", questionId));
};

export const IsOneLimitedAnswerAnswered = async (userId: string) =>
  await getDocs(
    query(
      collection(db, "questions_answered"),
      where("askedTo", "==", userId),
      where("isLimitedOnly", "==", true),
      limit(2)
    )
  ).then((snapshot) => snapshot.size === 1);
