import React, { useContext } from "react";
import { UserContext } from "../contexts/userContext";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { Image, Platform, useWindowDimensions } from "react-native";
import { tailwind as tw } from "../tailwind";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import { QuestionsScreen } from "../screens/QuestionsScreen";
import { QAScreen } from "../screens/QAScreen";
import { AccountScreen } from "../screens/AccountScreen";
import { AuthScreen } from "../screens/AuthScreen";
import { GetScreenSize } from "../screens/lib";

const Tab = createBottomTabNavigator();

export const HomeTabs = () => {
  const { user } = useContext(UserContext);
  const window = useWindowDimensions();
  const screenSize = GetScreenSize(window);
  const properTabIconPosition =
    screenSize === "xl" ? "25%" : screenSize === "lg" ? "12.5%" : null;

  return (
    <Tab.Navigator
      screenOptions={({ route }) => ({
        tabBarActiveTintColor: "white",
        tabBarInactiveTintColor: "#999",
        tabBarStyle: user
          ? { backgroundColor: "#1E293B" }
          : { display: "none" }, //bg-bluegray-800
        tabBarShowLabel: false,
        headerShown: true,
        headerStyle: { backgroundColor: "#1E293B" }, //bg-bluegray-800
        headerTintColor: "#fff",
        headerTitleAlign: "center",
        tabBarButton: ["auth"].includes(route.name)
          ? () => {
              return null;
            }
          : undefined,
      })}
    >
      {user && (
        <Tab.Screen
          name="unanswered_questions"
          component={QuestionsScreen}
          options={{
            tabBarIconStyle:
              properTabIconPosition === null
                ? {}
                : {
                    position: "absolute",
                    left: properTabIconPosition,
                    marginLeft: Platform.OS === "web" ? "2rem" : "auto",
                    width: Platform.OS === "web" ? "auto" : "100%",
                  },
            tabBarIcon: ({ color, size }) => (
              <MaterialCommunityIcons
                name="comment-question"
                size={36}
                color={color}
                style={{ width: 36 }}
              />
            ),
            headerTitle: "未回答の質問",
          }}
          initialParams={{
            questionToAdd: undefined,
            questionToRemove: undefined,
          }}
        />
      )}
      {user && (
        <Tab.Screen
          name="answered_questions"
          component={QAScreen}
          options={{
            tabBarIconStyle:
              properTabIconPosition === null
                ? {}
                : {
                    width: Platform.OS === "web" ? "auto" : "100%",
                  },
            tabBarIcon: ({ color, size }) => (
              <MaterialCommunityIcons
                name="comment-arrow-right"
                size={36}
                color={color}
                style={{ width: 36 }}
              />
            ),
            headerTitle: "回答済みの質問",
          }}
        />
      )}
      {user && (
        <Tab.Screen
          name="account_setting"
          component={AccountScreen}
          options={{
            tabBarIconStyle:
              properTabIconPosition === null
                ? {}
                : {
                    position: "absolute",
                    right: properTabIconPosition,
                    marginRight: Platform.OS === "web" ? "2rem" : "auto",
                    width: Platform.OS === "web" ? "auto" : "100%",
                  },
            tabBarIcon: () => (
              <Image
                source={{ uri: user!.iconImageUrl }}
                style={{
                  ...tw("h-8 w-8 rounded-full border"),
                  borderColor: "white",
                }}
              />
            ),
            headerTitle: "アカウント設定",
          }}
          initialParams={{
            showTipsUpdateShareAlertType: undefined,
          }}
        />
      )}

      <Tab.Screen
        name="auth"
        component={AuthScreen}
        options={{ headerShown: false }}
      />
    </Tab.Navigator>
  );
};
