import React from "react";
import { View, Text, TextInput, TouchableOpacity } from "react-native";
import { tailwind as tw } from "../tailwind";

type Props = {
  label: string;
  value: string;
  onChange: (value: string) => void;
  onSubmit: () => void;
};

export const AddCustomTipsPrice = ({
  label,
  value,
  onChange,
  onSubmit,
}: Props) => (
  <>
    <View style={tw("border-2 border-bluegray-800 my-2")}>
      <Text
        style={tw(
          "bg-white text-center text-sm text-bluegray-800 font-bold p-2"
        )}
      >
        {label}
      </Text>
    </View>
    <View style={tw("w-full flex-row items-center my-2")}>
      <TextInput
        style={tw(
          "flex-1 w-full rounded-lg text-xs border text-gray-800 h-12 p-2 mr-2 bg-white"
        )}
        autoCapitalize="none"
        keyboardType="numeric"
        autoCorrect={false}
        onChangeText={(value) => onChange(value)}
        value={value === undefined ? "" : `${value}`}
        placeholder={"追加価格を入力(1,000円単位 100,000円以下)"}
        placeholderTextColor="gray"
      />
      <TouchableOpacity
        style={tw(`flex-none bg-bluegray-800 rounded-full px-4 py-2 flex`)}
        onPress={onSubmit}
      >
        <Text style={tw(`text-white text-sm font-bold text-center`)}>追加</Text>
      </TouchableOpacity>
    </View>
  </>
);
