import React from "react";
import { ConfirmDialog } from "react-native-simple-dialogs";
import { tailwind as tw } from "../tailwind";

type Props = {
  title: string;
  message: string;
  buttons: DialogButton[];
  isShown: boolean;
  widthString: string;
};

type DialogButton = {
  label: string;
  onPress: () => void;
};

export const DialogBoxWithUpToTwoOptions = ({
  title,
  message,
  buttons,
  isShown,
  widthString,
}: Props) =>
  buttons.length === 1 ? (
    <ConfirmDialog
      title={title}
      message={message}
      visible={isShown}
      dialogStyle={{ ...tw(`${widthString} p-2`), alignSelf: "center" }}
      positiveButton={{
        title: buttons[0].label,
        onPress: buttons[0].onPress,
        titleStyle: { color: "#1E293B" },
      }}
    />
  ) : (
    <ConfirmDialog
      title={title}
      message={message}
      visible={isShown}
      dialogStyle={{ ...tw(`${widthString} p-2`), alignSelf: "center" }}
      positiveButton={{
        title: buttons[0].label,
        onPress: buttons[0].onPress,
        titleStyle: { color: "#1E293B" },
      }}
      negativeButton={{
        title: buttons[1].label,
        onPress: buttons[1].onPress,
        titleStyle: { color: "#1E293B" },
      }}
    />
  );
