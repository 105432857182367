import { Question } from "../../types/question";
import dayjs from "dayjs";
import { View, Text } from "react-native";
import { tailwind as tw } from "../../tailwind";

const generateDeadlineText = (question: Question) =>
  dayjs(question.askedAt.toDate()).add(7, "day").format("YYYY/MM/DD HH:mm");

type Props = { question: Question };

export const DeadlineTips = ({ question }: Props) => {
  return (
    <View style={tw("mx-4 my-2 border border-red-700 bg-white p-2")}>
      <Text style={tw("text-center text-red-700")}>Tips獲得回答期限:</Text>
      <Text style={tw("text-center font-bold text-red-700")}>
        {generateDeadlineText(question)}
      </Text>
    </View>
  );
};
