import React, { useContext, useState, useRef, useEffect } from "react";
import {
  FlatList,
  View,
  RefreshControl,
  Animated,
  useWindowDimensions,
  SafeAreaView,
  Text,
} from "react-native";
import * as Analytics from "expo-firebase-analytics";
import { AlertAndReportError, GetProperWidth, OnPressShare } from "./lib";
import { ShowToast } from "../lib";
import { tailwind as tw } from "../tailwind";
import { QuestionCard } from "../components/Cards/QuestionCard";
import { ExistsUpdateButton } from "../components/Buttons/ExistsUpdateButton";
import { TwitterSharePrompt } from "../components/TwitterSharePrompt";
import { Loading } from "../components/Loading";
import { StackNavigationProp } from "@react-navigation/stack";
import { RootStackParamList } from "../navigation/types";
import { RouteProp } from "@react-navigation/core";
import { Answer, Question } from "../types/question";
import { User } from "../types/user";
import { CheckBoxItem } from "../types/checkBoxItem";
import { ActionSheetItem, ActionSheetAction } from "../types/actionSheet";
import {
  connectActionSheet,
  useActionSheet,
} from "@expo/react-native-action-sheet";
import {
  GetQuestionsUnanswered,
  SearchQuestion,
  TrashQuestionUnanswered,
} from "../firebase/Questions";
import { UserContext } from "../contexts/userContext";
import { AddQuestionBlockHistory } from "../firebase/Questions";
import { ReportQuestion } from "../firebase/Reports";
import * as storage_draft from "../local_storage/answerDraft";
import * as storage_lastViewed from "../local_storage/lastViewed";
import { MutableRefObject } from "hoist-non-react-statics/node_modules/@types/react";
import { useScrollToTop } from "@react-navigation/native";
import { UpdateUser } from "../firebase/Users";
import { SearchButton } from "../components/Buttons/SearchButton";
import { SearchResultEndCard } from "../components/Cards/SearchResultEndCard";
import { NoSearchResultCard } from "../components/Cards/NoSearchResultCard";
import { MoreThanLimitHitsCard } from "../components/Cards/MoreThanLimitHitsCard";
import { DialogBoxWithUpToTwoOptions } from "../components/DialogBoxWithUpToTwoOptions";

import {
  collection,
  limit,
  onSnapshot,
  orderBy,
  query,
  Timestamp,
  where,
} from "firebase/firestore";
import { auth, db } from "../firebase/Init";
import { NotifyLimitedInviteResult } from "../firebase/Functions";
import { ConfirmDialog } from "react-native-simple-dialogs";
import Unorderedlist from "react-native-unordered-list";
import { UseFilterSortOnQuestionsScreen } from "../hooks/useFilterSortOnQuestionsScreen";
import { UseButtonsOnQuestionsScreen } from "../hooks/useButtonsOnQuestionsScreen";

type Props = {
  navigation: StackNavigationProp<RootStackParamList, "unanswered_questions">;
  route: RouteProp<RootStackParamList, "unanswered_questions">;
};

const QuestionsScreenNoAS = ({ navigation, route }: Props) => {
  const window = useWindowDimensions();
  const properWidth = GetProperWidth(window);
  const widthString =
    properWidth.num === 1 && properWidth.denom === 1
      ? "w-full"
      : `w-${properWidth.num}/${properWidth.denom}`;
  const { user } = useContext(UserContext);
  const [isUserAuthed, setIsUserAuthed] = useState(false);
  const [loading, setLoading] = useState(false);
  const [unansweredQuestions, setUnansweredQuestions] = useState<Question[]>(
    []
  );
  const [refreshing, setRefreshing] = useState(false);
  const [existsUpdate, setExistsUpdate] = useState(false);
  const [isSearchActive, setIsSearchActive] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchHitQuestions, setSearchHitQuestions] = useState<Question[]>([]);
  const [moreThanLimitHits, setMoreThanLimitHits] = useState(false);
  const [isAlsoDeleteAlertShown, setIsAlsoDeleteAlertShown] = useState(false);
  const [isAlsoBlockAlertShown, setIsAlsoBlockAlertShown] = useState(false);
  const [questionForAction, setQuestionForAction] = useState<Question>();
  const startAfterRef = useRef(Timestamp.now());
  const lastViewed = useRef(Timestamp.now().toMillis());
  const flatListRef = useRef() as MutableRefObject<FlatList<Question>>;
  const opacity = useRef(new Animated.Value(0)).current;
  const {
    isFirstLimitedOnlyAnswerAlertShown,
    isAutoShareAlertShown,
    isInviteToLimitedAlertShown,
    setIsFirstLimitedOnlyAnswerAlertShown,
    setIsAutoShareAlertShown,
    setIsInviteToLimitedAlertShown,
    buttonTranslationX,
    showTwitterShare,
    showTipsSignupGuide,
  } = UseButtonsOnQuestionsScreen(
    route,
    unansweredQuestions,
    existsUpdate,
    opacity,
    user
  );
  const SEARCH_RESULT_COUNT = 100;

  const logAnalyticsEventSetLoading = (
    location: string,
    idx: number,
    value: boolean
  ) => {
    console.log("logAnalyticsEventSetLoading", location, idx, value);
    Analytics.logEvent("setLoading", {
      location,
      idx,
      userId: user?.id!,
      value,
    });
  };

  const logAnalyticsEventSetRefreshing = (
    location: string,
    idx: number,
    value: boolean
  ) => {
    Analytics.logEvent("setRefreshing", {
      location,
      idx,
      userId: user?.id!,
      value,
    });
  };

  const logAnalyticsEventSetIsUserAuthed = (
    location: string,
    idx: number,
    value: boolean
  ) => {
    Analytics.logEvent("setIsUserAuthed", {
      location,
      idx,
      userId: user?.id!,
      value,
    });
  };

  const onRefresh = async () => {
    setRefreshing(true);
    logAnalyticsEventSetRefreshing("onRefresh", 0, true);
    resetUnansweredQuestions();
    setRefreshing(false);
    logAnalyticsEventSetRefreshing("onRefresh", 1, false);
  };

  const { sortedNewToOld, setSortedNewToOld, filterActivated } =
    UseFilterSortOnQuestionsScreen(
      onRefresh,
      () => user !== undefined && OnPressShare,
      window,
      navigation,
      user
    );
  const timestampToMilSec = (timestamp: Timestamp) =>
    timestamp.seconds * 1000 + timestamp.nanoseconds / 1000;

  useEffect(() => {
    return auth.onAuthStateChanged((user) => {
      setIsUserAuthed(user !== null);
      logAnalyticsEventSetIsUserAuthed("onAuthStateChanged", 0, user !== null);
    });
  }, []);

  useEffect(() => {
    let isMounted = true;
    const unsubscribe = () => {
      try {
        onSnapshot(
          query(
            collection(db, "questions_unanswered"),
            where("askedTo", "==", user!.id),
            orderBy("askedAt", "desc"),
            limit(1)
          ),
          (snapshot) => {
            const updateNotified =
              snapshot.docChanges().filter((x) => {
                const askedAt: Timestamp = x.doc.data().askedAt;
                const askedAtMilSec = timestampToMilSec(askedAt);
                return x.type === "added" && lastViewed.current < askedAtMilSec;
              }).length > 0;
            updateNotified && setExistsUpdate(true);
          }
        );
      } catch (e) {
        AlertAndReportError(e, "Listener on QuestionsScreen", true);
      }
    };
    isMounted && unsubscribe();
    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    let isMounted = true;
    if (isUserAuthed === false) {
      return;
    }
    if (isMounted && route.params.fromPush !== true) {
      updateLastViewedCurrent();
      fetchQuestions(undefined, true, "useEffect1");
      storage_lastViewed.SetLastViewed(Timestamp.now());
    }
    return () => {
      isMounted = false;
    };
  }, [isUserAuthed]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted && doesRootHaveParams(route)) {
      if (
        route.params.questionToRemove !== undefined &&
        route.params.questionToRemove !== null
      ) {
        const questionToRemove = route.params.questionToRemove;
        setUnansweredQuestions(
          unansweredQuestions.filter((x) => x.id !== questionToRemove)
        );
        setSearchHitQuestions(
          searchHitQuestions.filter((y) => y.id !== questionToRemove)
        );
      }
      if (
        route.params.questionToAdd !== undefined &&
        route.params.questionToAdd !== null
      ) {
        const questionToAdd = route.params.questionToAdd;
        if (
          !unansweredQuestions
            .map((unansweredQuestion) => unansweredQuestion.id)
            .includes(questionToAdd)
        ) {
          setUnansweredQuestions([]);
          fetchQuestions(undefined, true, "useEffect2");
        }
      }
      if (
        route.params.questionsToRemove !== undefined &&
        route.params.questionsToRemove !== null
      ) {
        const questionsToRemove = route.params.questionsToRemove;
        setUnansweredQuestions(
          unansweredQuestions.filter((x) => !questionsToRemove.includes(x.id!))
        );
        setSearchHitQuestions(
          searchHitQuestions.filter((y) => !questionsToRemove.includes(y.id!))
        );
      }
    }
    return () => {
      isMounted = false;
    };
  }, [
    route.params.questionToAdd,
    route.params.questionToRemove,
    route.params.questionsToRemove,
  ]);

  const doesRootHaveParams = (
    route: RouteProp<RootStackParamList, "unanswered_questions">
  ) => {
    if (Object.keys(route).includes("params")) {
      if (route.params !== undefined) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const updateLastViewedCurrent = async () => {
    const lastViewedStorage = await storage_lastViewed
      .GetLastViewed()
      .catch((e) => {
        AlertAndReportError(e, "GetLastViewed on QuestionsScreen");
        return 0;
      });
    lastViewed.current = lastViewedStorage === null ? 0 : lastViewedStorage;
  };

  const fetchQuestions = async (
    startAfterQuestion: Question | undefined,
    hideExistsUpdateIcon: boolean,
    from: string
  ) => {
    const userId = user?.id;
    if (userId === undefined) {
      return;
    }
    if (from !== "onEndReached") setLoading(true);
    logAnalyticsEventSetLoading(`fetchQuestions:${from}`, 0, true);
    const additional = await GetQuestionsUnanswered(
      userId,
      startAfterQuestion,
      20,
      sortedNewToOld === undefined ? true : sortedNewToOld,
      filterActivated === undefined ? false : filterActivated
    ).catch((e) => {
      AlertAndReportError(e, "GetQuestions on QuestionsScreen ");
      return [];
    });
    if (additional.length > 0) {
      setUnansweredQuestions((unansweredQuestions) =>
        [
          ...unansweredQuestions,
          ...additional.filter((add) => {
            return !unansweredQuestions.map((x) => x.id).includes(add.id);
          }),
        ].filter((x) =>
          route.params.questionsToRemove === undefined
            ? true
            : !route.params.questionsToRemove.includes(x.id!)
        )
      );
      startAfterRef.current = additional[additional.length - 1].askedAt;
    }
    hideExistsUpdateIcon && setExistsUpdate(false);
    setLoading(false);
    logAnalyticsEventSetLoading(`fetchQuestions:${from}`, 1, false);
  };

  const fetchQuestionsSearch = async (text: string) => {
    setLoading(true);
    logAnalyticsEventSetLoading("fetchQuestionsSearch", 0, true);
    const results = await SearchQuestion(
      "questions_unanswered",
      user?.id!,
      text,
      SEARCH_RESULT_COUNT
    );
    if (results.length >= SEARCH_RESULT_COUNT) {
      setMoreThanLimitHits(true);
      setSearchHitQuestions([]);
    } else {
      results.sort((a, b) => (a.askedAt > b.askedAt ? -1 : 1));
      setSearchHitQuestions(results);
    }
    setLoading(false);
    logAnalyticsEventSetLoading("fetchQuestionsSearch", 1, false);
  };

  const onEndReached = () => {
    if (loading) return;
    if (auth.currentUser === null) return;
    fetchQuestions(
      unansweredQuestions[unansweredQuestions.length - 1],
      false,
      "onEndReached"
    );
  };

  const resetUnansweredQuestions = async () => {
    updateLastViewedCurrent();
    setUnansweredQuestions([]);
    await fetchQuestions(undefined, true, "resetUnansweredQuestions").catch(
      (e) => {
        AlertAndReportError(e, "fetchQuestions on QuestionsScreen");
      }
    );
    storage_lastViewed.SetLastViewed(Timestamp.now());
    setExistsUpdate(false);
  };

  const onPressQuestion = (QA: Question & Answer) => {
    const getParam = (QA: Question & Answer) => {
      if (QA.replyToQuestionId === undefined) {
        if (QA.replyTo === undefined || QA.replyTo === null) {
          return { question_id: QA.id! };
        } else {
          return { question_id: QA.id!, reply_qa_id: QA.replyTo };
        }
      } else {
        if (QA.replyToQuestionId === null) {
          return { question_id: QA.id! };
        } else {
          return { question_id: QA.id!, reply_qa_id: QA.replyToQuestionId };
        }
      }
    };
    navigation.navigate("answer_question", getParam(QA));
    Analytics.logEvent("questionOpened");
  };

  const onChangeSearchTerm = async (text: string) => {
    setMoreThanLimitHits(false);
    setSearchTerm(text);
    if (!text) {
      setSearchHitQuestions([]);
    } else {
      fetchQuestionsSearch(text);
    }
  };

  const { showActionSheetWithOptions } = useActionSheet();
  const openActionSheet = (
    actionSheetItem: ActionSheetItem,
    question: Question
  ) => {
    const options = actionSheetItem.actions.map((x) => x.label);
    const cancelButtonIndex = actionSheetItem.cancel_index;
    const destructiveButtonIndex = actionSheetItem.destructive_index;

    showActionSheetWithOptions(
      {
        options,
        cancelButtonIndex,
        destructiveButtonIndex,
      },
      (buttonIndex) => {
        buttonIndex !== undefined &&
          actionSheetItem.actions[buttonIndex].onPress(question);
      }
    );
  };

  const chooseTrueOnAlsoDelete = async () => {
    if (questionForAction === undefined) {
      return;
    }
    setLoading(true);
    logAnalyticsEventSetLoading("chooseTrueOnAlsoDelete", 0, true);
    await deleteQuestion(questionForAction);
    setLoading(false);
    logAnalyticsEventSetLoading("chooseTrueOnAlsoDelete", 1, false);
    ShowToast(
      "info",
      "質問をゴミ箱に投入しました。\n 7日後に自動的に消去されます。"
    );
    setIsAlsoDeleteAlertShown(false);
    setQuestionForAction(undefined);
  };
  const chooseFalseOnAlsoDelete = async () => {
    setIsAlsoDeleteAlertShown(false);
    setQuestionForAction(undefined);
  };

  const chooseTrueOnAlsoBlock = async () => {
    if (questionForAction === undefined) {
      return;
    }
    setLoading(true);
    logAnalyticsEventSetLoading("chooseTrueOnAlsoBlock", 0, true);
    await blockQuestion(questionForAction);
    setLoading(false);
    logAnalyticsEventSetLoading("chooseTrueOnAlsoBlock", 1, false);
    ShowToast("info", "この質問者をブロックしました。");
    setIsAlsoBlockAlertShown(false);
    setQuestionForAction(undefined);
  };
  const chooseFalseOnAlsoBlock = async () => {
    setIsAlsoBlockAlertShown(false);
    setQuestionForAction(undefined);
  };

  const deleteQuestion = async (question: Question) => {
    await TrashQuestionUnanswered(question.id!).catch((e) => {
      AlertAndReportError(e, "TrashQuestionUnanswered on QuestionsScreen");
    });

    setUnansweredQuestions(
      unansweredQuestions.filter((x) => x.id !== question.id)
    );
    await storage_draft.DeleteAnswerDraft(question.id).catch((e) => {
      AlertAndReportError(e, "DeleteAnswerDraft on QuestionsScreen");
    });
    Analytics.logEvent("trashed", { at: "QuestionsScreen" });
  };

  const blockQuestion = async (question: Question) => {
    if (question.id === null) {
      return;
    }
    await AddQuestionBlockHistory(question.id, question).catch((e) =>
      AlertAndReportError(e, "AddBlock on QuestionsScreen")
    );
    Analytics.logEvent("blocked", { at: "QuestionsScreen" });
  };

  const actions: ActionSheetAction[] = [
    {
      label: "複数削除/ブロックモード起動",
      onPress: () => {
        navigation.navigate("multi_choice", {
          sortedNewToOld,
          filterActivated,
        });
      },
    },
    {
      label: "不適切な質問を運営に報告",
      onPress: async (question) => {
        setLoading(true);
        logAnalyticsEventSetLoading("ReportQuestion", 0, true);
        await ReportQuestion(question, "questions_unanswered").catch((e) => {
          AlertAndReportError(e, "ReportQuestion on QuestionsScreen");
        });
        setLoading(false);
        logAnalyticsEventSetLoading("ReportQuestion", 1, false);
        Analytics.logEvent("reported", { at: "QuestionsScreen" });
        ShowToast("info", "不適切な質問として運営に報告しました。");
      },
    },
    {
      label: "質問者をブロック",
      onPress: async (question) => {
        setQuestionForAction(question);
        setLoading(true);
        logAnalyticsEventSetLoading("blockQuestion", 0, true);
        await blockQuestion(question);
        setLoading(false);
        logAnalyticsEventSetLoading("blockQuestion", 1, false);
        setIsAlsoDeleteAlertShown(true);
      },
    },
    {
      label: "質問をゴミ箱へ移動",
      onPress: async (question) => {
        setQuestionForAction(question);
        setLoading(true);
        logAnalyticsEventSetLoading("deleteQuestion", 0, true);
        await deleteQuestion(question);
        setLoading(false);
        logAnalyticsEventSetLoading("deleteQuestion", 1, false);
        setIsAlsoBlockAlertShown(true);
      },
    },
    {
      label: "キャンセル",
      onPress: (question) => {},
    },
  ];
  const actionSheetItem: ActionSheetItem = {
    actions: actions,
    cancel_index: 4,
    destructive_index: 3,
  };
  const onPressUpdate = async () => {
    setIsSearchActive(false);
    flatListRef!.current!.scrollToOffset({
      animated: true,
      offset: 0,
    });
    await new Promise((f) => setTimeout(f, 500)).catch((e) => {
      AlertAndReportError(e, "setTimeout on QuestionsScreen");
    });
    if (sortedNewToOld === false) {
      sortedNewToOld === false && setSortedNewToOld(true);
    } else {
      await resetUnansweredQuestions().catch((e) => {
        AlertAndReportError(e, "onRefresh on QuestionsScreen");
      });
    }
    Analytics.logEvent("updatePressed");
  };
  const onPressSearchButton = async () => {
    setSearchHitQuestions([]);
    setSearchTerm("");
    setIsSearchActive(!isSearchActive);
  };

  useScrollToTop(flatListRef);

  return (
    <SafeAreaView style={tw("w-full items-center bg-gray-300 flex-1")}>
      <View style={tw(`${widthString} flex flex-grow bg-gray-300 h-full`)}>
        {!loading && !refreshing && existsUpdate && (
          <ExistsUpdateButton
            onPress={onPressUpdate}
            buttonTranslationX={buttonTranslationX}
          />
        )}
        {!loading && !refreshing && showTwitterShare && isUserAuthed && (
          <TwitterSharePrompt
            onPressShare={async (user) => {
              setLoading(true);
              logAnalyticsEventSetLoading("OnPressShare", 0, true);
              await OnPressShare(user).catch((e) => {
                AlertAndReportError(e, "OnPressShare on QuestionsScreen");
              });
              setLoading(false);
              logAnalyticsEventSetLoading("OnPressShare", 1, false);
            }}
            user={user as User}
            showTipsSignupGuide={showTipsSignupGuide}
          />
        )}
        <FlatList
          ListFooterComponent={
            <View style={tw("py-1 mb-16")}>
              {isSearchActive &&
                searchHitQuestions.length === SEARCH_RESULT_COUNT && (
                  <SearchResultEndCard
                    searchResultCount={SEARCH_RESULT_COUNT}
                  />
                )}
              {isSearchActive &&
                searchHitQuestions.length === 0 &&
                moreThanLimitHits === false && (
                  <NoSearchResultCard text={searchTerm} />
                )}
              {isSearchActive && moreThanLimitHits && (
                <MoreThanLimitHitsCard limit={SEARCH_RESULT_COUNT} />
              )}
            </View>
          }
          ListHeaderComponent={<View style={tw("py-1")} />}
          data={isSearchActive ? searchHitQuestions : unansweredQuestions}
          ref={flatListRef}
          removeClippedSubviews={true}
          renderItem={({ item }) => {
            const question: Question = item;
            const checkBoxItem: CheckBoxItem = {
              isChecked: false,
              onPressCheck: () => {},
            };

            return (
              <QuestionCard
                question={question}
                isNew={question.askedAt.toMillis() > lastViewed.current}
                checkBoxItem={checkBoxItem}
                isPressable={true}
                isCaretVisible={true}
                onPress={() => onPressQuestion(item as Question & Answer)}
                onPressCaret={() => {
                  openActionSheet(actionSheetItem, question);
                }}
                isMultiChoiceActive={false}
                showReplyToIcon={
                  item.replyTo !== undefined && item.replyTo !== null
                }
              />
            );
          }}
          keyExtractor={(_, index) => index.toString()}
          onEndReached={onEndReached}
          refreshControl={
            <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
          }
        />
        {(loading || isUserAuthed === false) && <Loading />}
        <SearchButton
          isActive={isSearchActive}
          onPressSearchButton={onPressSearchButton}
          text={searchTerm}
          onChangeText={onChangeSearchTerm}
        />
      </View>
      <DialogBoxWithUpToTwoOptions
        title={"質問者をブロックしました。"}
        message={"合わせて質問を削除しますか？"}
        widthString={widthString}
        buttons={[
          { label: "削除する", onPress: chooseTrueOnAlsoDelete },
          { label: "削除しない", onPress: chooseFalseOnAlsoDelete },
        ]}
        isShown={isAlsoDeleteAlertShown}
      />
      <DialogBoxWithUpToTwoOptions
        title={"質問を削除しました。"}
        message={
          "合わせて質問者をブロックしますか？\n(質問者にはブロックされたことはわかりませんのでご安心ください。)"
        }
        widthString={widthString}
        buttons={[
          { label: "ブロックする", onPress: chooseTrueOnAlsoBlock },
          { label: "ブロックしない", onPress: chooseFalseOnAlsoBlock },
        ]}
        isShown={isAlsoBlockAlertShown}
      />
      <DialogBoxWithUpToTwoOptions
        title={"自動募集投稿を設定しますか？"}
        message={
          "定期的に質問募集をツイッターに自動投稿し、より多くの質問を受け取れます。\n自動投稿を設定してよろしいですか？\n\nこの設定はいつでも変更できます。\n最終回答から2日後に投稿されます。"
        }
        widthString={widthString}
        buttons={[
          {
            label: "設定する",
            onPress: async () => {
              await UpdateUser(user!.id!, {
                autoQuestionRequestEnabled: true,
              });
              setIsAutoShareAlertShown(false);
            },
          },
          {
            label: "設定しない",
            onPress: async () => {
              await UpdateUser(user!.id!, {
                autoQuestionRequestEnabled: false,
              });
              setIsAutoShareAlertShown(false);
            },
          },
        ]}
        isShown={isAutoShareAlertShown}
      />
      <ConfirmDialog
        title={
          "質問回答を続けるだけで毎月数万円以上の定期収入を得られる可能性があります"
        }
        titleStyle={tw("text-base font-bold underline text-left")}
        message={[
          "(多くのフォロワーからの沢山の質問に回答された一部の回答者様だけにご案内しています。)",
          "「Querie Limited」を設定するとご自身の月額サブスクが開設され、一部回答を登録者のみに公開することができ、登録者から毎月定期収入を得ることができます。",
          "フォロワー数と開始一ヶ月後の定期収入額の例",
          "・約5千人:約1万円/月",
          "・約1万人:約3万円/月",
          "",
          "ご興味があれば下記より詳細をご確認ください。(設定は最大3分程度で完了します。)",
          ,
          "なおこのご案内は今後一切表示されません。",
        ].join("\n")}
        messageStyle={tw("text-sm text-black text-left")}
        dialogStyle={{ ...tw(`${widthString} p-2`), alignSelf: "center" }}
        positiveButton={{
          title: "機能詳細へ",
          onPress: async () => {
            setLoading(true);
            logAnalyticsEventSetLoading("navigate", 0, true);
            navigation.navigate("limited_settings");
            await NotifyLimitedInviteResult(true);
            setIsInviteToLimitedAlertShown(false);
            setLoading(false);
            logAnalyticsEventSetLoading("navigate", 1, false);
          },
          titleStyle: { color: "#1E293B" },
        }}
        negativeButton={{
          title: "定期収入に興味が無い",
          onPress: async () => {
            setLoading(true);
            logAnalyticsEventSetLoading("NotifyLimitedInviteResult", 0, true);
            await NotifyLimitedInviteResult(false);
            setLoading(false);
            logAnalyticsEventSetLoading("NotifyLimitedInviteResult", 1, false);
            setIsInviteToLimitedAlertShown(false);
            alert(
              "今後もしご興味を持たれることがありましたら、詳細は「アカウント設定」の「Querie Limited設定」からご覧いただけます。"
            );
          },
          titleStyle: { color: "#1E293B" },
        }}
        visible={isInviteToLimitedAlertShown}
      />
      <ConfirmDialog
        title={"🎉初めてのLimited限定回答完了🎉"}
        titleStyle={tw("text-base font-bold underline")}
        dialogStyle={{ ...tw(`${widthString} p-2`), alignSelf: "center" }}
        positiveButton={{
          title: "OK",
          onPress: () => setIsFirstLimitedOnlyAnswerAlertShown(false),
          titleStyle: { color: "#1E293B" },
        }}
        visible={isFirstLimitedOnlyAnswerAlertShown}
      >
        <View>
          <Text style={tw("text-sm text-black")}>
            {[
              "会員登録が発生したらメールでお知らせをお送りします。",
              "メールには「登録のきっかけとなった質問」を記載しますので、会員登録につながる質問回答のタイプを見極める上で参考情報としてご覧下さい。",
              "会員登録ページには「登録すると見られる限定回答件数」が表示されるため、限定回答を増やしていくことで会員登録の加速が見込めます。",
              "",
              "引き続き全質問の3割程度までを目安に下記のような質問回答をぜひ限定公開して下さい。",
            ].join("\n")}
          </Text>
          <Unorderedlist style={tw("text-sm")}>
            <Text style={tw("text-sm")}>
              多くのユーザーが興味を持ちそうな質問への回答全文
            </Text>
          </Unorderedlist>
          <Unorderedlist style={tw("text-sm")}>
            <Text style={tw("text-sm")}>特によい回答ができたと感じる回答</Text>
          </Unorderedlist>
          <Unorderedlist style={tw("text-sm")}>
            <Text style={tw("text-sm")}>長文回答の詳細説明部分</Text>
          </Unorderedlist>
        </View>
      </ConfirmDialog>
    </SafeAreaView>
  );
};

export const QuestionsScreen = connectActionSheet(QuestionsScreenNoAS);
