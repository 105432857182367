import React, { useContext, useState } from "react";
import {
  Text,
  useWindowDimensions,
  SafeAreaView,
  TouchableOpacity,
  ScrollView,
  TextInput,
  View,
} from "react-native";
import { Ionicons } from "@expo/vector-icons";
import { StackNavigationProp } from "@react-navigation/stack";
import { RootStackParamList } from "../../navigation/types";
import { tailwind as tw } from "../../tailwind";
import { Loading } from "../../components/Loading";
import { UserContext } from "../../contexts/userContext";
import { RouteProp, useFocusEffect } from "@react-navigation/native"; // works
import {
  AlertAndReportError,
  GetProperWidth,
  OnSubmitEmail,
  CreateDmAlert,
} from "./../lib";
import {
  TipsPricesPublicDefault,
  TipsPricesPrivateDefault,
} from "../../types/lib";
import { ShowToast } from "../../lib";
import {
  DeleteTipsLimitDaily,
  GetUserActive,
  UpdateUser,
} from "../../firebase/Users";
import { DialogBoxWithUpToTwoOptions } from "../../components/DialogBoxWithUpToTwoOptions";
import { GetEmailAddressVerified } from "../../firebase/EmailAddresses";
import {
  GenerateAccoutCreateLink,
  GenerateAccoutDashboardLink,
} from "../../firebase/Functions";
import * as Linking from "expo-linking";
import { SettingSwitch } from "../../components/SettingSwitch";
import { IdNotVerified } from "../../components/IdNotVerified";
import { AccountBlocked } from "../../components/AccountBlocked";
import { UseSetHeaderLeftGoBack } from "../../hooks/useSetHeaderLeftGoBack";
import { UseTipsSettingsOnFocus } from "../../hooks/useTipsSettingsOnFocus";
import { SummaryTips } from "../../components/Texts/SummaryTips";
import { AddCustomTipsPrice } from "../../components/AddCustomTipsPrice";
import { TipsType } from "../../types/tipsType";
import RadioGroup from "react-native-radio-buttons-group";

type Props = {
  navigation: StackNavigationProp<RootStackParamList, "tips_settings">;
  route: RouteProp<RootStackParamList, "tips_settings">;
};

export const TipsSettingsScreen = ({ navigation, route }: Props) => {
  const window = useWindowDimensions();
  const properWidth = GetProperWidth(window);
  const widthString =
    properWidth.num === 1 && properWidth.denom === 1
      ? "w-full"
      : `w-${properWidth.num}/${properWidth.denom}`;
  const { user } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [tipsPublicAccepted, setTipsPublicAccepted] = useState<number[]>([]);
  const [tipsPublicAcceptedOriginal, setTipsPublicAcceptedOriginal] = useState<
    number[]
  >([]);
  const [tipsPrivateAccepted, setTipsPrivateAccepted] = useState<number[]>([]);
  const [tipsPrivateAcceptedOriginal, setTipsPrivateAcceptedOriginal] =
    useState<number[]>([]);
  const [tipsPolicy, setTipsPolicy] = useState("");
  const [
    isNoTipsAcceptedSelectedDialogueShown,
    setIsNoTipsAcceptedSelectedDialogueShown,
  ] = useState(false);
  const [isExplainPrivateQuestionShown, setIsExplainPrivateQuestionShown] =
    useState(false);
  const [mailAddress, setMailAddress] = useState("");
  const [customPricePublicString, setCustomPricePublicString] = useState("");
  const [customPricePrivateString, setCustomPricePrivateString] = useState("");
  const [mailAddressOriginal, setMailAddressOriginal] = useState("");
  const [isInitialSetting, setIsInitialSetting] = useState(false);
  const [isTipsLimitDailyEnabled, setIsTipsLimitDailyEnabled] = useState(false);
  const [tipsLimitDailyHourClearedAt, setTipsLimitedDailyHourClearedAt] =
    useState<number>();
  const [tipsLimitedDailyLimit, setTipsLimitedDailyLimit] = useState<number>();

  const { tipsSetting } = UseTipsSettingsOnFocus(() => {
    navigation.navigate("home", {
      screen: "account_setting",
      params: {},
    });
  }, user);

  UseSetHeaderLeftGoBack(navigation, () => {
    navigation.navigate("home", { screen: "account_setting", params: {} });
  });

  useFocusEffect(
    React.useCallback(() => {
      let isMounted = true;
      if (user === undefined) {
        return;
      }
      const userId = user.id;
      if (userId === undefined) {
        return;
      }
      const initialize = async () => {
        const thisUser = await GetUserActive(userId);
        if (thisUser === undefined) {
          return;
        }
        setIsTipsLimitDailyEnabled(thisUser.tipsLimitDaily !== undefined);
        setTipsLimitedDailyLimit(thisUser.tipsLimitDaily?.limit);
        setTipsLimitedDailyHourClearedAt(
          thisUser.tipsLimitDaily?.hourClearedAt
        );
        setTipsPublicAccepted(thisUser.tipsAccepted);
        setTipsPublicAcceptedOriginal(thisUser.tipsAccepted);
        setTipsPrivateAccepted(
          thisUser.tipsPrivateAccepted === undefined
            ? []
            : thisUser.tipsPrivateAccepted
        );
        setTipsPrivateAcceptedOriginal(
          thisUser.tipsPrivateAccepted === undefined
            ? []
            : thisUser.tipsPrivateAccepted
        );
        if (thisUser.tipsPolicy === "") {
          setIsInitialSetting(true);
          setTipsPolicy("Tips額に応じて優先して詳細に回答します。");
        } else {
          setTipsPolicy(thisUser.tipsPolicy);
        }

        const thisEmail = await GetEmailAddressVerified(userId);
        thisEmail !== undefined && setMailAddress(thisEmail);
        thisEmail !== undefined && setMailAddressOriginal(thisEmail);
      };
      isMounted && initialize();
      return () => {
        isMounted = false;
        setIsInitialSetting(false);
        setTipsPublicAccepted([]);
        setTipsPrivateAccepted([]);
        setMailAddress("");
        setCustomPricePublicString("");
        setCustomPricePrivateString("");
      };
    }, [])
  );

  const startingPublicFree = (newTips: number[], originalTips: number[]) =>
    originalTips.includes(0) === false && newTips.includes(0);

  const startingPublicTips = (newTips: number[], originalTips: number[]) =>
    originalTips.includes(0) &&
    originalTips.length === 1 &&
    Math.max(...newTips) > 0;

  const startingPrivateTips = (newTips: number[], originalTips: number[]) =>
    originalTips.length === 0 && newTips.length > 0;

  const getAlertType = (
    newPublic: number[],
    originalPublic: number[],
    newPrivate: number[],
    originalPrivate: number[]
  ) => {
    if (startingPrivateTips(newPrivate, originalPrivate)) {
      if (startingPublicTips(newPublic, originalPublic)) {
        return "acceptPublicAndPrivate";
      } else {
        return "acceptPrivate";
      }
    } else if (startingPublicFree(newPublic, originalPublic)) {
      return "acceptFree";
    } else if (startingPublicTips(newPublic, originalPublic)) {
      return "acceptPublic";
    } else {
      return undefined;
    }
  };

  const updateAndExit = async () => {
    if (user === undefined) {
      alert(
        "ログインしていません。ログアウトしてからもう一度ログインしてください。"
      );
      return;
    }
    const userId = user.id;
    if (userId === undefined) {
      alert(
        "ログインしていません。ログアウトしてからもう一度ログインしてください。"
      );
      return;
    }
    if (tipsPolicy === "") {
      alert("Tips質問の回答方針を入力してください。");
      return;
    }
    if (isTipsLimitDailyEnabled) {
      const validatedCount = validateCount(tipsLimitedDailyLimit);
      if (validatedCount === false) {
        alert(
          "Tips受付件数に正しい数値が入力されていません。\n\n1以上の整数を入力してください。"
        );
        return;
      }
      const validatedTime = validateTime(tipsLimitDailyHourClearedAt);
      if (validatedTime === false) {
        alert(
          "Tips受付件数リセット時刻に正しい数値が入力されていません。\n\n0から23の整数を入力してください。"
        );
        return;
      }
    }
    setLoading(true);
    const tipsPublicAcceptedSorted = [...tipsPublicAccepted].sort(
      (a, b) => a - b
    );
    const tipsPrivateAcceptedSorted = [...tipsPrivateAccepted].sort(
      (a, b) => a - b
    );
    setIsNoTipsAcceptedSelectedDialogueShown(false);
    mailAddress !== mailAddressOriginal &&
      (await OnSubmitEmail(user, mailAddress, "tips"));
    const userBeforeChange = await GetUserActive(userId);
    const newCount =
      userBeforeChange?.tipsLimitDaily === undefined
        ? 0
        : userBeforeChange.tipsLimitDaily.count;
    isTipsLimitDailyEnabled &&
    tipsLimitedDailyLimit !== undefined &&
    tipsLimitDailyHourClearedAt !== undefined
      ? await UpdateUser(userId, {
          tipsAccepted: tipsPublicAcceptedSorted,
          tipsPrivateAccepted: tipsPrivateAcceptedSorted,
          tipsPolicy: tipsPolicy,
          tipsLimitDaily: {
            limit: tipsLimitedDailyLimit,
            hourClearedAt: tipsLimitDailyHourClearedAt,
            count: newCount,
          },
        })
          .then(() => ShowToast("info", "設定を更新しました。"))
          .catch((e) => ShowToast("error", `許可されていない操作です。${e}`))
      : await DeleteTipsLimitDaily(userId)
          .then(() =>
            UpdateUser(userId, {
              tipsAccepted: tipsPublicAcceptedSorted,
              tipsPrivateAccepted: tipsPrivateAcceptedSorted,
              tipsPolicy: tipsPolicy,
            })
          )
          .then(() => ShowToast("info", "設定を更新しました。"))
          .catch((e) => ShowToast("error", `許可されていない操作です。${e}`));
    setLoading(false);
    const showTipsUpdateShareAlertType = getAlertType(
      tipsPublicAccepted,
      tipsPublicAcceptedOriginal,
      tipsPrivateAccepted,
      tipsPrivateAcceptedOriginal
    );

    navigation.navigate("home", {
      screen: "account_setting",
      params: { showTipsUpdateShareAlertType: showTipsUpdateShareAlertType },
    });
  };

  const onPressSubmit = async () => {
    if (tipsPublicAccepted.length === 0 && tipsPrivateAccepted.length === 0) {
      setIsNoTipsAcceptedSelectedDialogueShown(true);
      return;
    }
    await updateAndExit();
  };

  const onChooseCancel = () => {
    setIsNoTipsAcceptedSelectedDialogueShown(false);
  };

  type Switch = {
    isInDefault: boolean;
    label: string;
    tipsAmount: number;
  };

  const joinAndSort = (xs: number[], ys: number[]) =>
    [...new Set([...xs, ...ys])].sort((a, b) => a - b);

  const switchesPublic: Switch[] = joinAndSort(
    TipsPricesPublicDefault,
    tipsPublicAccepted
  ).map((tipsPrice) => {
    return {
      isInDefault: TipsPricesPublicDefault.includes(tipsPrice),
      label: tipsPrice === 0 ? "無料" : `¥${tipsPrice.toLocaleString()} Tips`,
      tipsAmount: tipsPrice,
    };
  });
  const switchesPrivate: Switch[] = joinAndSort(
    TipsPricesPrivateDefault,
    tipsPrivateAccepted
  ).map((tipsPrice) => {
    return {
      isInDefault: TipsPricesPrivateDefault.includes(tipsPrice),
      label: tipsPrice === 0 ? "無料" : `¥${tipsPrice.toLocaleString()} Tips`,
      tipsAmount: tipsPrice,
    };
  });

  const isValidCustomPrice = (price: string) => {
    const priceNumber = Number(price);
    if (
      isNaN(priceNumber) ||
      priceNumber <= 0 ||
      priceNumber % 1000 !== 0 ||
      priceNumber > 100000
    ) {
      return false;
    } else {
      return true;
    }
  };

  const onSubmitAddPrice = async (tipsType: TipsType) => {
    const price =
      tipsType === "public"
        ? customPricePublicString
        : customPricePrivateString;
    if (isValidCustomPrice(price) === false) {
      alert(
        "追加できません。\n\n追加価格は1,000円単位で100,000円未満の数値で設定してください。"
      );
      return;
    }

    const userId = user?.id;
    if (userId === undefined) {
      alert(
        "ログインしていません。ログアウトしてからもう一度ログインしてください。"
      );
      return;
    }
    const priceNubmer = Number(price);
    if (
      (tipsType === "public" && tipsPublicAccepted.includes(priceNubmer)) ||
      (tipsType === "private" && tipsPrivateAccepted.includes(priceNubmer))
    ) {
      alert("すでに有効化されています。");
      return;
    }
    tipsType === "public"
      ? setTipsPublicAccepted(joinAndSort(tipsPublicAccepted, [priceNubmer]))
      : setTipsPrivateAccepted(joinAndSort(tipsPrivateAccepted, [priceNubmer]));
    ShowToast(
      "success",
      "成功しました。\n画面下部の「設定を保存」を押すと\n設定が保存されます"
    );
  };

  return (
    <SafeAreaView style={tw("w-full items-center bg-gray-300 flex-1")}>
      <ScrollView style={tw(`${widthString} mb-2 p-4`)}>
        {tipsSetting === undefined ? (
          <Loading />
        ) : tipsSetting.idVerified !== true ? (
          <IdNotVerified
            onPressToDashboard={async () => {
              setLoading(true);
              const dashboardUrl = await GenerateAccoutDashboardLink();
              Linking.openURL(dashboardUrl.response);
              setLoading(false);
            }}
            onPressToRegister={async () => {
              setLoading(true);
              const dashboardUrl = await GenerateAccoutCreateLink();
              Linking.openURL(dashboardUrl.response);
              setLoading(false);
            }}
          />
        ) : tipsSetting.chargesEnabled === false ||
          tipsSetting.isInReview === true ? (
          <AccountBlocked
            onPressStripe={async () => {
              setLoading(true);
              await GenerateAccoutDashboardLink().then((dashboardUrl) =>
                Linking.openURL(dashboardUrl.response)
              );
              setLoading(false);
            }}
            onPressInquiry={async () => {
              setLoading(true);
              const canOpenUrl = await Linking.canOpenURL(
                "twitter://messages/compose?recipient_id=1440949863097585665"
              ).catch((e) => {
                AlertAndReportError(e, "canOpenUrl on AcountScreen");
                return false;
              });
              setLoading(false);
              CreateDmAlert(canOpenUrl);
            }}
          />
        ) : (
          <>
            <SummaryTips />
            <TouchableOpacity
              style={tw(
                `border border-bluegray-800 bg-white border-bluegray-800 w-full rounded-full mb-2 p-2 flex`
              )}
              onPress={() => navigation.navigate("tips_explain")}
            >
              <Text
                style={tw(`text-bluegray-800 text-base font-bold text-center`)}
              >
                Querie Tips詳細を確認
              </Text>
            </TouchableOpacity>
            <Text
              style={tw(
                "bg-bluegray-800 text-center text-base text-white font-bold p-2 my-2"
              )}
            >
              受け取る公開質問タイプを選択
            </Text>
            {switchesPublic.map((x) => (
              <SettingSwitch
                key={x.tipsAmount}
                labels={[x.label]}
                isInDefault={x.isInDefault}
                value={
                  tipsPublicAccepted === undefined
                    ? false
                    : tipsPublicAccepted.includes(x.tipsAmount)
                }
                onValueChange={() => {
                  tipsPublicAccepted.includes(x.tipsAmount)
                    ? setTipsPublicAccepted(
                        tipsPublicAccepted.filter((tip) => tip !== x.tipsAmount)
                      )
                    : setTipsPublicAccepted([
                        ...tipsPublicAccepted,
                        x.tipsAmount,
                      ]);
                  x.isInDefault === false &&
                    ShowToast(
                      "success",
                      "成功しました。\n画面下部の「設定を保存」を押すと\n設定が保存されます"
                    );
                }}
              />
            ))}
            <AddCustomTipsPrice
              label="公開Tipsの独自設定価格を追加"
              value={customPricePublicString}
              onChange={(customPricePublicString) =>
                isNaN(Number(customPricePublicString))
                  ? alert("半角数値を入力して下さい。")
                  : setCustomPricePublicString(customPricePublicString)
              }
              onSubmit={() => onSubmitAddPrice("public")}
            />

            <View
              style={tw(
                "flex-row my-2 justify-center items-center bg-bluegray-800"
              )}
            >
              <Text
                style={tw("p-2 text-base font-bold text-center text-white")}
              >
                受け取る非公開質問タイプを選択
              </Text>

              <Ionicons
                name="help-circle-outline"
                size={24}
                color="white"
                onPress={() => {
                  setIsExplainPrivateQuestionShown(true);
                }}
              />
            </View>

            {switchesPrivate.map((x) => (
              <SettingSwitch
                key={x.tipsAmount}
                labels={[x.label]}
                isInDefault={x.isInDefault}
                value={
                  tipsPrivateAccepted === undefined
                    ? false
                    : tipsPrivateAccepted.includes(x.tipsAmount)
                }
                onValueChange={() => {
                  tipsPrivateAccepted.includes(x.tipsAmount)
                    ? setTipsPrivateAccepted(
                        tipsPrivateAccepted.filter(
                          (tip) => tip !== x.tipsAmount
                        )
                      )
                    : setTipsPrivateAccepted([
                        ...tipsPrivateAccepted,
                        x.tipsAmount,
                      ]);
                  x.isInDefault === false &&
                    ShowToast(
                      "success",
                      "画面下部の「設定を保存」を押すと\n設定が保存されます"
                    );
                }}
              />
            ))}
            <AddCustomTipsPrice
              label="非公開Tipsの独自設定価格を追加"
              value={customPricePrivateString}
              onChange={(customPricePrivateString) =>
                isNaN(Number(customPricePrivateString))
                  ? alert("半角数値を入力して下さい。")
                  : setCustomPricePrivateString(customPricePrivateString)
              }
              onSubmit={() => onSubmitAddPrice("private")}
            />

            <Text
              style={tw(
                "bg-bluegray-800 text-center text-base text-white font-bold p-2 mt-2 mb-4"
              )}
            >
              Tips受付制限(一日あたり)
            </Text>

            <View style={tw("flex-row flex-wrap items-center justify-center")}>
              <View style={tw("mr-2")}>
                <RadioGroup
                  radioButtons={[
                    {
                      id: "1",
                      label: "設定しない",
                      value: "true",
                      selected: isTipsLimitDailyEnabled === false,
                    },
                    {
                      id: "2",
                      label: "設定する",
                      value: "false",
                      selected: isTipsLimitDailyEnabled === true,
                    },
                  ]}
                  onPress={(value) =>
                    setIsTipsLimitDailyEnabled(value[1].selected === true)
                  }
                  layout="row"
                />
              </View>
              <View style={tw("flex-row items-center")}>
                <TextInput
                  editable={isTipsLimitDailyEnabled}
                  style={tw(
                    `flex-1 w-24 rounded-lg text-xs border text-gray-800 h-12 p-2 mb-2 ${
                      isTipsLimitDailyEnabled ? "bg-white" : "bg-gray-400"
                    }`
                  )}
                  autoCapitalize="none"
                  keyboardType="numeric"
                  autoCorrect={false}
                  onChangeText={(value) => {
                    isNaN(Number(value))
                      ? alert("半角数値を入力して下さい。")
                      : setTipsLimitedDailyLimit(
                          value === "" ? undefined : Number(value)
                        );
                  }}
                  value={
                    tipsLimitedDailyLimit === undefined
                      ? ""
                      : String(tipsLimitedDailyLimit)
                  }
                  placeholder={isTipsLimitDailyEnabled ? "受付件数" : ""}
                  placeholderTextColor="gray"
                />
                <Text style={tw("mx-2")}>件/日受付</Text>
                <View style={tw("w-4")}></View>
                <TextInput
                  editable={isTipsLimitDailyEnabled}
                  style={tw(
                    `flex-1 w-24 rounded-lg text-xs border text-gray-800 h-12 p-2 mb-2 ${
                      isTipsLimitDailyEnabled ? "bg-white" : "bg-gray-400"
                    }`
                  )}
                  autoCapitalize="none"
                  keyboardType="numeric"
                  autoCorrect={false}
                  onChangeText={(value) => {
                    isNaN(Number(value))
                      ? alert("半角数値を入力して下さい。")
                      : setTipsLimitedDailyHourClearedAt(
                          value === "" ? undefined : Number(value)
                        );
                  }}
                  value={
                    tipsLimitDailyHourClearedAt === undefined
                      ? ""
                      : String(tipsLimitDailyHourClearedAt)
                  }
                  placeholder={isTipsLimitDailyEnabled ? "リセット時刻" : ""}
                  placeholderTextColor="gray"
                />
                <Text style={tw("mx-2")}>時リセット</Text>
              </View>
            </View>
            <Text
              style={tw(
                "bg-bluegray-800 text-center text-base text-white font-bold p-2 mt-2 mb-4"
              )}
            >
              Tips質問の回答方針を設定
            </Text>
            <TextInput
              style={tw(
                `rounded-lg text-sm border text-gray-800 bg-white h-20 mb-2 p-2`
              )}
              onChangeText={(tipsPolicy) => setTipsPolicy(tipsPolicy)}
              value={tipsPolicy}
              placeholder={`Tips質問の回答方針を入力`}
              multiline={true}
            />
            <Text
              style={tw(
                "bg-bluegray-800 text-center text-base text-white font-bold p-2 mt-2"
              )}
            >
              メールアドレスを変更
            </Text>
            <TextInput
              style={tw(
                "bg-white rounded-lg text-sm border w-full text-gray-800 h-12 mt-4 mb-2 p-2"
              )}
              autoCapitalize="none"
              textContentType="emailAddress"
              keyboardType="email-address"
              autoCorrect={false}
              onChangeText={(mailAddress) => setMailAddress(mailAddress)}
              value={mailAddress}
              placeholder={"メールアドレスを入力"}
            />
            <View style={tw("w-full mb-2")}>
              <Text style={tw("text-center")}>
                (このメールアドレスは一切公開されません)
              </Text>
            </View>
            <TouchableOpacity
              style={tw(`bg-bluegray-800 w-full rounded-full mt-2 p-2 flex`)}
              onPress={onPressSubmit}
            >
              <Text style={tw(`text-white text-base font-bold text-center`)}>
                {isInitialSetting
                  ? "手順3/3:設定を保存して募集を開始"
                  : "設定を保存"}
              </Text>
            </TouchableOpacity>

            <View style={tw(`h-80`)} />
          </>
        )}
      </ScrollView>
      <DialogBoxWithUpToTwoOptions
        title={"受け取る質問タイプが選択されていません。"}
        message={"質問が一切受け取れなくなりますがよろしいですか？"}
        widthString={widthString}
        buttons={[
          { label: "ＯＫ", onPress: updateAndExit },
          { label: "キャンセル", onPress: onChooseCancel },
        ]}
        isShown={isNoTipsAcceptedSelectedDialogueShown}
      />
      <DialogBoxWithUpToTwoOptions
        title={"「非公開質問」とは"}
        widthString={widthString}
        message={
          "TwitterやQuerie.me上で公開されない質問を受け付ける事ができます。\n\n回答内容はQuerie.meから質問者へメールで送信されます。"
        }
        buttons={[
          {
            label: "OK",
            onPress: () => {
              setIsExplainPrivateQuestionShown(false);
            },
          },
        ]}
        isShown={isExplainPrivateQuestionShown}
      />
      {loading && <Loading />}
    </SafeAreaView>
  );
};

// 1以上の整数でなくてはならず、NaNも許容しない
const validateCount = (value: number | undefined): boolean =>
  value === undefined
    ? false
    : value < 1
    ? false
    : value % 1 !== 0
    ? false
    : isNaN(value)
    ? false
    : true;

// 0と23の間の整数でなくてはならず、NaNも許容しない
const validateTime = (value: number | undefined): boolean =>
  value === undefined
    ? false
    : value < 0
    ? false
    : value > 23
    ? false
    : value % 1 !== 0
    ? false
    : isNaN(value)
    ? false
    : true;
