import React from "react";
import { Text, View, Switch, Touchable, TouchableOpacity } from "react-native";
import { tailwind as tw } from "../tailwind";

type Props = {
  key: number;
  isInDefault?: boolean; //　Tips選択のときだけ使用
  labels: string[];
  value: boolean;
  onValueChange: () => void;
};

export const SettingSwitch = ({
  key,
  isInDefault,
  labels,
  value,
  onValueChange,
}: Props) => (
  <View
    style={tw("bg-white flex-row p-2 justify-between my-2 items-center")}
    key={key}
  >
    {isInDefault === false ? (
      <>
        <View>
          {labels.map((label) => (
            <Text style={tw("ml-2 text-lg self-start")}>
              {label}(独自設定価格)
            </Text>
          ))}
        </View>
        <TouchableOpacity onPress={onValueChange}>
          <Text style={tw("text-base font-bold underline px-2")}>削除</Text>
        </TouchableOpacity>
      </>
    ) : (
      <>
        <View>
          {labels.map((label) => (
            <Text style={tw("ml-2 text-lg self-start")}>{label}</Text>
          ))}
        </View>
        <Switch
          value={value}
          style={tw("self-end mr-2 self-center")}
          onValueChange={onValueChange}
        />
      </>
    )}
  </View>
);
