import React from "react";
import { View, Text, TouchableOpacity } from "react-native";
import { tailwind as tw } from "../../tailwind";
import { FontAwesome, Feather } from "@expo/vector-icons";
import { SinceAskedIcon } from "../Icons/SinceAskedIcon";
import { convertToSinceAsked } from "../lib";
import { TipsIcon } from "../Icons/TipsIcon";
import { LikedIcon } from "../Icons/LikedIcon";
import { Timestamp } from "firebase/firestore";
import { IsPrivateIcon } from "../Icons/IsPrivateIcon";

type Props = {
  tips?: number;
  askedAt?: Timestamp;
  isPressable: boolean;
  isCaretVisible: boolean;
  onPressCaret: () => void;
  smaller?: boolean;
  isLiked?: boolean;
  isAskedAsPrivate?: boolean;
};
export const QACardHeaderQuestioner = ({
  tips,
  askedAt,
  isPressable,
  isCaretVisible,
  onPressCaret,
  smaller,
  isLiked,
  isAskedAsPrivate,
}: Props) => {
  return (
    <>
      <View style={tw("flex-row w-full")}>
        {tips !== undefined && <TipsIcon tips={tips} />}
        {askedAt !== undefined && (
          <SinceAskedIcon sinceAsked={convertToSinceAsked(askedAt)} />
        )}
        {isAskedAsPrivate === true && <IsPrivateIcon />}
        {isLiked === true && <LikedIcon />}
        {isCaretVisible && (
          <TouchableOpacity
            style={tw("absolute right-0 mb-1 mr-1 mt-3 p-1")}
            onPress={onPressCaret}
          >
            <Feather name="more-horizontal" size={24} color={"#D1D5DB"} />
          </TouchableOpacity>
        )}
      </View>
      <View style={tw("flex-row mt-2 justify-center items-center")}>
        <FontAwesome
          name="user-o"
          color={"rgb(107, 114, 128)"}
          size={smaller ? 24 : 32}
          style={smaller ? tw("w-6") : tw("w-8")}
        />
        <Text
          style={tw(
            `pl-2 flex-1 ${
              smaller ? "text-sm" : "text-base"
            } text-gray-500 mr-2`
          )}
        >
          質問者さん
        </Text>
      </View>
    </>
  );
};
