import React from "react";
import { Text } from "react-native";
import { tailwind as tw } from "../../tailwind";

export const MailAddressConfirmedText = () => {
  return (
    <>
      <Text
        style={tw(
          "font-bold text-xl mb-2 bg-bluegray-800 text-white text-center w-full p-2"
        )}
      >
        メールアドレスが確認されました
      </Text>

      <Text style={tw("text-lg text-center mb-2")}>
        次に利用規約確認の上、報酬受け取り口座の設定をお願いします。
      </Text>
      <Text style={tw("text-base text-center mb-2")}>
        遷移先で入力頂く情報は法令等に基づきStripeが取得・保持するものです。
      </Text>
      <Text style={tw("text-base text-center mb-2")}>
        当社は、遷移先で入力頂いた情報を保持せず、また、一切閲覧いたしませんので、ご安心ください。
      </Text>
    </>
  );
};
