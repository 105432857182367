import React from "react";
import { View, Text, Image } from "react-native";
import { tailwind as tw } from "../../tailwind";
import { SinceAskedIcon } from "../Icons/SinceAskedIcon";
import { convertToSinceAsked } from "../lib";
import { Timestamp } from "firebase/firestore";

type Props = {
  answeredAt?: Timestamp;
  isPressable: boolean;
  answerUserIconUrl: string;
  answerUserName: string;
  smaller?: boolean;
};
export const QACardHeaderAnswerer = ({
  answeredAt,
  isPressable,
  answerUserIconUrl,
  answerUserName,
  smaller,
}: Props) => {
  return (
    <>
      <View
        style={{
          borderBottomWidth: 1,
          borderBottomColor: "lightgrey",
        }}
      ></View>
      <View style={tw("flex-row w-full")}>
        {answeredAt !== undefined && (
          <SinceAskedIcon sinceAsked={convertToSinceAsked(answeredAt)} />
        )}
      </View>
      <View style={tw("flex-row mt-2 justify-center items-center")}>
        <Image
          source={{ uri: answerUserIconUrl }}
          style={tw(`${smaller ? "h-6 w-6" : "h-8 w-8"} rounded-full`)}
        />
        <Text
          style={tw(
            `pl-2 flex-1 ${smaller ? "text-sm" : "text-base"} text-gray-500`
          )}
        >
          {answerUserName}さん
        </Text>
      </View>
    </>
  );
};
