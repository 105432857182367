// Library shared among app, firebase, web
import { User } from "./types/user";

export const BiGramize = (text: string) => {
  //Dont't use test.split (compare "😁😁".split("") with [..."😁😁"])
  const textStripped = [...text];
  if (textStripped.length < 2) {
    return [];
  }
  let result: string[] = [];
  for (let i = 0; i < textStripped.length - 1; i++) {
    result.push([textStripped[i], textStripped[i + 1]].join(""));
  }
  return [...new Set(result)];
};

export const MbSubstr = (text: string, count: number) =>
  [...text].slice(0, count).join("");

export const MbLength = (text: string) => [...text].length;

const generateTruncateText = (
  answer: string,
  isLimitedOnly: boolean,
  answerTextLimitedOnly?: string
) => {
  const text = `${answer}`;
  const textWithLimitedOnly = isLimitedOnly
    ? `${answer}${answerTextLimitedOnly ?? ""}`
    : undefined;

  if (textWithLimitedOnly === undefined) {
    if (MbLength(text) > 114) {
      return (
        MbSubstr(text, 100) +
        `…\n\n(残り${(MbLength(text) - 100).toLocaleString()}字)`
      );
    } else {
      return text;
    }
  } else if (MbLength(textWithLimitedOnly) > 114) {
    return (
      MbSubstr(text, 100) +
      `…\n\n(残り${(MbLength(textWithLimitedOnly) - 100).toLocaleString()}字)`
    );
  } else {
    const truncateCount = MbLength(textWithLimitedOnly) - MbLength(text);
    if (text.length === 0) {
      return `(${truncateCount.toLocaleString()}文字の回答を見るにはリンクへ)`;
    } else {
      return text + `…\n\n(残り${truncateCount.toLocaleString()}字)`;
    }
  }
};

const generateTweetUrl = (
  id: string | null,
  answeredAtSec: number,
  env: "dev" | "prod"
) => {
  const domain =
    env === "prod" ? "https://querie.me/" : "https://dev.querie.me/";
  return `\n${domain}answer/${id}?timestamp=${answeredAtSec.toString()}`;
};

export const GenerateHashtag = (screenName?: string) =>
  `\n#querie_${screenName === undefined ? "me" : screenName}\n`;

export const GenerateTweetContent = (
  questionId: string | null,
  answerText: string,
  isLimitedOnly: boolean,
  user: User,
  env: "dev" | "prod",
  answeredAt: number,
  answerTextLimitedOnly?: string
) => {
  return {
    text: generateTruncateText(
      answerText,
      isLimitedOnly,
      answerTextLimitedOnly
    ),
    hashtags: GenerateHashtag(user.screenName),
    url: generateTweetUrl(questionId, answeredAt, env),
  };
};
