import { DialogBoxWithUpToTwoOptions } from "../DialogBoxWithUpToTwoOptions";

type Props = {
  widthString: string;
  onPresses: (() => void)[];
  isShown: boolean;
};

export const DisableShareDialog = ({
  widthString,
  onPresses,
  isShown,
}: Props) => (
  <DialogBoxWithUpToTwoOptions
    title={"ツイート投稿設定を解除しました"}
    widthString={widthString}
    message={[
      "再度有効化されるまで、質問に回答しても回答がツイートされません。",
      "再度有効化するには回答ボタン下の「Twitterで回答を共有する」のチェックボックスにチェックを入れてください。",
    ].join("\n")}
    buttons={[
      {
        label: "　　ＯＫ　　",
        onPress: onPresses[0],
      },
      {
        label: "以後表示しない",
        onPress: onPresses[1],
      },
    ]}
    isShown={isShown}
  />
);
