import { StackNavigationProp } from "@react-navigation/stack";
import { useEffect } from "react";
import { BackButton } from "../components/Buttons/BackButton";
import { RootStackParamList } from "../navigation/types";

export const UseSetHeaderLeftGoBack = (
  navigation: StackNavigationProp<RootStackParamList>,
  onPress: () => void
) => {
  useEffect(() => {
    let isMounted = true;
    isMounted &&
      navigation.setOptions({
        headerLeft: (props) => <BackButton onPress={onPress} />,
      });
    return () => {
      isMounted = false;
    };
  }, []);
};
