import { Timestamp } from "firebase/firestore";
import dayjs from "dayjs";
import * as Clipboard from "expo-clipboard";
import { ShowToast } from "../lib";
import { Alert, Platform } from "react-native";
import * as Linking from "expo-linking";

export const convertToSinceAsked = (timestamp: Timestamp) => {
  const diff = Timestamp.now().seconds - timestamp.seconds;
  if (diff < 0) {
    return `0秒前`;
  } else if (diff < 60) {
    return `${diff}秒前`;
  } else if (diff < 60 * 60) {
    return `${Math.round(diff / 60)}分前`;
  } else if (diff < 60 * 60 * 24) {
    return `${Math.round(diff / (60 * 60))}時間前`;
  } else {
    const date = timestamp.toDate();
    if (diff < 60 * 60 * 24 * 365) {
      return `${dayjs(date).format("MM月DD日")}`;
    } else {
      return `${dayjs(date).format("YYYY年MM月DD日")}`;
    }
  }
};

export const OnPressHyperLink = (url: string) => {
  if (Platform.OS === "web") {
    const willOpenLink = confirm(
      `下記リンクを開きます。よろしいですか？ \n\n ${url}`
    );
    willOpenLink && Linking.openURL(url);
  } else {
    Alert.alert("下記のリンクを開きます", url, [
      {
        text: "リンクを開く",
        onPress: () => Linking.openURL(url),
      },
      {
        text: "リンクをコピー",
        onPress: async () => {
          await Clipboard.getStringAsync()
            .then(() => {
              Clipboard.setString(url);
              ShowToast("info", "クリップボードにコピーしました。");
            })
            .catch(() =>
              ShowToast(
                "error",
                "ご利用の環境ではコピーできません。機能を利用するにはアプリ版をご利用ください。"
              )
            );
        },
      },
      {
        text: "キャンセル",
        onPress: () => {},
      },
    ]);
  }
};
