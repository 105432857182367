import React from "react";
import { View, Text } from "react-native";
import { tailwind as tw } from "../../tailwind";

export const IsPrivateIcon = () => {
  return (
    <View style={tw(`border border-bluegray-800 mr-2 mt-4 px-1`)}>
      <Text style={tw("text-xs text-bluegray-800 text-center")}>非公開</Text>
    </View>
  );
};
