import React from "react";
import { Bullets } from "./Bullets";
import { SummaryLimited } from "./Texts/SummaryLimited";

export const ExplainLimited = () => {
  return (
    <>
      <SummaryLimited />
      <Bullets
        header="仕組み"
        texts={[
          "回答者はQuerie Limited開設時に月額料金(500円以上10,000円以下で100円単位で設定可能)を設定します。",
          "Querie Limited開設後は質問への回答を「Querie Limited限定公開」に指定できます。",
          "限定公開回答は、質問本文と回答文のうち回答者が指定した「一般公開」部分のみ一般に公開され、閲覧者が回答文全文を閲覧するためには回答者が開設したQuerie Limitedに入会する必要があります。",
          "また、質問者だけはQuerie Limitedに加入していなくても、限定公開部分を含めた回答全文をメールで受領できるように設定することもできます。",
          "回答者は毎月発生した月額料金の合計からサービス手数料20%を差し引いた金額を得られます。",
        ]}
      />
      <Bullets
        header="収益の受け取り"
        texts={[
          "各月のQuerie Limited収益は、回答者のStripeアカウントにプールされ、Querie Tips質問に回答している場合はTips収益も合算した上で月末残高が翌月末に回答者に引き渡されます。",
          "残高が10,000円以上の場合は残高から入金手数料450円を差し引いた金額が銀行口座へ振り込まれます。",
          "残高が10,000円未満の場合はAmazon残高分のギフトカードが電子メールで送付されます。",
        ]}
      />
      <Bullets
        header="限定公開質問選択に適した質問回答例"
        texts={[
          "多くのユーザーが興味を持ちそうな質問への回答全文",
          "特によい回答ができたと感じる回答の全文",
          "長文回答の詳細説明部分",
        ]}
      />
      <Bullets
        header="その他詳細"
        texts={[
          "Querie Limitedを開設するためには、回答者はStripeアカウントを取得し、Stripeに対する本人確認手続を完了していただく必要があります。",
          "Querie Limitedの月額料金は一度設定すると変更できないため、ご注意ください。",
          "Querie Tips付き質問をQuerie Limited限定公開した場合は質問者はQuerie Limitedに登録していなくてもメールで回答を閲覧することができます。",
        ]}
      />
    </>
  );
};
