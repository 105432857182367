import React from "react";
import { TouchableOpacity, Text } from "react-native";
import { tailwind as tw } from "../../tailwind";

type Props = {
  label: string;
  onPress: () => void;
  smallerText?: boolean;
};

export const SubmitButton = ({ label, onPress, smallerText }: Props) => {
  return (
    <TouchableOpacity
      style={tw("flex-1 h-12 m-2 bg-bluegray-800 rounded-full justify-center")}
      onPress={onPress}
    >
      <Text
        style={tw(
          `${
            smallerText ? "text-sm" : "text-base"
          } p-2 font-bold text-center text-white`
        )}
      >
        {label}
      </Text>
    </TouchableOpacity>
  );
};
