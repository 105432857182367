import Constants from "expo-constants";
import AsyncStorage from "@react-native-async-storage/async-storage";

export const SetPreviousAppVersion = async () => {
  console.log("SetPreviousAppVersion");
  const currentVersion =
    Constants.manifest === null ? undefined : Constants.manifest.version;
  console.log("SetPreviousAppVersion", currentVersion);
  currentVersion !== undefined &&
    (await AsyncStorage.setItem("@previousAppVersion", currentVersion));
};

export const GetPreviousAppVersion = async () => {
  const previousAppVersion = await AsyncStorage.getItem("@previousAppVersion");
  return previousAppVersion;
};
