import React from "react";
import { Text, View } from "react-native";
import { tailwind as tw } from "../tailwind";
import { MaterialCommunityIcons } from "@expo/vector-icons";

type Props = { iconName: any; label: string; count?: number };

export const QuestionCountListItem = ({ iconName, label, count }: Props) => {
  return (
    <View style={tw("h-12  bg-white px-6 mx-4 my-2 flex-row items-center")}>
      <MaterialCommunityIcons name={iconName} size={30} color={"#1E293B"} />
      <Text style={tw("mx-4 text-xl")}>{`${label}:`}</Text>
      <Text style={tw("text-xl text-right")}>
        {count === undefined ? "" : count}
      </Text>
    </View>
  );
};
