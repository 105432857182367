import { getAuth } from "firebase/auth";
import Constants from "expo-constants";
import { initializeApp } from "firebase/app";
import { initializeFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";
import { getStorage } from "firebase/storage";
import { LogBox, Platform } from "react-native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { getReactNativePersistence, initializeAuth } from "firebase/auth";

LogBox.ignoreLogs(["Setting a timer"]);
const app = initializeApp(Constants.manifest?.extra!.firebase);

export const auth =
  Platform.OS === "web"
    ? getAuth()
    : initializeAuth(app, {
        persistence: getReactNativePersistence(AsyncStorage),
      });

export const db = initializeFirestore(app, {
  experimentalAutoDetectLongPolling: true,
});
export const storage = getStorage(app);
export const functions = getFunctions(app, "asia-northeast1");
