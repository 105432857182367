import { PlatformOSType } from "react-native";
import Toast from "react-native-root-toast";
import { getAuth } from "firebase/auth";

export const ShowToast = (kind: "success" | "info" | "error", text: string) => {
  const position = -100;
  let backgroundColor;
  let textColor;
  if (kind === "success") {
    backgroundColor = "#1F2937";
    textColor = "white";
  } else if (kind === "error") {
    backgroundColor = "#B91C1C";
    textColor = "white";
  } else {
    backgroundColor = "#CBD5E1";
    textColor = "black";
  }

  Toast.show(text, {
    position: position,
    shadow: true,
    backgroundColor: backgroundColor,
    textColor: textColor,
    textStyle: { fontWeight: "bold" },
  });
};

export const GetProperTabButtonPosition = (
  os: PlatformOSType,
  screenSize: "xl" | "lg" | "md" | "sm"
) => {
  if (os === "web") {
    return screenSize === "xl"
      ? "25vw"
      : screenSize === "lg"
      ? "12.5vw"
      : screenSize === "md"
      ? "8.333333333vw"
      : "0vw";
  } else {
    return screenSize === "xl"
      ? "50%"
      : screenSize === "lg"
      ? "25%"
      : screenSize === "md"
      ? "16.6666666666%"
      : "0%";
  }
};

export const GetCurrentUser = () => getAuth().currentUser;

export const GetCurrentUserId = () => {
  const currentUser = GetCurrentUser();
  if (currentUser === null) {
    throw new Error("Current user is null");
  }
  return currentUser.uid;
};
