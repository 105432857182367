import React, { useContext, useState, useEffect } from "react";
import {
  View,
  ScrollView,
  Text,
  Image,
  useWindowDimensions,
  SafeAreaView,
  Platform,
} from "react-native";
import { ShowToast } from "../lib";
import { UserContext } from "../contexts/userContext";
import { User } from "../types/user";
import * as db from "../firebase/Users";
import * as firebase_storage from "../firebase/FirebaseStorage";
import * as storage from "../local_storage/user";
import { tailwind as tw } from "../tailwind";
import { SubmitButton } from "../components/Buttons/SubmitButton";
import { ProfileItem } from "../components/ProfileItem";
import { Loading } from "../components/Loading";
import { GetProperWidth, PickImage } from "./lib";
import { SubmitButtonInactive } from "../components/Buttons/SubmitButtonInactive";
import { AlertAndReportError } from "./lib";
import { StackNavigationProp } from "@react-navigation/stack";
import { RootStackParamList } from "../navigation/types";
import { BackButton } from "../components/Buttons/BackButton";
import { UseSetHeaderLeftGoBack } from "../hooks/useSetHeaderLeftGoBack";

type Props = {
  navigation: StackNavigationProp<RootStackParamList, "terms">;
};

export const ProfileSettingScreen = ({ navigation }: Props) => {
  const window = useWindowDimensions();
  const properWidth = GetProperWidth(window);
  const widthString =
    properWidth.num === 1 && properWidth.denom === 1
      ? "w-full"
      : `w-${properWidth.num}/${properWidth.denom}`;
  const { user, setUser } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [userNameText, setUserNameText] = useState(user?.name);
  const [userProfileText, setUserProfileText] = useState(user?.profile);
  const [userMessageText, setUserMessageText] = useState(user?.message);

  UseSetHeaderLeftGoBack(navigation, () => {
    navigation.navigate("home", { screen: "account_setting", params: {} });
  });

  const isUserNameValid = () => {
    if (userNameText === undefined) {
      return false;
    } else if (userNameText.length === 0) {
      return false;
    } else if (userNameText.length > 50) {
      return false;
    } else {
      return true;
    }
  };

  const isUserProfileValid = () => {
    if (userProfileText == undefined) {
      return true;
    } else if (userProfileText.length > 160) {
      return false;
    } else {
      return true;
    }
  };

  const isUserMessageValid = () => {
    if (userMessageText == undefined) {
      return true;
    } else if (userMessageText.length > 140) {
      return false;
    } else {
      return true;
    }
  };

  const onPressUpdate = async () => {
    setLoading(true);
    const data = {
      name: userNameText,
      profile: userProfileText,
      message: userMessageText,
    } as User;
    await db.UpdateUser(user?.id!, data).catch((e) => {
      AlertAndReportError(e, "UpdateUser on ProfileSettingScreen");
    });
    setUser({ ...user, ...data });
    await storage.SetUser({ ...user, ...data }).catch((e) => {
      AlertAndReportError(e, "SetUser on ProfileSettingScreen");
    });
    setLoading(false);
    ShowToast("info", "プロフィールが更新されました");
  };

  const onPickImage = async () => {
    if (Platform.OS === "web") {
      alert("この操作はモバイルアプリでのみ許可されています。");
      return;
    }
    setLoading(true);
    const uri = await PickImage();
    if (uri === undefined) {
      setLoading(false);
      return;
    }
    const storagePath = `icons/${user!.id!}.png`;
    const iconImageUrl = await firebase_storage
      .UploadImage(uri, storagePath)
      .catch((e) => {
        AlertAndReportError(e, "UploadImage on ProfileSettingScreen");
        return "";
      });
    await db
      .UpdateUser(user?.id!, { iconImageUrl: iconImageUrl })
      .catch((e) => {
        AlertAndReportError(e, "UpdateUser on ProfileSettingScreen");
      });
    setUser({ ...user!, iconImageUrl: iconImageUrl });
    await storage
      .SetUser({ ...user!, iconImageUrl: iconImageUrl })
      .catch((e) => {
        AlertAndReportError(e, "SetUser on ProfileSettingScreen");
      });
    setLoading(false);
    ShowToast("info", "プロフィールが画像が更新されました");
  };
  //TODO show word count
  return (
    <SafeAreaView style={tw("w-full items-center bg-gray-300 flex-1")}>
      <ScrollView style={tw(`${widthString} flex flex-grow bg-gray-300`)}>
        <View style={tw("mx-4 mt-4 p-4 bg-white")}>
          <Text style={tw("text-lg mb-2")}>{"プロフィール画像"}</Text>
          <View style={tw("flex-row items-center")}>
            <Image
              source={{ uri: user!.iconImageUrl }}
              style={tw("h-24 w-24 rounded-full")}
            />
            <View style={tw("flex-1 h-16")}>
              <SubmitButton label={"画像を選択"} onPress={onPickImage} />
            </View>
          </View>
        </View>
        <View style={tw("mx-4 mt-4 p-4 bg-white")}>
          {isUserNameValid() ? (
            <ProfileItem
              title={"ユーザー名"}
              isMultiline={false}
              text={userNameText!}
              onChange={setUserNameText}
              height={10}
              isValid={true}
            />
          ) : (
            <ProfileItem
              title={"ユーザー名\n(1-50文字で設定してください。)"}
              isMultiline={false}
              text={userNameText!}
              onChange={setUserNameText}
              height={10}
              isValid={false}
            />
          )}
          {isUserProfileValid() ? (
            <ProfileItem
              title={"プロフィール"}
              isMultiline={true}
              text={userProfileText!}
              onChange={setUserProfileText}
              height={40}
              isValid={true}
            />
          ) : (
            <ProfileItem
              title={"プロフィール\n(160文字以下で設定してください。)"}
              isMultiline={true}
              text={userProfileText!}
              onChange={setUserProfileText}
              height={40}
              isValid={false}
            />
          )}
          {isUserMessageValid() ? (
            <ProfileItem
              title={"質問者へのメッセージ"}
              isMultiline={true}
              text={userMessageText!}
              onChange={setUserMessageText}
              height={40}
              isValid={true}
            />
          ) : (
            <ProfileItem
              title={"質問者へのメッセージ\n(140文字以下で設定してください。)"}
              isMultiline={true}
              text={userMessageText!}
              onChange={setUserMessageText}
              height={40}
              isValid={false}
            />
          )}
          <View style={tw("flex-1")}>
            {isUserMessageValid() &&
            isUserNameValid() &&
            isUserProfileValid() ? (
              <SubmitButton label={"情報を更新"} onPress={onPressUpdate} />
            ) : (
              <SubmitButtonInactive label={"情報修正必要"} onPress={() => {}} />
            )}
          </View>
          <View style={tw("h-80")} />
        </View>
        {loading && <Loading />}
      </ScrollView>
    </SafeAreaView>
  );
};
