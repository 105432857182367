import React, { useEffect, useRef } from "react";
import {
  View,
  Text,
  TouchableOpacity,
  KeyboardAvoidingView,
  Platform,
} from "react-native";
import { Feather } from "@expo/vector-icons";
import { tailwind as tw } from "../../tailwind";
import { TextInput } from "react-native-gesture-handler";

type Props = {
  isActive: boolean;
  onPressSearchButton: () => void;
  text: string;
  onChangeText: (text: string) => void;
  placeholder?: string;
};

export const SearchButton = ({
  isActive,
  onPressSearchButton,
  text,
  onChangeText,
  placeholder,
}: Props) => {
  return isActive ? (
    <>
      <KeyboardAvoidingView
        behavior={Platform.OS === "ios" ? "padding" : "height"}
        keyboardVerticalOffset={Platform.OS === "ios" ? 100 : 0}
        style={tw("mr-4 w-full absolute bottom-4")}
      >
        <TextInput
          style={tw(
            "px-4 ml-4 mr-16 h-10 rounded-full bg-white border-2 border-bluegray-800"
          )}
          placeholder={
            placeholder === undefined
              ? "検索語句を入力(スペース区切りでAND検索)"
              : placeholder
          }
          autoFocus={true}
          value={text}
          onChangeText={onChangeText}
          autoCapitalize="none"
        />
      </KeyboardAvoidingView>
      <KeyboardAvoidingView
        behavior={Platform.OS === "ios" ? "padding" : "height"}
        keyboardVerticalOffset={Platform.OS === "ios" ? 100 : 0}
        style={tw("absolute bottom-4 right-4")}
      >
        <TouchableOpacity
          style={tw(
            "rounded-full bg-bluegray-800  border-2 border-bluegray-800"
          )}
          onPress={onPressSearchButton}
        >
          <Feather name="x" color={"white"} size={20} style={tw("m-2 ")} />
        </TouchableOpacity>
      </KeyboardAvoidingView>
    </>
  ) : (
    <TouchableOpacity
      style={tw(
        "rounded-full bg-white absolute bottom-4 right-4 border-2 border-bluegray-800"
      )}
      onPress={onPressSearchButton}
    >
      <Feather name="search" color={"#1E293B"} size={20} style={tw("m-2")} />
    </TouchableOpacity>
  );
};
