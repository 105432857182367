import React from "react";
import { View, Text } from "react-native";
import { tailwind as tw } from "../../tailwind";

type Props = {
  text: string;
};

export const NoSearchResultCard = ({ text }: Props) => (
  <View style={tw("px-4 mx-4 my-2 items-center")}>
    {text.length < 2 ? (
      <Text style={tw("text-sm text-gray-500 leading-7")}>
        2文字以上を入力欄に入力して下さい。(記号を除く)
      </Text>
    ) : (
      <Text style={tw("text-sm text-gray-500 leading-7")}>
        該当する検索結果はありません。
      </Text>
    )}
  </View>
);
