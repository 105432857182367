import React from "react";
import { TouchableOpacity, Text, View } from "react-native";
import { tailwind as tw } from "../../tailwind";
import { MaterialCommunityIcons } from "@expo/vector-icons";

type Props = {
  isLiked: boolean;
  onPress: () => void;
};

export const LikeButton = ({ isLiked, onPress }: Props) => {
  return (
    <TouchableOpacity
      style={tw(
        `flex-1 h-12 m-2 rounded-full items-center px-4 border-2 ${
          isLiked ? "bg-white border-red-700" : "bg-red-700 border-transparent"
        }`
      )}
      onPress={onPress}
    >
      <View style={tw("flex-row justify-center items-center")}>
        <MaterialCommunityIcons
          name={isLiked ? "heart-outline" : "heart"}
          size={24}
          color={isLiked ? "#b91c1c" : "white"}
        />
        <Text
          style={tw(
            `text-sm py-2 ml-2 font-bold text-center  ${
              isLiked ? "text-red-700" : "text-white"
            }`
          )}
        >
          {isLiked ? "「いいね!」を解除する" : "質問を「いいね!」する"}
        </Text>
      </View>
    </TouchableOpacity>
  );
};
