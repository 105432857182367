import React from "react";
import {
  ScrollView,
  SafeAreaView,
  useWindowDimensions,
  View,
  TouchableOpacity,
  Text,
} from "react-native";
import { tailwind as tw } from "../../tailwind";
import { GetProperWidth } from "../lib";
import { StackNavigationProp } from "@react-navigation/stack";
import { RootStackParamList } from "../../navigation/types";
import { ExplainTips } from "../../components/ExplainTips";
import { UseSetHeaderLeftGoBack } from "../../hooks/useSetHeaderLeftGoBack";

type Props = {
  navigation: StackNavigationProp<RootStackParamList, "tips_explain">;
};

export const TipsExplainScreen = ({ navigation }: Props) => {
  const window = useWindowDimensions();
  const properWidth = GetProperWidth(window);
  const widthString =
    properWidth.num === 1 && properWidth.denom === 1
      ? "w-full"
      : `w-${properWidth.num}/${properWidth.denom}`;
  UseSetHeaderLeftGoBack(navigation, () => {
    navigation.navigate("home", { screen: "account_setting", params: {} });
  });
  return (
    <SafeAreaView style={tw("w-full items-center bg-gray-300 flex-1")}>
      <ScrollView style={tw(`${widthString} bg-white p-4`)}>
        <View style={tw(`items-center flex flex-grow w-full`)}>
          <ExplainTips />
          <TouchableOpacity
            style={tw(
              `border border-bluegray-800 bg-white border-bluegray-800 w-full rounded-full mt-2 p-2 flex`
            )}
            onPress={navigation.goBack}
          >
            <Text
              style={tw(`text-bluegray-800 text-base font-bold text-center`)}
            >
              戻る
            </Text>
          </TouchableOpacity>
          <View style={tw(`h-80`)} />
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};
