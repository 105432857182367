import * as Linking from "expo-linking";
import { LinkingOptions } from "@react-navigation/native";
import * as Notifications from "expo-notifications";
import * as Analytics from "expo-firebase-analytics";
import * as RootNavigation from "./RootNavigation";
import * as db_question from "../firebase/Questions";
import { AlertAndReportError } from "../screens/lib";

export const LinkingConfiguration: LinkingOptions<any> = {
  prefixes: [Linking.makeUrl("/")],
  enabled: true,
  subscribe(listener: (url: string) => void) {
    const onReceiveURL = ({ url }: { url: string }) => {
      listener(url);
    };
    Linking.addEventListener("url", onReceiveURL);
    const notificationClickedListener =
      Notifications.addNotificationResponseReceivedListener(
        async (response) => {
          Analytics.logEvent("openedFromPush");
          const { url } = response.notification.request.content.data;
          const questionId = url as string;
          await db_question
            .GetQuestion(questionId, "questions_unanswered")
            .then((question) => {
              if (question === undefined) {
                return;
              }
              RootNavigation.navigate(
                "unanswered_questions" as never,
                { questionToAdd: question.id, fromPush: true } as never
              );
            })
            .catch((e) =>
              AlertAndReportError(e, "GetQuestion on Linking COnfiguration")
            );
        }
      );
    return () => {
      // Linking.removeEventListener("url", onReceiveURL);
      notificationClickedListener.remove();
    };
  },
  config: {
    screens: {
      home: {
        screens: {
          view: {
            screens: {
              top: "top",
              user: "user/:user",
              answer: "answer/:question",
              terms: "terms",
              privacy_policy: "privacy_policy",
            },
          },
          unanswered_questions: "unanswered_questions",
          answered_questions: "answered_questions",
          account_setting: "account_setting",
          auth: "auth",
        },
      },
      multi_choice: "multi_choice",
      answer_question: "answer_question/:question_id",
      answer_limited_only: "answer_limited_only",
      answer_question_reply: "answer_question_reply/:qa_id",
      profile: "profile_setting",
      detail_setting: "detail_setting",
      payout: "payout",
      tips_email_registration: "tips_email_registration",
      tips_stripe_registration: "tips_stripe_registration",
      tips_explain: "tips_explain",
      tips_settings: "tips_settings",
      limited_email_registration: "limited_email_registration",
      limited_stripe_registration: "limited_stripe_registration",
      limited_explain: "limited_explain",
      limited_settings: "limited_settings",
      limited_terminate: "limited_terminate",
      blocked: "blocked",
      trash: "trash",
    },
  },
};
