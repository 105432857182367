import React, { useContext, useState } from "react";
import {
  SafeAreaView,
  useWindowDimensions,
  View,
  ScrollView,
  Platform,
} from "react-native";
import { StackNavigationProp } from "@react-navigation/stack";
import { GetProperWidth } from "../lib";
import { Loading } from "../../components/Loading";
import { RootStackParamList } from "../../navigation/types";
import { useFocusEffect } from "@react-navigation/native"; // works
import { tailwind as tw } from "../../tailwind";
import { UserContext } from "../../contexts/userContext";
import { GenerateAccoutCreateLink } from "../../firebase/Functions";
import { SubmitButtonTwoLines } from "../../components/Buttons/SubmitButtonTwoLines";
import * as Linking from "expo-linking";
import { GetEmailAddressVerified } from "../../firebase/EmailAddresses";
import { GetTipsSetting } from "../../firebase/TipsSettings";
import { AgreeToTermsCheckbox } from "../../components/Checkboxes/AgreeToTermsCheckbox";
import { MailAddressConfirmedText } from "../../components/Texts/MailAddressConfirmedText";

type Props = {
  navigation: StackNavigationProp<
    RootStackParamList,
    "tips_stripe_registration"
  >;
};

export const TipsStripeRegistration = ({ navigation }: Props) => {
  useFocusEffect(
    React.useCallback(() => {
      let isMounted = true;
      if (user === undefined) {
        return;
      }
      const userId = user.id;
      if (userId === undefined) {
        return;
      }
      const initialize = async () => {
        const thisEmailAddressVerified = await GetEmailAddressVerified(userId);
        if (thisEmailAddressVerified === undefined) {
          navigation.navigate("home", {
            screen: "account_setting",
            params: {},
          });
        }
        const thisTipsSetting = await GetTipsSetting(userId);
        if (thisTipsSetting !== undefined) {
          navigation.navigate("tips_settings");
        }
      };
      isMounted && initialize();
    }, [])
  );

  const window = useWindowDimensions();
  const properWidth = GetProperWidth(window);
  const widthString =
    properWidth.num === 1 && properWidth.denom === 1
      ? "w-full"
      : `w-${properWidth.num}/${properWidth.denom}`;
  const [loading, setLoading] = useState(false);
  const [isChecked, setChecked] = useState(false);
  const { user } = useContext(UserContext);

  return (
    <SafeAreaView style={tw("w-full items-center bg-gray-300 flex-1")}>
      <ScrollView style={tw(`${widthString} bg-white p-4`)}>
        <View style={tw(`items-center flex flex-grow w-full`)}>
          <View style={tw("w-full mb-6")}>
            <MailAddressConfirmedText />
            <AgreeToTermsCheckbox
              onCheckPressed={setChecked}
              isChecked={isChecked}
            />
            <View style={tw("h-16 w-full")}>
              <SubmitButtonTwoLines
                labelAbove="手順2/3:受け取り口座の設定に進む"
                labelBelow="(提携先サイト)"
                onPress={async () => {
                  if (user === undefined) {
                    return;
                  }
                  const userId = user.id;
                  if (userId === undefined) {
                    return;
                  }
                  if (isChecked === false) {
                    alert(
                      "利用規約への同意が必要です。 \n ログインボタン下の「利用規約」をタップして、規約の内容を確認の上、チェックボックスにチェックを入れた上でログインボタンを押してください。"
                    );
                    return;
                  } else {
                    setLoading(true);
                    const thisTipsSetting = await GetTipsSetting(userId);
                    if (thisTipsSetting !== undefined) {
                      navigation.navigate("tips_settings");
                      setLoading(false);
                      return;
                    }
                    const data = await GenerateAccoutCreateLink();
                    await Linking.openURL(data.response).catch((e) => {
                      alert(`失敗しました。${e}`);
                    });
                    if (Platform.OS !== "web") {
                      navigation.navigate("home", {
                        screen: "account_setting",
                        params: {},
                      });
                    }
                    setLoading(false);
                  }
                }}
              />
            </View>
          </View>
        </View>
        <View style={tw("h-16 w-full")}></View>
      </ScrollView>
      {loading && <Loading />}
    </SafeAreaView>
  );
};
