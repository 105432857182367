import React, { useEffect, useState, useContext, useCallback } from "react";
import { View, Text, Image, TouchableOpacity, Platform } from "react-native";
import * as Linking from "expo-linking";
import * as AuthSession from "expo-auth-session";
import * as Analytics from "expo-firebase-analytics";
import { CheckBox } from "react-native-elements";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import Constants from "expo-constants";
import { ShowToast } from "../lib";
import { AlertAndReportError, ToQueryString } from "./lib";
import { tailwind as tw } from "../tailwind";
import * as Notifications from "expo-notifications";
import * as firebase_storage from "../firebase/FirebaseStorage";
import { Loading } from "../components/Loading";
import { UserContext } from "../contexts/userContext";
import { User, userDefault } from "../types/user";
import { getAuth, signInWithCustomToken } from "firebase/auth";
import * as db_user from "../firebase/Users";
import * as storage from "../local_storage/user";
import { RouteProp, useFocusEffect } from "@react-navigation/native";
import { IsBanned } from "../firebase/Banned";
import {
  TwitterAccessTokenResponse,
  TwitterRequestTokenResponse,
} from "../types/twitterAuthReqRes";
import {
  GetTwitterOAuthToken,
  SetTwitterOAuthToken,
} from "../local_storage/twitterOAuthTokens";
import { RootStackParamList } from "../navigation/types";
type Props = {
  route: RouteProp<RootStackParamList, "auth">;
};
export const AuthScreen = ({ route }: Props) => {
  const [loading, setLoading] = useState(
    Platform.OS === "web" && route.params !== undefined
  );
  const [loadingLogin, setLoadingLogin] = useState(false);
  const { user, setUser } = useContext(UserContext);
  const [me, setMe] = useState<any>({});
  const [token, setToken] = useState<string>();
  const [isChecked, setChecked] = useState(false);

  const loginTwitter = async (
    oauth_token: string,
    oauth_token_secret: string,
    oauth_verifier: string,
    twitter_app_token_index: number
  ) => {
    const accessParams = ToQueryString({
      oauth_token,
      oauth_token_secret,
      oauth_verifier,
      twitter_app_token_index,
    });
    const accessTokensResponse = await fetch(accessTokenURL + accessParams);
    if (accessTokensResponse.status === 401) {
      const errorMessage = await accessTokensResponse.json();
      throw new Error(errorMessage);
    }
    const accessTokens: TwitterAccessTokenResponse =
      await accessTokensResponse.json();
    const twitterUserId = accessTokens.twitter_user_id;
    const requestParamsUserInfo = ToQueryString({
      twitter_user_id: twitterUserId,
      access_token_key: accessTokens.oauth_token,
      access_token_secret: accessTokens.oauth_token_secret,
      twitter_app_token_index: twitter_app_token_index,
    });
    const responseUserInfo = await fetch(userInfoURL + requestParamsUserInfo);
    const userInfo = await responseUserInfo.json();
    return { accessTokens, userInfo };
  };
  useEffect(() => {
    const params = route.params;
    if (params === undefined || Platform.OS !== "web") {
      setLoadingLogin(false);
      return;
    }
    GetTwitterOAuthToken()
      .then((oauthStored) => {
        if (oauthStored === undefined) {
          setLoadingLogin(false);
          return;
        }
        if (oauthStored.oauth_token !== params.oauth_token) {
          setLoadingLogin(false);
          return;
        }
        loginTwitter(
          oauthStored.oauth_token,
          oauthStored.oauth_token_secret,
          params.oauth_verifier,
          oauthStored.twitter_app_token_index
        )
          .then(({ accessTokens, userInfo }) => {
            setMe(userInfo);
            setToken(accessTokens.firebase_auth_token);
          })
          .catch((e) => {
            throw new Error(e);
          });
      })
      .finally(() => setLoadingLogin(false));
  }, [route.params]);
  useFocusEffect(
    React.useCallback(() => {
      if (user !== undefined) {
        Linking.openURL(Linking.createURL("/unanswered_questions"));
        return;
      }
    }, [user])
  );

  useEffect(() => {
    let isMounted = true;
    if (isMounted && token && me) {
      const fetchUser = async () => {
        const isBanned = await IsBanned(me.id_str);
        if (isBanned) {
          ShowToast("error", "このアカウントはQuerie.meを使用できません。");
          return;
        }
        let userToSignIn: User = {
          name: "",
          twitterId: me.id_str,
          profile: "",
          message: "",
          iconImageUrl: "",
          tipsAccepted: [0],
          tipsPolicy: "",
          limitedPolicy: "",
        };
        setLoading(true);
        const credentials = await signInWithCustomToken(getAuth(), token);
        const id = credentials.user.uid;
        Platform.OS !== "web" && Analytics.setClientId(id);
        const userIsActive = await db_user.IsUserActive(id).catch((e) => {
          AlertAndReportError(e, "IsUserActive on AuthScreen");
          return null;
        });

        if (userIsActive) {
          const userActive = await db_user.GetUserActive(id!).catch((e) => {
            AlertAndReportError(e, "GetUserActive on AuthScreen");
            return userDefault;
          });
          if (userActive === undefined) {
            return;
          }
          userToSignIn = userActive;
        } else {
          const storagePath = `icons/${id}.png`;
          const largeImageUrl = me.profile_image_url_https.replace(
            "_normal",
            ""
          );
          const iconImageUrl = await firebase_storage
            .UploadImage(largeImageUrl, storagePath)
            .catch((e) => {
              AlertAndReportError(e, "UploadImage on AuthScreen");
              return "";
            });
          const genValidScreenName = async (
            screen_name: string
          ): Promise<string> => {
            const existsAlready = await db_user.ExistsScreenName(screen_name);
            return existsAlready
              ? genValidScreenName(screen_name + "1")
              : screen_name;
          };

          userToSignIn.screenName = await genValidScreenName(me.screen_name);
          userToSignIn.name = me.name;
          userToSignIn.profile = me.description;
          userToSignIn.iconImageUrl = iconImageUrl;
          userToSignIn.searchAllowed = true;
          await db_user.AddUserActive(id!, userToSignIn);
        }
        setUser({ id: id!, ...userToSignIn });
        Analytics.setUserId(id);
        await storage
          .SetUser({
            id: id!,
            ...userToSignIn,
          })
          .catch((e) => {
            AlertAndReportError(e, "SetUser on AuthScreen");
          });
        Analytics.logEvent(userIsActive ? "login" : "signup");
        setLoading(false);

        const text = userIsActive
          ? "🎉ログインしました🎉"
          : "🎉質問箱を開設しました🎉";
        Platform.OS !== "web" && ShowToast("success", text);
        Platform.OS === "web" &&
          Linking.openURL(Linking.createURL("/unanswered_questions"));
      };
      fetchUser();
    }
    return () => {
      isMounted = false;
    };
  }, [token]);

  const functionsUrl = Constants.manifest?.extra!.functions.url;
  const requestTokenURL = `${functionsUrl}widgetsV2/request-token`;
  const accessTokenURL = `${functionsUrl}widgetsV2/access-token`;
  const userInfoURL = `${functionsUrl}widgetsV2/user-info`;
  const pickTokenIndexURL = `${functionsUrl}widgetsV2/pick-token-index`;
  // const redirect = "https://querie-app.firebaseapp.com/__/auth/handler";
  const redirect = "https://auth.expo.io/@kumagai.takumi/querie-app";

  const onLoginPress = useCallback(async () => {
    setLoading(true);
    const token_index = await fetch(pickTokenIndexURL)
      .then((res) => res.json())
      .then((json) => json.index);
    try {
      if (Platform.OS === "web") {
        const requestParams = ToQueryString({
          callback_url: Linking.createURL("auth"),
          token_index,
        });
        const response = await fetch(requestTokenURL + requestParams);
        const requestTokens: TwitterRequestTokenResponse =
          await response.json();
        await SetTwitterOAuthToken(requestTokens);
        await Linking.openURL(
          "https://api.twitter.com/oauth/authorize" +
            ToQueryString(requestTokens)
        );
      } else {
        const requestParams = ToQueryString({
          callback_url: redirect,
          token_index,
        });
        const response = await fetch(requestTokenURL + requestParams);
        const requestTokens: TwitterRequestTokenResponse =
          await response.json();
        const twitterAppTokenIndex = requestTokens.twitter_app_token_index;

        const authResponse = await AuthSession.startAsync({
          authUrl:
            "https://api.twitter.com/oauth/authorize" +
            ToQueryString(requestTokens),
        });

        if (authResponse.type !== "success") {
          throw new Error("did not succeed");
        }
        const { accessTokens, userInfo } = await loginTwitter(
          requestTokens.oauth_token,
          requestTokens.oauth_token_secret,
          authResponse.params.oauth_verifier,
          twitterAppTokenIndex
        );
        setMe(userInfo);
        setToken(accessTokens.firebase_auth_token);
      }
    } catch (error: any) {
      alert(
        Platform.OS === "web"
          ? `ログインに失敗しました。\nもう一度お試し下さい。\n\n解決しない場合は、このブラウザの別タブでTwitterにログインしてから再度お試しください。\n\nそれでも解決しない場合はお手数ですが下記エラーを添えてQuerie.me運営ツイッターアカウント(@querie_me_app)のDMまでお問い合わせください${error}`
          : `ログインに失敗しました。\nもう一度お試し下さい。\n\n解決しない場合は、アプリの再インストールをお試しください。\n\nそれでも解決しない場合は下記エラーを添えてQuerie.me運営ツイッターアカウント(@querie_me_app)のDMまでお問い合わせください${error}`
      );
      setUser(undefined);
      setMe(undefined);
      setToken(undefined);
    } finally {
      setLoading(false);
    }
  }, []);
  const requestCheck = () => {
    alert(
      "利用規約への同意が必要です。 \n ログインボタン下の「利用規約」をタップして、規約の内容を確認の上、チェックボックスにチェックを入れた上でログインボタンを押してください。"
    );
  };
  return (
    <View
      style={tw("h-full bg-bluegray-800 flex-1 items-center justify-center")}
    >
      <View style={tw("h-48 w-48")}>
        <Image
          source={require("../assets/icon.png")}
          style={tw("h-full w-full")}
        />
      </View>
      <View style={tw("h-12 w-72 mb-6")}>
        <Image
          source={require("../assets/logo.png")}
          style={tw("h-full w-full")}
        />
      </View>
      {Platform.OS === "web" && (
        <>
          <View style={tw("bg-white border-2 border-white w-80 mb-4")}>
            <Text
              style={tw(
                "font-bold text-xl bg-bluegray-800 text-white text-center w-full p-2"
              )}
            >
              モバイルアプリはこちらから
            </Text>
          </View>
          <View style={tw("flex-row mb-6")}>
            <TouchableOpacity
              style={tw("h-12 w-40")}
              onPress={() =>
                Linking.openURL(Constants.manifest?.extra!.storeUrl.ios)
              }
            >
              <Image
                source={require("../assets/app-store-badge.png")}
                style={tw("h-full w-full")}
              />
            </TouchableOpacity>
            <TouchableOpacity
              style={tw("h-12 w-40")}
              onPress={() =>
                Linking.openURL(Constants.manifest?.extra!.storeUrl.android)
              }
            >
              <Image
                source={require("../assets/google-play-badge.png")}
                style={tw("h-full w-full")}
              />
            </TouchableOpacity>
          </View>
          <View style={tw("bg-white border-2 border-white w-80 mb-2")}>
            <Text
              style={tw(
                "font-bold text-xl bg-bluegray-800 text-white text-center w-full p-2"
              )}
            >
              PC版はこちらから
            </Text>
          </View>
        </>
      )}
      <TouchableOpacity
        onPress={() => {
          isChecked ? onLoginPress() : requestCheck();
        }}
        style={tw("rounded-full bg-white w-80 p-2 m-2 items-center")}
      >
        <View style={tw("flex-row justify-center")}>
          <MaterialCommunityIcons name="twitter" size={36} color="#1E293B" />
          <Text
            style={tw(
              "text-bluegray-800 justify-center items-center text-xl ml-2 leading-10"
            )}
          >
            Twitterログインして始める
          </Text>
        </View>
      </TouchableOpacity>
      <View style={tw("flex-row items-center")}>
        <CheckBox
          checkedColor="white"
          uncheckedColor="white"
          iconType="ionicon"
          checkedIcon="checkmark-circle-outline"
          uncheckedIcon="ellipse-outline"
          size={24}
          checked={isChecked}
          onPress={() => {
            isChecked ? setChecked(false) : setChecked(true);
          }}
        />
        <Text
          onPress={() =>
            Linking.openURL(
              `https://${Constants.manifest?.extra!.domain}/terms`
            )
          }
          style={tw(
            "text-white justify-center items-center text-lg leading-10 -ml-4 underline font-bold"
          )}
        >
          利用規約
        </Text>
        <Text
          style={tw(
            "text-white justify-center items-center text-lg leading-10"
          )}
        >
          に同意する。
        </Text>
      </View>
      {/* <TWModal /> */}
      {loading && <Loading color={"bluegray-800"} />}
      {loadingLogin && <Loading color={"bluegray-800"} />}
    </View>
  );
};
//
