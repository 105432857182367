import React from "react";
import { Text, View } from "react-native";
import { tailwind as tw } from "../../tailwind";
import { Ionicons } from "@expo/vector-icons";
import { CheckBox } from "react-native-elements";

type props = {
  isChecked: boolean;
  onCheckPressed: () => void;
  onPressDetail: () => void;
};

export const AnswerAsPrivateCheckBox = ({
  isChecked,
  onCheckPressed,
  onPressDetail,
}: props) => {
  return (
    <View style={tw("flex flex-row items-center justify-start")}>
      <CheckBox
        checkedColor="gray"
        uncheckedColor="gray"
        iconType="ionicon"
        checkedIcon="checkmark-circle-outline"
        uncheckedIcon="ellipse-outline"
        size={24}
        checked={isChecked}
        onPress={onCheckPressed}
        containerStyle={tw("-my-1 -mr-1")}
      />
      <Text style={tw("mr-2 text-sm text-center text-gray-500")}>
        非公開で回答する
      </Text>
      <Ionicons
        name="help-circle-outline"
        size={24}
        color="gray"
        onPress={onPressDetail}
      />
    </View>
  );
};
