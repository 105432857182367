import React from "react";
import { View, Text, TextInput } from "react-native";
import { tailwind as tw } from "../tailwind";

type Props = {
  title: string;
  isMultiline: boolean;
  text: string;
  onChange: (text: string) => void;
  height: number;
  isValid: boolean;
};

export const ProfileItem = ({
  title,
  isMultiline,
  text,
  onChange,
  height,
  isValid,
}: Props) => {
  const textColor = isValid ? "text-gray-800" : "text-red-800";
  return (
    <View>
      <Text style={tw(`text-lg ${textColor} mb-2`)}>{title}</Text>
      <TextInput
        style={tw(
          `rounded-lg text-sm border text-gray-800 h-${height} mb-2 p-2`
        )}
        onChangeText={(text) => onChange(text)}
        value={text}
        placeholder={`${title}を入力`}
        multiline={isMultiline}
      />
    </View>
  );
};
