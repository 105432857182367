import AsyncStorage from "@react-native-async-storage/async-storage";

const KEY_NAME = "@will_show_api_post_rate_limit_dialog";

export const SetWillShowApiPostRateLimitHitDialog = async (
  willShowApiPostRateLimitHitDialog: boolean
) => {
  await AsyncStorage.setItem(
    KEY_NAME,
    String(willShowApiPostRateLimitHitDialog)
  );
};

export const GetWillShowApiPostRateLimitHitDialog = async () => {
  const willShowApiPostRateLimitHitDialog = await AsyncStorage.getItem(
    KEY_NAME
  );
  return willShowApiPostRateLimitHitDialog === null
    ? true
    : willShowApiPostRateLimitHitDialog === "true";
};
