import { db } from "./Init";
import { ForceUpdateConfig, MaintenanceConfig } from "../types/remoteConfig";
import { doc, getDoc } from "firebase/firestore";

export const GetMaintenanceConfig = async () => {
  const snapShot = await getDoc(doc(db, "remote_config", "maintenance"));
  const maintenanceConfig = snapShot.data() as MaintenanceConfig | undefined;
  return maintenanceConfig;
};

export const GetForceUpdateConfig = async (os: "android" | "ios") => {
  const snapShot = await getDoc(doc(db, "remote_config", "force_update"));
  const maintenanceConfig = snapShot.data() as ForceUpdateConfig | undefined;
  if (maintenanceConfig === undefined) {
    return undefined;
  } else {
    return maintenanceConfig[os];
  }
};
