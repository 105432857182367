import { useEffect, useState, useCallback } from "react";
import { Animated } from "react-native";
import { QA, Question } from "../types/question";
import { RouteProp, useFocusEffect } from "@react-navigation/native"; // works
import { GetUserActive } from "../firebase/Users";
import { User } from "../types/user";
import { GetMultiAccountAuth } from "../firebase/MultiAccountAuthes";
import { CheckShouldShowLimitedInvite } from "../firebase/Functions";
import { GetQuestion, IsOneLimitedAnswerAnswered } from "../firebase/Questions";
import { RootStackParamList } from "../navigation/types";

export const UseButtonsOnQuestionsScreen = (
  route: RouteProp<RootStackParamList, "unanswered_questions">,
  unansweredQuestions: Question[],
  existsUpdate: boolean,
  opacity: Animated.Value,
  user?: User
) => {
  const [showTwitterShare, setShowTwitterShare] = useState(false);
  const [showTipsSignupGuide, setShowTipsSignupGuide] = useState(false);
  const [
    isFirstLimitedOnlyAnswerAlertShown,
    setIsFirstLimitedOnlyAnswerAlertShown,
  ] = useState(route.params.showAlertType === "firstLimitedOnlyAnswer");
  const [isAutoShareAlertShown, setIsAutoShareAlertShown] = useState(false);
  const [isInviteToLimitedAlertShown, setIsInviteToLimitedAlertShown] =
    useState(false);

  const buttonTranslationX = opacity.interpolate({
    inputRange: [0, 1],
    outputRange: [-10, 20],
  });

  useEffect(() => {
    let isMounted = true;
    isMounted &&
      Animated.timing(opacity, {
        toValue: existsUpdate ? 1 : 0.01,
        duration: 400,
        useNativeDriver: true,
      }).start();
    return () => {
      isMounted = false;
    };
  }, [existsUpdate]);

  useEffect(() => {
    let isMounted = true;
    isMounted && setShowTwitterShare(unansweredQuestions.length === 0);
    return () => {
      isMounted = false;
    };
  }, [unansweredQuestions]);

  useFocusEffect(
    useCallback(() => {
      if (user === undefined) {
        return;
      }
      GetUserActive(user.id).then((user) => {
        user !== undefined &&
          setShowTipsSignupGuide(
            user.tipsAccepted.length === 1 && user.tipsAccepted.includes(0)
          );
      });
    }, [])
  );

  useEffect(() => {
    const askAutoQuestionRequestedEnabled = async () => {
      const userFromDb = await GetUserActive(user!.id!);
      if (
        userFromDb !== undefined &&
        userFromDb.autoQuestionRequestEnabled === undefined &&
        route.params.questionToRemove !== undefined
      ) {
        setIsAutoShareAlertShown(true);
      }
    };
    askAutoQuestionRequestedEnabled();
  }, [route.params.questionToRemove]);

  useEffect(() => {
    const checkForLimitedInvite = async () => {
      const userId = user?.id;
      if (userId === undefined) {
        return;
      }
      const userFromDb = await GetUserActive(userId);
      if (userFromDb === undefined) {
        return;
      }
      await GetMultiAccountAuth(userFromDb.twitterId)
        .then((auth) =>
          auth === undefined ? false : CheckShouldShowLimitedInvite(auth)
        )
        .then((shouldShow) => {
          if (shouldShow) {
            setIsInviteToLimitedAlertShown(true);
          }
        });
    };
    checkForLimitedInvite();
  }, [route.params.questionToRemove]);

  useEffect(() => {
    const checkIsFirstLimitedAnswerAnswered = async () => {
      if (
        route.params.questionToRemove === undefined ||
        user?.id === undefined
      ) {
        return;
      }
      const qa = (await GetQuestion(
        route.params.questionToRemove,
        "questions_answered"
      )) as QA | undefined;
      if (qa === undefined || qa.isLimitedOnly !== true) {
        return;
      }
      const isOneLimitedAnswerAnswered = await IsOneLimitedAnswerAnswered(
        user.id
      );
      if (isOneLimitedAnswerAnswered) {
        setIsFirstLimitedOnlyAnswerAlertShown(true);
      }
    };
    checkIsFirstLimitedAnswerAnswered();
  }, [route.params.questionToRemove]);

  return {
    isFirstLimitedOnlyAnswerAlertShown,
    isAutoShareAlertShown,
    isInviteToLimitedAlertShown,
    setIsFirstLimitedOnlyAnswerAlertShown,
    setIsAutoShareAlertShown,
    setIsInviteToLimitedAlertShown,
    buttonTranslationX,
    showTwitterShare,
    showTipsSignupGuide,
  };
};
