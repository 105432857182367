import React from "react";
import { TextInput, View } from "react-native";
import { tailwind as tw } from "../tailwind";

type Props = {
  onChangeText: (text: string) => void;
  text: string;
  placeholder: string;
};

export const QATextInput = ({ onChangeText, text, placeholder }: Props) => {
  return (
    <View
      style={tw("flex-1 p-4 m-2 bg-white rounded-lg border-2 border-gray-300")}
    >
      <TextInput
        style={{ ...tw("text-sm text-gray-800"), minHeight: 120 }}
        onChangeText={(text) => onChangeText(text)}
        value={text}
        placeholder={placeholder}
        multiline={true}
      />
    </View>
  );
};
