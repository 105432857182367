import React from "react";
import { Text, TouchableOpacity } from "react-native";
import { tailwind as tw } from "../tailwind";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { SettingListItem } from "../types/settingListItem";

export const AccountListItem = ({
  iconName,
  title,
  onPress,
}: SettingListItem) => {
  return (
    <TouchableOpacity
      style={tw(
        "rounded-3xl  h-12  bg-white px-6 mx-4 my-2 flex-row items-center"
      )}
      onPress={() => onPress()}
    >
      <MaterialCommunityIcons name={iconName} size={30} color={"#1E293B"} />
      <Text style={tw("mx-4 flex-row items-center text-xl")}>{title}</Text>
    </TouchableOpacity>
  );
};
