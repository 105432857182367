import React from "react";
import { Text, View, TouchableOpacity } from "react-native";
import { tailwind as tw } from "../../tailwind";
import { QA } from "../../types/question";
import { QACardHeaderAnswerer } from "./QACardHeaderAnswerer";
import { QACardHeaderQuestioner } from "./QACardHeaderQuestioner";
import { QACardContent } from "./QACardContent";
import { Ionicons } from "@expo/vector-icons";
import { SubmitButton } from "../Buttons/SubmitButton";

type Props = {
  answerUserName: string;
  answerUserIconUrl: string;
  qa: QA;
  label: string;
  isPressable: boolean;
  isExpanded: boolean;
  onPress?: () => void;
  onDismiss?: () => void;
  onPressDetail?: () => void;
};

export const QACardReplyTo = ({
  answerUserName,
  answerUserIconUrl,
  qa,
  label,
  isPressable,
  isExpanded,
  onPress,
  onDismiss,
  onPressDetail,
}: Props) => {
  const ContentTexts = () => (
    <>
      <QACardHeaderQuestioner
        tips={
          qa.tipsChosen !== undefined
            ? qa.tipsChosen
            : qa.tips === 0
            ? undefined
            : qa.tips
        }
        isPressable={isPressable}
        isCaretVisible={false}
        onPressCaret={() => {}}
        smaller={true}
        isAskedAsPrivate={qa.isAskedAsPrivate}
      />
      <QACardContent
        text={qa.text}
        isPressable={isPressable}
        isExpanded={isExpanded}
        smaller={true}
      />
      <QACardHeaderAnswerer
        isPressable={isPressable}
        answerUserIconUrl={answerUserIconUrl}
        answerUserName={answerUserName}
        smaller={true}
      />
      <QACardContent
        text={qa.answer}
        isPressable={isPressable}
        textForLimitedOnly={qa.answerTextLimitedOnly}
        isExpanded={isExpanded}
        smaller={true}
        isLimitedOnly={qa.isLimitedOnly}
      />
    </>
  );
  return (
    <>
      {isPressable ? (
        <>
          <Text
            style={tw(
              "pt-4 pb-3 text-sm font-bold text-center text-white bg-bluegray-800 rounded-t-2xl"
            )}
          >
            {label}
          </Text>
          <TouchableOpacity
            style={tw(
              "border-2 border-bluegray-800 px-4 bg-white rounded-b-2xl"
            )}
            onPress={onPress}
          >
            <ContentTexts />
          </TouchableOpacity>
        </>
      ) : (
        <>
          <View
            style={tw(
              "flex-row mt-2 justify-center items-center bg-bluegray-800"
            )}
          >
            <Text
              style={tw("pt-4 pb-3 text-sm font-bold text-center text-white")}
            >
              {label}
            </Text>
            {onPressDetail !== undefined && (
              <Ionicons
                name="help-circle-outline"
                style={tw("ml-1 mt-1")}
                size={24}
                color="white"
                onPress={onPressDetail}
              />
            )}
          </View>

          <View style={tw("border-2 border-bluegray-800 px-4 bg-white")}>
            <ContentTexts />
            {onDismiss !== undefined && (
              <View style={tw("h-12")}>
                <SubmitButton
                  label="続きの質問設定を解除"
                  onPress={onDismiss}
                  smallerText={true}
                />
              </View>
            )}
          </View>
        </>
      )}
    </>
  );
};
