import React, { useContext, useState } from "react";
import {
  FlatList,
  View,
  RefreshControl,
  useWindowDimensions,
  SafeAreaView,
} from "react-native";

import { StackNavigationProp } from "@react-navigation/stack";
import * as Analytics from "expo-firebase-analytics";
import { ShowToast } from "../lib";
import { RootStackParamList } from "../navigation/types";
import { tailwind as tw } from "../tailwind";
import { AlertAndReportError, GetProperWidth } from "./lib";
import { QACard } from "../components/Cards/QACard";
import { Loading } from "../components/Loading";
import { ActionSheetItem, ActionSheetAction } from "../types/actionSheet";
import { UserContext } from "../contexts/userContext";
import {
  connectActionSheet,
  useActionSheet,
} from "@expo/react-native-action-sheet";
import { QA } from "../types/question";
import { ReportQuestion } from "../firebase/Reports";
import { AddQuestionBlockHistory } from "../firebase/Questions";
import { useScrollToTop } from "@react-navigation/native";
import { SearchButton } from "../components/Buttons/SearchButton";
import { SearchResultEndCard } from "../components/Cards/SearchResultEndCard";
import { NoSearchResultCard } from "../components/Cards/NoSearchResultCard";
import { MoreThanLimitHitsCard } from "../components/Cards/MoreThanLimitHitsCard";
import { DialogBoxWithUpToTwoOptions } from "../components/DialogBoxWithUpToTwoOptions";
import * as local_storage_twitter from "../local_storage/tweetDeletionSetting";
import * as Clipboard from "expo-clipboard";
import Constants from "expo-constants";
import { UseQAs } from "../hooks/useQAs";

type Props = {
  navigation: StackNavigationProp<RootStackParamList, "answered_questions">;
};
export const QAScreenNoAS = ({ navigation }: Props) => {
  const window = useWindowDimensions();
  const properWidth = GetProperWidth(window);
  const widthString =
    properWidth.num === 1 && properWidth.denom === 1
      ? "w-full"
      : `w-${properWidth.num}/${properWidth.denom}`;

  const { user } = useContext(UserContext);

  const SEARCH_RESULT_COUNT = 100;

  const [isDeleteTweetDialogShown, setIsDeleteTweetDialogShown] =
    useState(false);
  const [isAlwaysApplyDialogShown, setIsAlwaysApplyDialogShown] =
    useState(false);
  const [modifyOrDelete, setModifyOrDelete] = useState<
    undefined | "Modify" | "Delete"
  >(undefined);
  const [willDelete, setWillDelete] = useState<boolean>();
  const [qaForAction, setQaForAction] = useState<QA>();

  const { showActionSheetWithOptions } = useActionSheet();
  const {
    loading,
    searchTerm,
    isSearchActive,
    refreshing,
    answeredQuestions,
    searchHitQuestions,
    moreThanLimitHits,
    setLoading,
    onChangeSearchTerm,
    onEndReached,
    onRefresh,
    onPressSearchButton,
    deleteQA,
    modifyQA,
  } = UseQAs(user!, SEARCH_RESULT_COUNT);
  const openActionSheet = (actionSheetItem: ActionSheetItem, qa: QA) => {
    const options = actionSheetItem.actions.map((x) => x.label);
    const cancelButtonIndex = actionSheetItem.cancel_index;
    const destructiveButtonIndex = actionSheetItem.destructive_index;

    showActionSheetWithOptions(
      {
        options,
        cancelButtonIndex,
        destructiveButtonIndex,
      },
      (buttonIndex) => {
        buttonIndex !== undefined &&
          actionSheetItem.actions[buttonIndex].onPress(qa);
      }
    );
  };

  const actions: ActionSheetAction[] = [
    {
      label: "未回答に戻して編集する",
      // @ts-ignore
      onPress: async (qa: QA) => {
        setQaForAction(qa);
        setModifyOrDelete("Modify");
        const modifySetting =
          await local_storage_twitter.getTweetDeletionSetting("Modify");
        if (modifySetting?.alwaysApply !== true) {
          setIsDeleteTweetDialogShown(true);
        } else {
          await modifyQA(qa, modifySetting?.willDelete!);
          navigation.navigate("home", {
            screen: "unanswered_questions",
            params: {
              questionToAdd: qa.id!,
            },
          });
          ShowToast(
            "info",
            `回答を未回答に戻しました。 ${
              modifySetting.willDelete ? "共有したツイートも削除しました。" : ""
            }`
          );
          setQaForAction(undefined);
          setModifyOrDelete(undefined);
        }
      },
    },
    {
      label: "回答ページURLをコピーする",
      // @ts-ignore
      onPress: async (qa: QA) => {
        if (qa.isAskedAsPrivate === true || qa.isAnsweredAsPrivate === true) {
          ShowToast("error", "非公開質問のためコピーできません。");
          return;
        }
        const questionId = qa.id;
        questionId !== null &&
          (await Clipboard.getStringAsync()
            .then(() => {
              Clipboard.setString(
                `https://${
                  Constants.manifest?.extra!.domain
                }/answer/${questionId}`
              );
              ShowToast("info", "クリップボードにコピーしました。");
              Analytics.logEvent("copied", { at: "AnswerScreen" });
            })
            .catch(() =>
              ShowToast(
                "error",
                "ご利用の環境ではコピーできません。機能を利用するにはアプリ版をご利用ください。"
              )
            ));
      },
    },
    {
      label: "不適切な質問を運営に報告",
      onPress: async (qa) => {
        setLoading(true);
        await ReportQuestion(qa, "questions_answered").catch((e) => {
          AlertAndReportError(e, "ReportQuestion on QA Screen");
        });
        setLoading(false);
        Analytics.logEvent("reported", { at: "QAScreen" });
        ShowToast("info", "不適切な質問として運営に報告しました。");
      },
    },
    {
      label: "質問者をブロック",
      onPress: async (qa) => {
        if (qa.id === null) {
          return;
        }
        await AddQuestionBlockHistory(qa.id, qa).catch((e) =>
          AlertAndReportError(e, "AddBlock on QA Screen")
        );
        setLoading(false);
        Analytics.logEvent("blocked", { at: "QAScreen" });
        ShowToast("info", "この質問者をブロックしました。");
      },
    },
    {
      label: "質問をゴミ箱へ移動",
      onPress: async (qa) => {
        setQaForAction(qa as QA);
        setModifyOrDelete("Delete");
        const deleteSetting =
          await local_storage_twitter.getTweetDeletionSetting("Delete");
        if (deleteSetting?.alwaysApply !== true) {
          setIsDeleteTweetDialogShown(true);
        } else {
          await deleteQA(qa as QA, deleteSetting?.willDelete!);
          ShowToast(
            "info",
            `質問をゴミ箱に投入しました。\n7日後に自動的に消去されます。 ${
              deleteSetting.willDelete
                ? "\n共有したツイートも削除しました。"
                : ""
            }`
          );
          setQaForAction(undefined);
          setModifyOrDelete(undefined);
        }
      },
    },
    {
      label: "キャンセル",
      onPress: (qa) => {},
    },
  ];

  const actionSheetItem: ActionSheetItem = {
    actions: actions,
    cancel_index: 5,
    destructive_index: 4,
  };

  const ref = React.useRef(null);
  useScrollToTop(ref);

  return (
    <SafeAreaView style={tw("w-full items-center bg-gray-300 flex-1")}>
      <View style={tw(`${widthString} flex flex-grow bg-gray-300 h-full`)}>
        <FlatList
          ListFooterComponent={
            <View style={tw("py-1 mb-16")}>
              {isSearchActive &&
                searchHitQuestions.length === SEARCH_RESULT_COUNT && (
                  <SearchResultEndCard
                    searchResultCount={SEARCH_RESULT_COUNT}
                  />
                )}
              {isSearchActive &&
                searchHitQuestions.length === 0 &&
                moreThanLimitHits === false && (
                  <NoSearchResultCard text={searchTerm} />
                )}
              {isSearchActive && moreThanLimitHits && (
                <MoreThanLimitHitsCard limit={SEARCH_RESULT_COUNT} />
              )}
            </View>
          }
          ListHeaderComponent={<View style={tw("py-1")} />}
          data={isSearchActive ? searchHitQuestions : answeredQuestions}
          renderItem={({ item }) => {
            return (
              <QACard
                qa={item}
                answerUserName={user!.name}
                answerUserIconUrl={user!.iconImageUrl}
                isCaretVisible={false}
                isPressable={true}
                onPressCaret={() => {}}
                onPress={() => openActionSheet(actionSheetItem, item)}
              />
            );
          }}
          keyExtractor={(_, index) => index.toString()}
          onEndReached={onEndReached}
          refreshControl={
            <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
          }
          ref={ref}
          removeClippedSubviews={true}
        />
        {loading && <Loading />}
        <SearchButton
          isActive={isSearchActive}
          onPressSearchButton={onPressSearchButton}
          text={searchTerm}
          onChangeText={onChangeSearchTerm}
        />
      </View>
      <DialogBoxWithUpToTwoOptions
        title={
          modifyOrDelete === "Modify"
            ? "回答を未回答にもどします。"
            : "回答を削除します。"
        }
        message={
          "この回答を共有したツイートも削除してよろしいですか？ \n(すでにご自分で削除している場合は何も起こりません。)"
        }
        widthString={widthString}
        buttons={[
          {
            label: "削除する",
            onPress: async () => {
              modifyOrDelete === "Modify"
                ? await modifyQA(qaForAction!, true)
                : await deleteQA(qaForAction!, true);
              setWillDelete(true);
              setIsDeleteTweetDialogShown(false);
              setIsAlwaysApplyDialogShown(true);
            },
          },
          {
            label: "削除しない",
            onPress: async () => {
              modifyOrDelete === "Modify"
                ? await modifyQA(qaForAction!, false)
                : await deleteQA(qaForAction!, false);
              setWillDelete(false);
              setIsDeleteTweetDialogShown(false);
              setIsAlwaysApplyDialogShown(true);
            },
          },
        ]}
        isShown={isDeleteTweetDialogShown}
      />
      <DialogBoxWithUpToTwoOptions
        title={
          willDelete ? "ツイートが削除されます。" : "ツイートが削除されません。"
        }
        message={
          modifyOrDelete === "Modify"
            ? "今後も未回答に戻した回答のツイートに関して同様のルールを適用してよろしいですか？"
            : "今後も削除した回答のツイートに関して同様のルールを適用してよろしいですか？"
        }
        widthString={widthString}
        buttons={[
          {
            label: "適用する",
            onPress: async () => {
              local_storage_twitter.setTweetDeletionSetting(modifyOrDelete, {
                willDelete: willDelete!,
                alwaysApply: true,
              });
              setIsAlwaysApplyDialogShown(false);
              setWillDelete(undefined);
              modifyOrDelete === "Modify" &&
                navigation.navigate("home", {
                  screen: "unanswered_questions",
                  params: {
                    questionToAdd: qaForAction!.id!,
                  },
                });
              setQaForAction(undefined);
              ShowToast("info", "設定が適用されました。");
            },
          },
          {
            label: "適用しない",
            onPress: async () => {
              local_storage_twitter.setTweetDeletionSetting(modifyOrDelete, {
                willDelete: willDelete!,
                alwaysApply: false,
              });
              setIsAlwaysApplyDialogShown(false);
              setWillDelete(undefined);
              modifyOrDelete === "Modify" &&
                navigation.navigate("home", {
                  screen: "unanswered_questions",
                  params: {
                    questionToAdd: qaForAction!.id!,
                  },
                });
              setQaForAction(undefined);
              ShowToast("info", "次回も確認します。");
            },
          },
        ]}
        isShown={isAlwaysApplyDialogShown}
      />
    </SafeAreaView>
  );
};

export const QAScreen = connectActionSheet(QAScreenNoAS);
