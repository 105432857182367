import React from "react";
import { View, Text, TouchableOpacity } from "react-native";
import { tailwind as tw } from "../../tailwind";
import { convertToSinceAsked } from "../lib";
import { NewIcon } from "../Icons/NewIcon";
import { SinceAskedIcon } from "../Icons/SinceAskedIcon";
import { CheckBox } from "react-native-elements";
import { Feather } from "@expo/vector-icons";
import { Question } from "../../types/question";
import { CheckBoxItem } from "../../types/checkBoxItem";
import Autolink from "react-native-autolink";
import { OnPressHyperLink } from "../lib";
import { ReplyQuestionIcon } from "../Icons/ReplyQuestionIcon";
import { TipsIcon } from "../Icons/TipsIcon";
import { LikedIcon } from "../Icons/LikedIcon";
import { IsPrivateIcon } from "../Icons/IsPrivateIcon";

type Props = {
  question: Question;
  isNew: boolean;
  checkBoxItem: CheckBoxItem;
  isPressable: boolean;
  isCaretVisible: boolean;
  onPress: () => void;
  onPressCaret: () => void;
  isMultiChoiceActive: boolean;
  showReplyToIcon: boolean;
  isLiked?: boolean;
};

export const QuestionCard = ({
  question,
  isNew,
  checkBoxItem,
  isPressable,
  isCaretVisible,
  onPress,
  onPressCaret,
  isMultiChoiceActive,
  showReplyToIcon,
  isLiked,
}: Props) => {
  const HeaderContent = () => (
    <View style={tw("flex-row w-full")}>
      {question.tips !== 0 && question.tipsAlreadyCaptured !== true && (
        <TipsIcon
          tips={
            question.tipsChosen === undefined
              ? question.tips
              : question.tipsChosen
          }
        />
      )}
      {isNew && <NewIcon />}
      <SinceAskedIcon sinceAsked={convertToSinceAsked(question.askedAt)} />
      {isLiked && <LikedIcon />}
      {question.isAskedAsPrivate === true && <IsPrivateIcon />}
      {showReplyToIcon && <ReplyQuestionIcon />}
      {isMultiChoiceActive ? (
        <View style={tw("absolute right-0 items-center -mr-5")}>
          <CheckBox
            checkedColor="gray"
            uncheckedColor="gray"
            size={18}
            iconType="ionicon"
            checkedIcon="checkmark-circle-outline"
            uncheckedIcon="ellipse-outline"
            checked={checkBoxItem.isChecked}
            onPress={() => checkBoxItem.onPressCheck()}
          />
        </View>
      ) : (
        isCaretVisible && (
          <TouchableOpacity
            style={tw("absolute right-0 mt-3 -mr-1 mb-1 p-1")}
            onPress={() => onPressCaret()}
          >
            <Feather name="more-horizontal" size={18} color={"#D1D5DB"} />
          </TouchableOpacity>
        )
      )}
    </View>
  );
  return isPressable ? (
    <TouchableOpacity
      style={tw(
        `rounded-2xl bg-white px-4 mx-4 my-2 border-2 ${
          isLiked === true ? "border-red-700" : "border-transparent"
        }`
      )}
      onPress={onPress}
    >
      <HeaderContent />
      <Text
        style={tw("text-base text-gray-800 leading-7 my-2")}
        selectable={true}
      >
        {question.text}
      </Text>
    </TouchableOpacity>
  ) : (
    <View
      style={tw(
        `bg-white px-4 mx-4 my-2 border-2  ${
          isLiked === true ? "border-red-700" : "border-transparent"
        }`
      )}
    >
      <HeaderContent />
      <Autolink
        text={question.text}
        style={tw("text-base text-gray-800 leading-7 my-2")}
        linkStyle={tw("text-base text-gray-800 leading-7 underline")}
        onPress={(url) => {
          OnPressHyperLink(url);
        }}
      />
    </View>
  );
};
