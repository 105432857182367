import { useFocusEffect } from "@react-navigation/native"; // works
import { useCallback, useState } from "react";
import { User } from "../types/user";
import { TipsSetting } from "../types/tipsSetting";
import { GetTipsSetting } from "../firebase/TipsSettings";
import { CheckAndUpdateIdVerification } from "../firebase/Functions";

export const UseTipsSettingsOnFocus = (
  onTipsNotFound: () => void,
  user?: User
) => {
  const [tipsSetting, setTipsSetting] = useState<TipsSetting>();
  const [loadingTipsSetting, setLoadingTipsSetting] = useState(false);
  useFocusEffect(
    useCallback(() => {
      let isMounted = true;
      if (user === undefined) {
        return;
      }
      const userId = user.id;
      if (userId === undefined) {
        return;
      }
      const initialize = async () => {
        setLoadingTipsSetting(true);
        const thisTipsSetting = await GetTipsSetting(userId);
        if (thisTipsSetting === undefined) {
          onTipsNotFound();
          setLoadingTipsSetting(false);
          return;
        }
        if (thisTipsSetting.idVerified === false) {
          const response = await CheckAndUpdateIdVerification();
          if (response.data.response === "true") {
            setTipsSetting({ ...thisTipsSetting, idVerified: true });
          } else {
            setTipsSetting(thisTipsSetting);
          }
        } else {
          setTipsSetting(thisTipsSetting);
        }
        setLoadingTipsSetting(false);
      };
      isMounted && initialize();
    }, [])
  );
  return { loadingTipsSetting, tipsSetting };
};
