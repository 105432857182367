import AsyncStorage from "@react-native-async-storage/async-storage";

const DEFAULT_COUNT_TO_ASK_FOR_REVIEW = 5;
const BACKOFF_MULTIPLE = 5;

export const IncrementAnswerCountOnDevice = async () => {
  const answerCountOnDevice = await AsyncStorage.getItem(
    "@answerCountOnDevice"
  );
  if (answerCountOnDevice === null) {
    await AsyncStorage.setItem("@answerCountOnDevice", `${1}`);
  } else {
    const incrementedInt = parseInt(answerCountOnDevice) + 1;
    await AsyncStorage.setItem("@answerCountOnDevice", `${incrementedInt}`);
  }
};

export const GetAnswerCountOnDevice = async () => {
  const answerCountOnDevice = await AsyncStorage.getItem(
    "@answerCountOnDevice"
  );
  if (answerCountOnDevice === null) {
    return 0;
  } else {
    return parseInt(answerCountOnDevice);
  }
};

export const GetCountToAskForReview = async () => {
  const countToAskForReview = await AsyncStorage.getItem(
    "@countToAskForReview"
  );
  const count =
    countToAskForReview === null
      ? DEFAULT_COUNT_TO_ASK_FOR_REVIEW
      : parseInt(countToAskForReview);
  return count;
};

export const BackOffCountToAskForReview = async () => {
  const currentCountToAskForReview = await GetCountToAskForReview();
  await AsyncStorage.setItem(
    "@countToAskForReview",
    `${currentCountToAskForReview * BACKOFF_MULTIPLE}`
  );
};
