import React from "react";
import { View, TouchableOpacity } from "react-native";
import { tailwind as tw } from "../../tailwind";
import { QA } from "../../types/question";
import { SubmitButton } from "../Buttons/SubmitButton";
import { QATextInput } from "../QATextInput";
import { QACardHeaderAnswerer } from "./QACardHeaderAnswerer";
import { QACardHeaderQuestioner } from "./QACardHeaderQuestioner";
import { QACardContent } from "./QACardContent";
import { QACardReplyTo } from "./QACardReplyTo";

type Props = {
  qa: QA;
  answerUserName: string;
  answerUserIconUrl: string;
  replyText?: string;
  replyToQA?: QA;
  replyToQALabel?: string;
  isCaretVisible: boolean;
  isPressable: boolean;
  isReplyButtonShown?: boolean;
  isReplyFormShown?: boolean;
  isViewedBySender?: boolean;
  onPressShowReply?: () => void;
  onPressSubmitReply?: () => void;
  onChangeReplyText?: (text: string) => void;
  onPressReplyToQA?: () => void;
  onPressCaret: () => void;
  onPress: () => void;
};

type QATextsProps = {
  qa: QA;
  isPressable: boolean;
  isCaretVisible: boolean;
  answerUserName: string;
  answerUserIconUrl: string;
  onPressCaret: () => void;
};
const QATexts = ({
  qa,
  isPressable,
  isCaretVisible,
  answerUserName,
  answerUserIconUrl,
  onPressCaret,
}: QATextsProps) => (
  <>
    <QACardHeaderQuestioner
      tips={
        qa.tipsChosen !== undefined
          ? qa.tipsChosen
          : qa.tips === 0
          ? undefined
          : qa.tips
      }
      askedAt={qa.askedAt}
      isPressable={isPressable}
      isCaretVisible={isCaretVisible}
      onPressCaret={onPressCaret}
      isLiked={qa.liked}
      isAskedAsPrivate={qa.isAskedAsPrivate}
    />
    <QACardContent text={qa.text} isPressable={isPressable} isExpanded={true} />
    <QACardHeaderAnswerer
      answeredAt={qa.answeredAt}
      isPressable={isPressable}
      answerUserIconUrl={answerUserIconUrl}
      answerUserName={answerUserName}
    />
    <QACardContent
      text={qa.answer}
      isPressable={isPressable}
      isExpanded={true}
      textForLimitedOnly={qa.answerTextLimitedOnly}
      isLimitedOnly={qa.isLimitedOnly}
    />
  </>
);

export const QACard = ({
  qa,
  answerUserName,
  answerUserIconUrl,
  replyText,
  replyToQA,
  replyToQALabel,
  isCaretVisible,
  isPressable,
  isReplyButtonShown,
  isReplyFormShown,
  isViewedBySender,
  onPressShowReply,
  onPressSubmitReply,
  onChangeReplyText,
  onPressReplyToQA,
  onPressCaret,
  onPress,
}: Props) => {
  return (
    <View
      style={tw(`${isPressable ? "rounded-2xl" : ""} bg-white px-4 mx-4 my-2`)}
    >
      {replyToQA !== undefined &&
        onPressReplyToQA !== undefined &&
        replyToQALabel !== undefined && (
          <>
            <View style={tw("h-4")} />
            <QACardReplyTo
              answerUserName={answerUserName}
              answerUserIconUrl={answerUserIconUrl}
              qa={replyToQA}
              isPressable={onPressReplyToQA !== undefined}
              isExpanded={false}
              onPress={onPressReplyToQA}
              label={replyToQALabel}
            />
          </>
        )}
      {isPressable ? (
        <TouchableOpacity onPress={onPress}>
          <QATexts
            qa={qa}
            isPressable={isPressable}
            isCaretVisible={isCaretVisible}
            answerUserName={answerUserName}
            answerUserIconUrl={answerUserIconUrl}
            onPressCaret={onPressCaret}
          />
        </TouchableOpacity>
      ) : (
        <QATexts
          qa={qa}
          isPressable={isPressable}
          isCaretVisible={isCaretVisible}
          answerUserName={answerUserName}
          answerUserIconUrl={answerUserIconUrl}
          onPressCaret={onPressCaret}
        />
      )}
      {isReplyButtonShown === true &&
        onPressShowReply !== undefined &&
        isReplyFormShown !== true && (
          <View style={tw("mb-2 -mx-2")}>
            <SubmitButton
              label={
                isViewedBySender === true
                  ? "お礼メッセージ/追加質問を送る"
                  : "関連する質問/メッセージを送る"
              }
              onPress={onPressShowReply}
              smallerText={isViewedBySender !== true}
            />
          </View>
        )}
      {isReplyFormShown === true &&
        onPressSubmitReply !== undefined &&
        onChangeReplyText !== undefined &&
        replyText !== undefined && (
          <View style={tw("-mx-2")}>
            <QATextInput
              onChangeText={(replyText) => onChangeReplyText(replyText)}
              placeholder={"ここに質問/メッセージを入力してください。"}
              text={replyText}
            />
            <View style={tw("mb-2")}>
              <SubmitButton
                label={"関連する質問/メッセージを匿名で送信"}
                onPress={() => {
                  {
                    replyText.length > 0
                      ? onPressSubmitReply()
                      : alert(
                          "質問/メッセージが入力されていません。質問/メッセージを入力してください。"
                        );
                  }
                }}
                smallerText={true}
              />
            </View>
          </View>
        )}
    </View>
  );
};
