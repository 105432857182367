import React, { useState } from "react";
import {
  SafeAreaView,
  ScrollView,
  Text,
  TouchableOpacity,
  useWindowDimensions,
  View,
} from "react-native";
import { GetProperWidth } from "../lib";
import { tailwind as tw } from "../../tailwind";
import { Bullets } from "../../components/Bullets";
import { StackNavigationProp } from "@react-navigation/stack";
import { RootStackParamList } from "../../navigation/types";
import { TerminateLimited } from "../../firebase/Functions";
import { ShowToast } from "../../lib";
import { Loading } from "../../components/Loading";
import { CheckBox } from "react-native-elements";
import { DialogBoxWithUpToTwoOptions } from "../../components/DialogBoxWithUpToTwoOptions";

type Props = {
  navigation: StackNavigationProp<RootStackParamList, "limited_terminate">;
};

export const LimitedTerminateScreen = ({ navigation }: Props) => {
  const [loading, setLoading] = useState(false);
  const [isChecked, setChecked] = useState(false);
  const [isConfirmDialogShown, setConfirmDialogShown] = useState(false);
  const window = useWindowDimensions();
  const properWidth = GetProperWidth(window);
  const widthString =
    properWidth.num === 1 && properWidth.denom === 1
      ? "w-full"
      : `w-${properWidth.num}/${properWidth.denom}`;
  const onPressTerminate = async () => {
    if (isChecked === false) {
      alert(
        "Querie Limitedを閉鎖できません。\n\nQuerie Limitedを閉鎖するには、上記注意点を確認の上、「上記注意点を確認しました」左のチェックボックスをチェックする必要があります。"
      );
    } else {
      setConfirmDialogShown(true);
    }
  };
  return loading ? (
    <Loading />
  ) : (
    <SafeAreaView style={tw("w-full items-center bg-gray-300 flex-1")}>
      <ScrollView style={tw(`${widthString} bg-white p-4`)}>
        <View style={tw(`items-center flex flex-grow w-full`)}>
          <Bullets
            header="本当にQuerie Limitedを閉鎖しますか？"
            texts={[
              "現在のLimited登録は全て解除され、収益が受け取れなくなります。(この操作は取り消せません。)",
              "閉鎖すると新たにLimitedへの加入を受け入れることができなくなり、現状の加入者は最後に会費を支払ってから1ヶ月経過後にLimited限定回答を閲覧することができなくなります。",
              "Querie Limited閉鎖後にQuerie.meから退会するには、全てのLimited会員の契約が満了する必要があり、最大1ヶ月間待つ必要があります。",
              "これまでのLimited限定回答の限定回答範囲設定は引き継がれ、他ユーザーは一切閲覧できなくなります。",
              "Limitedを再開設した場合には引続き限定回答範囲設定は保持され、他ユーザーはLimitedに登録することで、これまでの限定回答を閲覧することができるようになります。",
            ]}
          />
          <View style={tw("flex-row items-center")}>
            <CheckBox
              checkedColor="black"
              uncheckedColor="black"
              iconType="ionicon"
              checkedIcon="checkmark-circle-outline"
              uncheckedIcon="ellipse-outline"
              size={24}
              checked={isChecked}
              onPress={() => {
                isChecked ? setChecked(false) : setChecked(true);
              }}
            />
            <Text
              style={tw(
                "text-black font-bold justify-center items-center text-lg leading-10"
              )}
            >
              上記注意点を確認しました。
            </Text>
          </View>
          <TouchableOpacity
            style={tw(
              `border border-red-700 bg-red-700 w-full rounded-full mt-2 p-2 flex`
            )}
            onPress={onPressTerminate}
          >
            <Text style={tw(`text-white text-base font-bold text-center`)}>
              Querie Limitedを閉鎖する
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={tw(
              `border border-bluegray-800 bg-white border-bluegray-800 w-full rounded-full mt-2 p-2 flex`
            )}
            onPress={() => navigation.navigate("limited_settings")}
          >
            <Text
              style={tw(`text-bluegray-800 text-base font-bold text-center`)}
            >
              戻る
            </Text>
          </TouchableOpacity>
          <View style={tw(`h-80`)} />
        </View>
      </ScrollView>
      <DialogBoxWithUpToTwoOptions
        isShown={isConfirmDialogShown}
        title="本当によろしいですか？"
        message="Querie Limitedを閉鎖します。この操作は取り消せません。"
        buttons={[
          {
            label: "閉鎖する",
            onPress: async () => {
              setLoading(true);
              await TerminateLimited()
                .then(() => {
                  ShowToast("success", "Querie Limitedを閉鎖しました。");
                  navigation.navigate("home", {
                    screen: "account_setting",
                    params: {},
                  });
                })
                .catch((e) =>
                  alert(
                    `Querie Limited閉鎖保存に際して不明なエラーが発生しました。運営までご連絡ください。${e}`
                  )
                )
                .finally(() => {
                  setLoading(false);
                  setConfirmDialogShown(false);
                });
            },
          },
          { label: "閉鎖しない", onPress: () => setConfirmDialogShown(false) },
        ]}
        widthString={widthString}
      />
    </SafeAreaView>
  );
};
