import { doc, getDoc } from "firebase/firestore";
import { TipsSetting } from "./../types/tipsSetting";
import { db } from "./Init";

export const ExistsTipsSetting = async (userId: string | undefined) => {
  if (userId === undefined) {
    return false;
  }
  const snapshot = await getDoc(doc(db, "tips_settings", userId));
  return snapshot.exists();
};

export const GetTipsSetting = async (userId: string) => {
  const exists = await ExistsTipsSetting(userId);
  if (exists === false) {
    return undefined;
  }
  const snapshot = await getDoc(doc(db, "tips_settings", userId));
  return snapshot.data() as TipsSetting;
};
