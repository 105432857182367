import React from "react";
import { View, Text } from "react-native";
import { tailwind as tw } from "../../tailwind";
import { MaterialCommunityIcons } from "@expo/vector-icons";

export const LikedIcon = () => {
  return (
    <View style={tw(`bg-red-700 mr-2 mt-4 px-1 flex-row items-center`)}>
      <MaterialCommunityIcons name={"heart"} size={12} color={"white"} />
      <Text style={tw("text-xs text-white text-center")}>いいね!</Text>
    </View>
  );
};
