import React from "react";
import { Text, View } from "react-native";
import { tailwind as tw } from "../../tailwind";
import Autolink from "react-native-autolink";
import { OnPressHyperLink } from "../lib";

type ContentProps = {
  text: string;
  isLimitedOnly?: boolean;
  textForLimitedOnly?: string;
  isPressable: boolean;
  isExpanded: boolean;
  smaller?: boolean;
};

const Separator = () => (
  <View style={tw("border border-dotted border-bluegray-800 p-1")}>
    <Text style={tw("text-xs text-gray-500  text-center")}>
      以下はQuerie Limited登録者限定公開
    </Text>
  </View>
);
export const QACardContent = ({
  text,
  isLimitedOnly,
  textForLimitedOnly,
  isPressable,
  isExpanded,
  smaller,
}: ContentProps) => {
  const textStyle = tw(
    `${
      smaller ? "text-sm leading-6 " : "text-base leading-7 "
    } text-gray-800 my-2`
  );
  const linkStyle = tw(
    `${
      smaller ? "text-sm leading-6 " : "text-base leading-7 "
    } text-gray-800 underline`
  );
  return isPressable ? (
    <>
      <Text
        style={textStyle}
        selectable={true}
        numberOfLines={isExpanded ? undefined : 3}
        ellipsizeMode={isExpanded ? undefined : "tail"}
      >
        {text}
      </Text>
      {isLimitedOnly && textForLimitedOnly !== undefined && (
        <>
          <Separator />
          <Text
            style={textStyle}
            selectable={true}
            numberOfLines={isExpanded ? undefined : 3}
            ellipsizeMode={isExpanded ? undefined : "tail"}
          >
            {textForLimitedOnly}
          </Text>
        </>
      )}
    </>
  ) : (
    <>
      <Autolink
        text={text}
        style={textStyle}
        linkStyle={linkStyle}
        onPress={(url) => {
          OnPressHyperLink(url);
        }}
        numberOfLines={isExpanded ? undefined : 3}
        ellipsizeMode={isExpanded ? undefined : "tail"}
      />
      {isLimitedOnly && textForLimitedOnly !== undefined && (
        <>
          <Separator />
          <Autolink
            text={textForLimitedOnly}
            style={textStyle}
            linkStyle={linkStyle}
            onPress={(url) => {
              OnPressHyperLink(url);
            }}
            numberOfLines={isExpanded ? undefined : 3}
            ellipsizeMode={isExpanded ? undefined : "tail"}
          />
        </>
      )}
    </>
  );
};
