import { Timestamp } from "firebase/firestore";
import AsyncStorage from "@react-native-async-storage/async-storage";

export const SetLastViewed = async (lastViewedToSave: Timestamp) => {
  const lastViewedMillis = lastViewedToSave.toMillis();
  await AsyncStorage.setItem("@lastViewed", lastViewedMillis.toString());
};

export const GetLastViewed = async () => {
  const LastViewed = await AsyncStorage.getItem("@lastViewed");
  return LastViewed === null ? null : Number(LastViewed);
};
