import { View, Text } from "react-native";
import { tailwind as tw } from "../../tailwind";

export const AskedAsPrivateText = () => {
  return (
    <View style={tw("mx-4 my-2 border border-red-700 bg-white p-2")}>
      <Text style={tw("text-center text-red-700 font-bold mb-1")}>
        質問者が「非公開質問」として送信しました
      </Text>
      <Text style={tw("text-center text-xs text-red-700")}>
        この質問への回答は質問者のみに送付され、
      </Text>
      <Text style={tw("text-center text-xs text-red-700")}>
        TwitterやQuerie.meで公開されません。
      </Text>
      <Text style={tw("text-center text-xs text-red-700")}>
        質問内容はスクショやコピペ等で公開しないでください。
      </Text>
    </View>
  );
};
