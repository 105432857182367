import React from "react";
import { Text, TouchableOpacity } from "react-native";
import { tailwind as tw } from "../tailwind";

type Props = {
  option: string;
  isActive: boolean;
  onPress: (label: string) => void;
};

export const SettingDetailItem = ({ option, isActive, onPress }: Props) => {
  return (
    <TouchableOpacity
      style={tw(
        `rounded-3xl h-12 ${
          isActive ? "bg-bluegray-800" : "bg-white"
        } px-6 mx-4 my-1 flex-row items-center`
      )}
      onPress={() => onPress(option)}
    >
      <Text
        style={tw(`${isActive ? "text-white" : "text-bluegray-800"} text-xl`)}
      >
        {option}
      </Text>
    </TouchableOpacity>
  );
};
