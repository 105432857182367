import React from "react";
import { View, Text } from "react-native";
import { tailwind as tw } from "../../tailwind";

export const SummaryLimited = () => {
  return (
    <View style={tw("mb-6 w-full")}>
      <Text
        style={tw(
          "font-bold text-xl mb-2 bg-bluegray-800 text-white text-center w-full p-2"
        )}
      >
        Querie Limitedとは
      </Text>

      <Text style={tw("text-lg text-center mb-2")}>
        回答者が月額サブスクリプション制の「Querie
        Limited」を開設でき、回答者は各回答の一部または全文を「Limited限定公開」することで登録者のみへ公開することができます。
      </Text>
    </View>
  );
};
