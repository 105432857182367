import { db } from "./Init";
import { User } from "../types/user";
import {
  FieldValue,
  collection,
  deleteField,
  doc,
  getDoc,
  getDocs,
  query,
  runTransaction,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { TipsLimitDaily } from "../types/tipsLimitDaily";

type user_collection = "users_active" | "users_dormant" | "users_terminated";

export const GetUserActive = async (id: User["id"]) => {
  if (id === undefined) {
    return undefined;
  }
  const snapShot = await getDoc(doc(db, "users_active", id));
  const data = snapShot.data();
  if (data === undefined) {
    return undefined;
  }
  const user = { id: id, ...data } as User;
  return user;
};

export const IsUserActive = async (id: User["id"]) => {
  if (id === undefined) {
    return false;
  }
  const snapShot = await getDoc(doc(db, "users_active", id));
  return snapShot.exists();
};

export const AddUserActive = async (idFirebaseAuth: string, user: User) => {
  await setDoc(doc(db, "users_active", idFirebaseAuth), user);
};

export const MoveUser = async (
  id: string,
  moveFrom: user_collection,
  moveTo: user_collection
) => {
  const fromReference = doc(db, moveFrom, id);
  await runTransaction(db, async (transaction) => {
    const snapShot = await transaction.get(fromReference);
    transaction.set(doc(db, moveTo, id), snapShot.data());
    transaction.delete(fromReference);
  });
};

export const UpdateUser = async (
  userId: string,
  params: Partial<User & { tipsLimitDaily?: TipsLimitDaily | FieldValue }>
) => {
  await updateDoc(doc(db, "users_active", userId), params);
};

export const DeleteTipsLimitDaily = async (userId: string) => {
  await updateDoc(doc(db, "users_active", userId), {
    tipsLimitDaily: deleteField(),
  });
};

export const ExistsScreenName = async (screenName: string) => {
  const snapshot = await getDocs(
    query(collection(db, "users_active"), where("screenName", "==", screenName))
  );
  return snapshot.docs.length !== 0;
};
