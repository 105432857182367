import React, { useContext, useEffect, useState } from "react";
import { Platform, useWindowDimensions } from "react-native";
import { NavigationContainer } from "@react-navigation/native";
import { UserContext } from "../contexts/userContext";
import { AppContainer } from "./AppContainer";
import { LinkingConfiguration } from "./LinkingConfiguration";
import { Loading } from "../components/Loading";
import { User } from "../types/user";
import * as storage from "../local_storage/user";
import * as Sentry from "sentry-expo";
import { navigationRef } from "./RootNavigation";
import { AlertAndReportError, GetProperWidth } from "../screens/lib";
import NetInfo from "@react-native-community/netinfo";
//@ts-ignore
import { DialogBoxWithUpToTwoOptions } from "../components/DialogBoxWithUpToTwoOptions";
import Constants from "expo-constants";
import {
  GetPreviousAppVersion,
  SetPreviousAppVersion,
} from "../local_storage/previousAppVersion";
import { signOut } from "firebase/auth";
import { auth } from "../firebase/Init";
import * as storage_user from "../local_storage/user";

export const AppNavigator = () => {
  const { user, setUser } = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const [isNetworkErrorDialogShown, setIsNetworkErrorDialogShown] =
    useState(false);
  const [updateAlertShown, setUpdateAlertShown] = useState(false);
  const [updateAlertContent, setUpdateAlertContent] = useState("");
  const window = useWindowDimensions();
  const properWidth = GetProperWidth(window);
  const widthString =
    properWidth.num === 1 && properWidth.denom === 1
      ? "w-full"
      : `w-${properWidth.num}/${properWidth.denom}`;

  useEffect(() => {
    const unsubscribe = NetInfo.addEventListener(async (state) => {
      if (
        state.isInternetReachable === false &&
        state.isConnected === false &&
        isNetworkErrorDialogShown === false
      ) {
        setIsNetworkErrorDialogShown(true);
      }
    });
    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    let isMounted = true;
    if (!user) {
      const loadUser = async () => {
        const user = await storage.GetUser().catch((e) => {
          AlertAndReportError(e, "GetUser on AppNavigator");
          return null;
        });
        if (user !== null) {
          setUser(user);
        }
        setLoading(false);
      };
      isMounted && loadUser();
    } else {
      setLoading(false);
    }

    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    let isMounted = true;
    isMounted &&
      Platform.OS !== "web" &&
      Sentry.Native.setContext("user", user!);
    return () => {
      isMounted = false;
    };
  }, [user]);

  useEffect(() => {
    if (user === undefined) return;
    let isMounted = true;
    if (isMounted === true) {
      GetPreviousAppVersion()
        .then(async (previousAppVersion) => {
          const releaseNoteVer = Constants.manifest?.extra?.releaseNotes
            .version as string | undefined;
          const releaseNoteContent = Constants.manifest?.extra?.releaseNotes
            .content as string | undefined;
          const currentAppVersion = Constants.manifest?.version;
          // 新バージョンの場合は再ログインを要求する

          if (previousAppVersion === null || currentAppVersion === undefined) {
            return;
          } else if (previousAppVersion === currentAppVersion) {
            return;
          } else {
            if (
              (releaseNoteVer === undefined ||
                releaseNoteVer === currentAppVersion) &&
              releaseNoteContent !== undefined &&
              releaseNoteContent !== ""
            ) {
              setUpdateAlertContent(releaseNoteContent);
              setUpdateAlertShown(true);
            }
            if (previousAppVersion !== currentAppVersion) {
              await storage_user.DeleteUser().catch((e) => {
                AlertAndReportError(e, "DeleteUser on AcountScreen");
                return false;
              });
              await signOut(auth);
              setUser(undefined);
              return;
            }
          }
        })
        .then(() => {
          SetPreviousAppVersion();
        });
    }
    return () => {
      isMounted = false;
    };
  }, [user]);

  return (
    <NavigationContainer ref={navigationRef} linking={LinkingConfiguration}>
      {loading ? <Loading /> : <AppContainer />}
      <DialogBoxWithUpToTwoOptions
        title={"通信環境が不安定です"}
        message="安定したネットワーク環境を確保してください。"
        widthString={widthString}
        buttons={[
          {
            label: "OK",
            onPress: async () => {
              setIsNetworkErrorDialogShown(false);
            },
          },
        ]}
        isShown={isNetworkErrorDialogShown}
      />
      <DialogBoxWithUpToTwoOptions
        title={"アップデートのお知らせ"}
        message={updateAlertContent}
        widthString={widthString}
        buttons={[
          {
            label: "OK",
            onPress: () => {
              setUpdateAlertShown(false);
            },
          },
        ]}
        isShown={updateAlertShown}
      />
    </NavigationContainer>
  );
};
