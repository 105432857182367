import React from "react";
import { View } from "react-native";
import { tailwind as tw } from "../../tailwind";
import { MaterialCommunityIcons } from "@expo/vector-icons";

type Props = {
  sortedNewToOld: boolean;
  filterActivated: boolean;
  properButtonPosition: string;
  onPressSort: () => void;
  onPressFilter: () => void;
  isFilterShown: boolean;
};

export const ButtonOnUnansweredHeader = ({
  sortedNewToOld,
  filterActivated,
  properButtonPosition,
  onPressSort,
  onPressFilter,
  isFilterShown,
}: Props) => {
  return (
    <View
      style={{
        right: properButtonPosition,
      }}
    >
      <View style={tw("flex-row")}>
        {isFilterShown && (
          <MaterialCommunityIcons
            name={filterActivated ? "filter-check-outline" : "filter-outline"}
            size={30}
            color="white"
            style={tw("mr-4")}
            onPress={onPressFilter}
          />
        )}
        <MaterialCommunityIcons
          name={
            sortedNewToOld
              ? "sort-calendar-descending"
              : "sort-calendar-ascending"
          }
          size={30}
          color="white"
          style={tw("mr-4")}
          onPress={onPressSort}
        />
      </View>
    </View>
  );
};
