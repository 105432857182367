import React, { useState } from "react";
import {
  GetQuestions,
  MoveQuestionFromTrash,
  SearchQuestion,
  TrashQuestionAnswered,
} from "../firebase/Questions";
import { Timestamp } from "firebase/firestore";
import { useFocusEffect } from "@react-navigation/native"; // works
import { User } from "../types/user";
import { QA } from "../types/question";
import { AlertAndReportError } from "../screens/lib";
import * as Analytics from "expo-firebase-analytics";
import * as storage from "../local_storage/answerDraft";

export const UseQAs = (user: User, SEARCH_RESULT_COUNT: number) => {
  const [answeredQuestions, setAnsweredQuestions] = useState<QA[]>([]);
  const [loading, setLoading] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchHitQuestions, setSearchHitQuestions] = useState<QA[]>([]);
  const [moreThanLimitHits, setMoreThanLimitHits] = useState(false);
  const [isSearchActive, setIsSearchActive] = useState(false);

  useFocusEffect(
    React.useCallback(() => {
      fetchQuestions(true).then((questions) => {
        questions !== undefined &&
          questions.length === 0 &&
          alert(
            "回答済みの質問一覧画面です。\n 質問に回答するとこちらに回答した質問の一覧が表示されます。"
          );
      });
      return () => {
        setAnsweredQuestions([]);
        setSearchHitQuestions([]);
        setMoreThanLimitHits(false);
        setIsSearchActive(false);
      };
    }, [])
  );
  const fetchQuestions = async (isRefresh: boolean) => {
    if (loading) return undefined;

    setLoading(true);
    const startAfter = isRefresh
      ? Timestamp.now()
      : answeredQuestions.length > 0
      ? answeredQuestions[answeredQuestions.length - 1].answeredAt
      : Timestamp.now();

    if (!user || !user.id) {
      setLoading(false);
      return undefined;
    }

    return await GetQuestions(
      "questions_answered",
      user.id,
      startAfter,
      "answeredAt",
      20
    )
      .then((additionalRaw) => {
        const additional = additionalRaw.filter(
          (qa) =>
            !answeredQuestions.some((existingQA) => existingQA.id === qa.id)
        ) as QA[];

        if (additional.length > 0) {
          setAnsweredQuestions((prevQuestions) =>
            isRefresh ? additional : [...prevQuestions, ...additional]
          );
        }
        return [...answeredQuestions, ...additional];
      })
      .catch((e) => {
        AlertAndReportError(e, "GetQuestions on QA Screen");
        return undefined;
      })
      .finally(() => setLoading(false));
  };

  const fetchQuestionsSearch = async (text: string) => {
    setLoading(true);
    const results = await SearchQuestion(
      "questions_answered",
      user?.id!,
      text,
      SEARCH_RESULT_COUNT
    );
    if (results.length >= SEARCH_RESULT_COUNT) {
      setMoreThanLimitHits(true);
      setSearchHitQuestions([]);
    } else {
      results.sort((a, b) => (a.answeredAt > b.answeredAt ? -1 : 1));
      setSearchHitQuestions(results);
    }
    setLoading(false);
  };

  const onEndReached = () => {
    if (loading) return;
    fetchQuestions(false);
  };

  const onRefresh = async () => {
    setRefreshing(true);
    await fetchQuestions(true).catch((e) => {
      AlertAndReportError(e, "fetchQuestions on QA Screen");
    });
    setRefreshing(false);
  };

  const onPressSearchButton = async () => {
    setSearchHitQuestions([]);
    setSearchTerm("");
    setIsSearchActive(!isSearchActive);
  };

  const onChangeSearchTerm = async (text: string) => {
    setMoreThanLimitHits(false);
    setSearchTerm(text);
    if (!text) {
      setSearchHitQuestions([]);
    } else {
      fetchQuestionsSearch(text);
    }
  };
  const deleteQA = async (qa: QA, deleteTweet: boolean) => {
    await TrashQuestionAnswered(qa.id!, deleteTweet)
      .then(() =>
        setAnsweredQuestions(answeredQuestions.filter((x) => x.id !== qa.id))
      )
      .then(() => {
        setLoading(false);
        Analytics.logEvent("trashed", { at: "QAScreen" });
      })
      .catch((e) => {
        AlertAndReportError(e, "TrashQuestionAnswered on QA Screen");
      });
  };

  const modifyQA = async (qa: QA, deleteTweet: boolean) => {
    await TrashQuestionAnswered(qa.id!, deleteTweet).catch((e) => {
      AlertAndReportError(e, "TrashQuestionAnswered on QA Screen");
    });
    await MoveQuestionFromTrash(qa.id!, "questions_unanswered").catch((e) => {
      AlertAndReportError(e, "MoveQuestionFromTrash on QA Screen");
    });
    await storage
      .SetAnswerDraft(
        qa.id,
        qa.isLimitedOnly === true && qa.answerTextLimitedOnly !== undefined
          ? `${qa.answer}\n${qa.answerTextLimitedOnly}`
          : qa.answer
      )
      .then(async () =>
        setAnsweredQuestions(answeredQuestions.filter((x) => x.id !== qa.id))
      )
      .then(() => {
        setLoading(false);
        Analytics.logEvent("returnedToUnanswered", {
          at: "QAScreen",
        });
      })
      .catch((e) => {
        AlertAndReportError(e, "SetAnswerDraft on QA Screen");
      });
  };

  return {
    loading,
    isSearchActive,
    searchTerm,
    refreshing,
    answeredQuestions,
    searchHitQuestions,
    moreThanLimitHits,
    setLoading,
    onChangeSearchTerm,
    onEndReached,
    onRefresh,
    onPressSearchButton,
    deleteQA,
    modifyQA,
  };
};
