import React, { useContext, useState } from "react";
import {
  SafeAreaView,
  useWindowDimensions,
  Text,
  View,
  ScrollView,
  TextInput,
} from "react-native";
import { StackNavigationProp } from "@react-navigation/stack";
import { AlertPromisified, GetProperWidth, OnSubmitEmail } from "../lib";
import { Loading } from "../../components/Loading";
import { RootStackParamList } from "../../navigation/types";
import { tailwind as tw } from "../../tailwind";
import { UserContext } from "../../contexts/userContext";
import { SubmitButton } from "../../components/Buttons/SubmitButton";
import { RouteProp } from "@react-navigation/core";
import { GetEmailAddressVerified } from "../../firebase/EmailAddresses";
import { ExplainTips } from "../../components/ExplainTips";
import { ExplainStripeRegisterSteps } from "../../components/ExplainStripeRegisterSteps";

type Props = {
  navigation: StackNavigationProp<
    RootStackParamList,
    "tips_email_registration"
  >;
  route: RouteProp<RootStackParamList, "tips_email_registration">;
};

export const TipsEmailRegistration = ({ navigation, route }: Props) => {
  const window = useWindowDimensions();
  const properWidth = GetProperWidth(window);
  const widthString =
    properWidth.num === 1 && properWidth.denom === 1
      ? "w-full"
      : `w-${properWidth.num}/${properWidth.denom}`;
  const [loading, setLoading] = useState(false);
  const [mailAddress, setMailAddress] = useState("");
  const { user } = useContext(UserContext);
  const stripeSettingType = "tips";

  return (
    <SafeAreaView style={tw("w-full items-center bg-gray-300 flex-1")}>
      <ScrollView style={tw(`${widthString} bg-white p-4`)}>
        <View style={tw(`items-center flex flex-grow w-full`)}>
          <ExplainTips />
          <ExplainStripeRegisterSteps stripeSettingType={stripeSettingType} />
          <TextInput
            style={tw(
              "rounded-lg text-sm border w-full text-gray-800 h-12 mt-4 mb-2 p-2"
            )}
            autoCapitalize="none"
            textContentType="emailAddress"
            keyboardType="email-address"
            autoCorrect={false}
            onChangeText={(mailAddress) => setMailAddress(mailAddress)}
            value={mailAddress}
            placeholder={"メールアドレスを入力"}
          />
          <Text style={tw("text-center text-sm mb-2")}>
            メールアドレスはQuerie.meからの通知のみに使用され、サービス内外にて一切公開されません。
          </Text>
          <View style={tw("h-16 w-full")}>
            <SubmitButton
              label="手順1/3:メールアドレスを登録する"
              onPress={async () => {
                setLoading(true);
                const emailBefore = await GetEmailAddressVerified(user?.id!);
                if (emailBefore === undefined) {
                  await OnSubmitEmail(user, mailAddress, "tips");
                  const emailAfter = await GetEmailAddressVerified(user?.id!);
                  if (emailAfter === undefined) {
                    AlertPromisified(
                      "メールアドレスが確認されていません。届かない場合はメールアドレスが正しい事、querie.meからのメールが受信許可されていることを確認してメールアドレスを再登録してください。"
                    );
                  } else {
                    navigation.navigate("tips_stripe_registration");
                  }
                } else {
                  navigation.navigate("tips_stripe_registration");
                }
                setLoading(false);
              }}
            />
          </View>
          <View style={tw("h-80 w-full")}></View>
        </View>
        <View style={tw("h-16 w-full")}></View>
      </ScrollView>
      {loading && <Loading />}
    </SafeAreaView>
  );
};
