import { Ionicons } from "@expo/vector-icons";
import React from "react";
import { Text, View, Switch } from "react-native";
import { tailwind as tw } from "../tailwind";

type Props = {
  label: string;
  value: boolean;
  onValueChange: () => void;
  onPressHelp: () => void;
};

export const SettingSwitchSmall = ({
  label,
  value,
  onValueChange,
  onPressHelp,
}: Props) => (
  <View style={tw("bg-white flex-row p-2 justify-between my-2 items-center")}>
    <View style={tw("flex-row self-start")}>
      <Text style={tw("text-xs py-2 mr-2")}>{label}</Text>
      <Ionicons
        name="help-circle-outline"
        size={24}
        color="black"
        onPress={onPressHelp}
      />
    </View>

    <Switch
      value={value}
      style={tw("self-end mr-2 self-center")}
      onValueChange={onValueChange}
    />
  </View>
);
