import React from "react";
import { Text, View } from "react-native";
import { tailwind as tw } from "../../tailwind";
import { SimpleLineIcons } from "@expo/vector-icons";
import { CheckBox } from "react-native-elements";

type props = {
  isChecked: boolean;
  onCheckPressed: () => void;
};

export const TwitterShareCheckBox = ({ isChecked, onCheckPressed }: props) => {
  return (
    <View style={tw("flex flex-row items-center justify-start")}>
      <CheckBox
        checkedColor="gray"
        uncheckedColor="gray"
        iconType="ionicon"
        checkedIcon="checkmark-circle-outline"
        uncheckedIcon="ellipse-outline"
        size={24}
        checked={isChecked}
        onPress={onCheckPressed}
        containerStyle={tw("-my-1 -mr-1")}
      />

      <SimpleLineIcons
        name="social-twitter"
        size={24}
        color="gray"
        style={tw("")}
      />
      <Text style={tw("ml-2 text-sm text-center text-gray-500")}>
        Twitterで回答を共有する
      </Text>
    </View>
  );
};
