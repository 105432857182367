import React from "react";
import { FontAwesome5 } from "@expo/vector-icons";
import { tailwind as tw } from "../../tailwind";

type Props = {
  onPress: () => void;
  bluegray?: boolean;
};

export const BackButton = ({ onPress, bluegray }: Props) => (
  <FontAwesome5
    name="chevron-left"
    color={bluegray === true ? "#1E293B" : "white"}
    style={tw("ml-4")}
    size={24}
    onPress={onPress}
  />
);
