import React, { useContext, useState, useEffect } from "react";
import {
  Text,
  useWindowDimensions,
  SafeAreaView,
  TouchableOpacity,
  ScrollView,
  TextInput,
  View,
} from "react-native";
import { StackNavigationProp } from "@react-navigation/stack";
import { RootStackParamList } from "../../navigation/types";
import { tailwind as tw } from "../../tailwind";
import { Loading } from "../../components/Loading";
import { UserContext } from "../../contexts/userContext";

import { AlertAndReportError, GetProperWidth, CreateDmAlert } from "../lib";
import { GetUserActive, UpdateUser } from "../../firebase/Users";
import {
  CreateQuerieLimited,
  GenerateAccoutCreateLink,
  GenerateAccoutDashboardLink,
  GetLimitedPrice,
  GetSubscriptionsCounts,
} from "../../firebase/Functions";
import * as Linking from "expo-linking";
import { IdNotVerified } from "../../components/IdNotVerified";
import { AccountBlocked } from "../../components/AccountBlocked";
import { UseSetHeaderLeftGoBack } from "../../hooks/useSetHeaderLeftGoBack";
import { UseTipsSettingsOnFocus } from "../../hooks/useTipsSettingsOnFocus";
import { ShowToast } from "../../lib";
import { DialogBoxWithUpToTwoOptions } from "../../components/DialogBoxWithUpToTwoOptions";
import { SummaryLimited } from "../../components/Texts/SummaryLimited";
import { useFocusEffect } from "@react-navigation/native";
import { SettingSwitchSmall } from "../../components/SettingSwitchSmall";
import { ConfirmDialog } from "react-native-simple-dialogs";
import Unorderedlist from "react-native-unordered-list";

type Props = {
  navigation: StackNavigationProp<RootStackParamList, "limited_settings">;
};

export const LimitedSettingsScreen = ({ navigation }: Props) => {
  const window = useWindowDimensions();
  const properWidth = GetProperWidth(window);
  const widthString =
    properWidth.num === 1 && properWidth.denom === 1
      ? "w-full"
      : `w-${properWidth.num}/${properWidth.denom}`;
  const { user } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [loadingLimitedPrice, setLoadingLimitedPrice] = useState(false);
  const [loadingLimitedPolicy, setLoadingLimitedPolicy] = useState(false);
  const [isMailNotifActivated, setIsMailNotifActivated] = useState(false);
  const [isInitialSetting, setIsInitialSetting] = useState(false);
  const [limitedPolicy, setLimitedPolicy] = useState("");
  const [limitedPrice, setLimitedPrice] = useState<number>();
  const [limitedStartConfirmShown, setLimitedStartConfirmShown] =
    useState(false);
  const [isPricingGuideShown, setPricingGuideShown] = useState(false);
  const [
    isInitialSettingCompleteAlertShown,
    setInitialSettingCompleteAlertShown,
  ] = useState(false);

  type LimitedMemberCounts = {
    active: number;
    canceled: number;
  };
  const [limitedMemberCounts, setLimitedMemberCounts] =
    useState<LimitedMemberCounts>();

  const { tipsSetting } = UseTipsSettingsOnFocus(() => {
    navigation.navigate("home", {
      screen: "account_setting",
      params: {},
    });
  }, user);

  useEffect(() => {
    setLoadingLimitedPrice(true);
    if (tipsSetting === undefined) {
      setLoadingLimitedPrice(false);
    } else if (tipsSetting.limitedPriceId === undefined) {
      setLoadingLimitedPrice(false);
      setIsInitialSetting(true);
    } else {
      GetLimitedPrice().then((price) => {
        setLimitedPrice(price.data);
        setIsInitialSetting(false);
        setLoadingLimitedPrice(false);
      });
    }
    setLoadingLimitedPolicy(true);
    if (user === undefined) {
      setLoadingLimitedPolicy(false);
      return;
    }
    GetUserActive(user.id).then((user) => {
      if (user === undefined) {
        console.log("undefined");
        setLoadingLimitedPolicy(false);
        return;
      } else {
        setIsMailNotifActivated(
          user.isMailNotifActivated === undefined
            ? false
            : user.isMailNotifActivated
        );
        setLimitedPolicy(
          user.limitedPolicy === "" || user.limitedPolicy === undefined
            ? "限定だからこそ書ける回答内容をLimited限定で公開します。"
            : user.limitedPolicy
        );
        setLoadingLimitedPolicy(false);
      }
    });
    return () => {
      setLimitedPolicy("");
      setLimitedPrice(undefined);
    };
  }, [tipsSetting]);

  useFocusEffect(
    React.useCallback(() => {
      GetSubscriptionsCounts()
        .then((x) =>
          setLimitedMemberCounts(
            JSON.parse(x.data.response) as unknown as LimitedMemberCounts
          )
        )
        .catch((e) => console.log(e.code));
    }, [])
  );

  UseSetHeaderLeftGoBack(navigation, () => {
    navigation.navigate("home", { screen: "account_setting", params: {} });
  });

  const isLimitedPriceValid = (limitedPrice: number) =>
    limitedPrice % 100 === 0 && limitedPrice <= 10000 && limitedPrice >= 500;

  const onPressSubmit = async () => {
    if (limitedPolicy === "") {
      alert(
        "Limited限定回答方針が入力されていません。\n入力してから再度ボタンをタップしてください。"
      );
      return;
    }
    if (isInitialSetting) {
      if (limitedPrice === undefined) {
        alert(
          "Limitedの価格が設定されていません。\n価格を入力してから再度ボタンをタップしてください。"
        );
        return;
      }
      if (isLimitedPriceValid(limitedPrice) === false) {
        alert(
          "Limitedの価格は500円以上10,000円以下で100円単位でのみ設定できます。\n価格を修正してから再度ボタンをタップしてください。"
        );
        return;
      }
      setLimitedStartConfirmShown(true);
    } else {
      updateAndExitExistingSetting(limitedPolicy, isMailNotifActivated);
    }
  };

  const updateAndExitInitialSetting = async (
    limitedPrice: number,
    limitedPolicy: string
  ) => {
    setLoading(true);
    await CreateQuerieLimited(limitedPrice)
      .then(async () => {
        user !== undefined &&
          user.id !== undefined &&
          (await UpdateUser(user.id, { limitedPolicy, isMailNotifActivated }));
        setInitialSettingCompleteAlertShown(true);
      })
      .catch((e) => alert(e))
      .finally(() => setLoading(false));
  };

  const updateAndExitExistingSetting = async (
    limitedPolicy: string,
    isMailNotifActivated: boolean
  ) => {
    setLoading(true);
    user !== undefined &&
      user.id !== undefined &&
      (await UpdateUser(user.id, { limitedPolicy, isMailNotifActivated }));
    setLoading(false);
    ShowToast("success", "設定を保存しました。");
    navigation.navigate("home", { screen: "account_setting", params: {} });
  };

  return loading || loadingLimitedPolicy || loadingLimitedPrice ? (
    <Loading />
  ) : (
    <SafeAreaView style={tw("w-full items-center bg-gray-300 flex-1")}>
      <ScrollView style={tw(`${widthString} mb-2 p-4`)}>
        {tipsSetting === undefined ? (
          <Loading />
        ) : tipsSetting.idVerified !== true ? (
          <IdNotVerified
            onPressToDashboard={async () => {
              setLoading(true);
              const dashboardUrl = await GenerateAccoutDashboardLink();
              Linking.openURL(dashboardUrl.response);
              setLoading(false);
            }}
            onPressToRegister={async () => {
              setLoading(true);
              const dashboardUrl = await GenerateAccoutCreateLink();
              Linking.openURL(dashboardUrl.response);
              setLoading(false);
            }}
          />
        ) : tipsSetting.chargesEnabled === false ||
          tipsSetting.isInReview === true ? (
          <AccountBlocked
            onPressStripe={async () => {
              setLoading(true);
              await GenerateAccoutDashboardLink().then((dashboardUrl) =>
                Linking.openURL(dashboardUrl.response)
              );
              setLoading(false);
            }}
            onPressInquiry={async () => {
              setLoading(true);
              const canOpenUrl = await Linking.canOpenURL(
                "twitter://messages/compose?recipient_id=1440949863097585665"
              ).catch((e) => {
                AlertAndReportError(e, "canOpenUrl on AcountScreen");
                return false;
              });
              setLoading(false);
              CreateDmAlert(canOpenUrl);
            }}
          />
        ) : (
          <>
            <SummaryLimited />
            <TouchableOpacity
              style={tw(
                `border border-bluegray-800 bg-white border-bluegray-800 w-full rounded-full mb-2 p-2 flex`
              )}
              onPress={() => navigation.navigate("limited_explain")}
            >
              <Text
                style={tw(`text-bluegray-800 text-base font-bold text-center`)}
              >
                Querie Limited詳細を確認
              </Text>
            </TouchableOpacity>
            <Text
              style={tw(
                "bg-bluegray-800 text-center text-base text-white font-bold p-2 my-2 "
              )}
            >
              Querie Limited価格設定
            </Text>
            <View style={tw("w-full items-center")}>
              <View style={tw(`flex-row flex items-center`)}>
                {isInitialSetting ? (
                  <>
                    <TextInput
                      style={tw(
                        `rounded-lg text-base border text-gray-800 bg-white mb-2 p-2 pb-4 w-20`
                      )}
                      onChangeText={(limitedPrice) =>
                        isNaN(Number(limitedPrice))
                          ? alert("半角数値を入力して下さい。")
                          : setLimitedPrice(Number(limitedPrice))
                      }
                      value={
                        limitedPrice === undefined || limitedPrice === 0
                          ? ""
                          : limitedPrice.toString()
                      }
                      textAlign="center"
                    />
                    <Text style={tw(`rounded-lg text-base mb-2 p-2`)}>
                      円/月
                    </Text>
                  </>
                ) : (
                  limitedPrice !== undefined && (
                    <Text style={tw(`rounded-lg text-lg font-bold p-2`)}>
                      {limitedPrice.toLocaleString()}円/月
                    </Text>
                  )
                )}
              </View>

              <Text style={tw(`text-gray-800`)}>
                {isInitialSetting
                  ? "(500円以上10,000円以下で100円単位で設定)"
                  : "(変更にはQuerie Limitedの閉鎖と再開設が必要です。)"}
              </Text>
              {isInitialSetting && (
                <TouchableOpacity
                  style={tw(
                    `border border-bluegray-800 bg-white border-bluegray-800 w-full rounded-full mt-4 mb-2 p-2 flex`
                  )}
                  onPress={() => setPricingGuideShown(true)}
                >
                  <Text
                    style={tw(
                      `text-bluegray-800 text-base font-bold text-center`
                    )}
                  >
                    価格設定のヒント
                  </Text>
                </TouchableOpacity>
              )}
            </View>

            {limitedMemberCounts !== undefined &&
              isInitialSetting === false && (
                <>
                  <Text
                    style={tw(
                      "bg-bluegray-800 text-center text-base text-white font-bold p-2 my-2"
                    )}
                  >
                    入退会件数
                  </Text>
                  <View style={tw("w-full items-center")}>
                    <View style={tw(`flex-row flex items-center`)}>
                      <Text style={tw(`rounded-lg text-lg font-bold`)}>
                        会員数:{limitedMemberCounts.active.toLocaleString()}人
                      </Text>
                    </View>
                    <View style={tw(`flex-row flex items-center`)}>
                      <Text style={tw(`rounded-lg text-lg font-bold`)}>
                        退会済:{limitedMemberCounts.canceled.toLocaleString()}人
                      </Text>
                    </View>
                  </View>
                </>
              )}

            <Text
              style={tw(
                "bg-bluegray-800 text-center text-base text-white font-bold p-2 mt-2 mb-4"
              )}
            >
              Querie Limited限定回答方針を設定
            </Text>
            <TextInput
              style={tw(
                `rounded-lg text-sm border text-gray-800 bg-white h-20 mb-2 p-2`
              )}
              onChangeText={(limitedPolicy) => setLimitedPolicy(limitedPolicy)}
              value={limitedPolicy}
              placeholder={`Querie Limited限定回答方針を入力`}
              placeholderTextColor="gray"
              multiline={true}
            />
            <Text
              style={tw(
                "bg-bluegray-800 text-center text-base text-white font-bold p-2 my-2"
              )}
            >
              その他設定
            </Text>
            <SettingSwitchSmall
              label={"Limited未登録質問者への回答メール送信"}
              value={isMailNotifActivated}
              onValueChange={() => {
                setIsMailNotifActivated(!isMailNotifActivated);
                isMailNotifActivated &&
                  isInitialSetting === false &&
                  alert(
                    "\n\nこの設定を有効化している間に受け取った質問に関しては回答時に回答者にメールが送付されます。"
                  );
              }}
              onPressHelp={() =>
                alert(
                  "この設定を有効化すると質問への回答をQuerie Limited限定公開した場合に、その質問を送ってくれた質問者はこのQuerie Limitedに登録していなくてもQuerie.meからメールで回答の全文が送られます。"
                )
              }
            />
            <TouchableOpacity
              style={tw(`bg-bluegray-800 w-full rounded-full mt-2 p-2 flex`)}
              onPress={onPressSubmit}
            >
              <Text style={tw(`text-white text-base font-bold text-center`)}>
                {isInitialSetting
                  ? "Querie Limitedを開設"
                  : "Querie Limited設定を保存"}
              </Text>
            </TouchableOpacity>
            {isInitialSetting === false && (
              <TouchableOpacity
                style={tw(
                  `bg-white w-full rounded-full border border-bluegray-800 mt-4 p-2 flex`
                )}
                onPress={() => navigation.navigate("limited_terminate")}
              >
                <Text
                  style={tw(
                    `text-bluegray-800 text-base font-bold text-center`
                  )}
                >
                  Querie Limitedを閉鎖
                </Text>
              </TouchableOpacity>
            )}
            <View style={tw(`h-80`)} />
          </>
        )}
      </ScrollView>
      <DialogBoxWithUpToTwoOptions
        title={"Querie Limitedを開設します。"}
        message={`開設後はQuerie Limitedの価格は変更できません。\n\n${limitedPrice}円/月の価格でQuerie Limitedを開設してよろしいですか？\n\n(「Limited限定回答方針」と「Limited未登録質問者への回答メール送信設定」は変更できます)`}
        widthString={widthString}
        buttons={[
          {
            label: "ＯＫ",
            onPress: () => {
              if (limitedPrice === undefined) {
                alert(
                  "Querie Limited設定保存に際して不明なエラーが発生しました。運営までご連絡ください。"
                );
                setLimitedStartConfirmShown(false);
              } else {
                updateAndExitInitialSetting(limitedPrice, limitedPolicy);
                setLimitedStartConfirmShown(false);
              }
            },
          },
          {
            label: "キャンセル",
            onPress: () => setLimitedStartConfirmShown(false),
          },
        ]}
        isShown={limitedStartConfirmShown}
      />
      <DialogBoxWithUpToTwoOptions
        title={"想定オーディエンスと回答方針毎の価格設定例"}
        widthString={widthString}
        message={`500円
可処分所得が少ない学生など幅広いオーディエンスを想定する場合、もしくは数十文字の回答などカジュアルな使い方をする場合\n
600-900円
ビジネスパーソンなど、可処分所得が高いオーディエンスに対して百文字を超えるようなリッチなコンテンツを発信する場合\n
1,000円以上
オーディエンスの熱量が相当高いか、発信するコンテンツの希少性や換金性が極めて高い場合`}
        buttons={[
          {
            label: "OK",
            onPress: () => {
              setPricingGuideShown(false);
            },
          },
        ]}
        isShown={isPricingGuideShown}
      />
      <ConfirmDialog
        title={"🎉Querie Limitedの設定完了🎉"}
        titleStyle={tw("text-base font-bold underline")}
        dialogStyle={{ ...tw(`${widthString} p-2`), alignSelf: "center" }}
        positiveButton={{
          title: "OK",
          onPress: () => {
            navigation.navigate("home", {
              screen: "unanswered_questions",
              params: {},
            });
            setInitialSettingCompleteAlertShown(false);
          },
          titleStyle: { color: "#1E293B" },
        }}
        visible={isInitialSettingCompleteAlertShown}
      >
        <View>
          <Text style={tw("text-sm text-black")}>
            {[
              "早速限定回答を投稿しましょう！",
              "",
              "限定公開質問選択に適した質問回答例",
            ].join("\n")}
          </Text>
          <Unorderedlist style={tw("text-sm")}>
            <Text style={tw("text-sm")}>
              多くのユーザーが興味を持ちそうな質問への回答全文
            </Text>
          </Unorderedlist>
          <Unorderedlist style={tw("text-sm")}>
            <Text style={tw("text-sm")}>特によい回答ができたと感じる回答</Text>
          </Unorderedlist>
          <Unorderedlist style={tw("text-sm")}>
            <Text style={tw("text-sm")}>長文回答の詳細説明部分</Text>
          </Unorderedlist>
        </View>
      </ConfirmDialog>
    </SafeAreaView>
  );
};
