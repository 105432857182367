import React from "react";
import { Text, TouchableOpacity } from "react-native";
import { tailwind as tw } from "../../tailwind";

type Props = {
  properButtonPosition: string;
  onPress: () => void;
};

export const RequestButtonOnUnansweredHeader = ({
  onPress,
  properButtonPosition,
}: Props) => {
  return (
    <TouchableOpacity
      onPress={onPress}
      style={{
        ...tw("rounded-full  bg-white py-2 px-4 ml-4"),
        left: properButtonPosition,
      }}
    >
      <Text style={tw("text-center font-bold text-sm")}>質問を募集する</Text>
    </TouchableOpacity>
  );
};
