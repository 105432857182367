import { doc, getDoc } from "firebase/firestore";
import { db } from "./Init";
import { EmailAddress } from "../types/emailAddress";

export const GetEmailAddressVerified = async (id: string) => {
  const snapShot = await getDoc(doc(db, "email_addresses", id));
  const emailData = snapShot.data() as EmailAddress | undefined;
  if (emailData === undefined) {
    return undefined;
  }
  if (emailData.emailVerified === undefined) {
    return undefined;
  }
  return emailData.emailVerified;
};
