import React from "react";
import { View, Text } from "react-native";
import { tailwind as tw } from "../../tailwind";

type Props = {
  tips: number;
};

export const TipsIcon = ({ tips }: Props) => {
  return (
    <View style={tw("bg-yellow-400 mr-2 mt-4 px-1")}>
      <Text style={tw("text-xs text-white text-center font-bold")}>
        ¥{tips.toLocaleString()}
      </Text>
    </View>
  );
};
