import React, { useContext, useState, useRef, useEffect } from "react";
import {
  FlatList,
  Text,
  View,
  RefreshControl,
  useWindowDimensions,
  SafeAreaView,
} from "react-native";
import { ShowToast } from "../lib";
import { StackNavigationProp } from "@react-navigation/stack";
import * as Analytics from "expo-firebase-analytics";
import { RootStackParamList } from "../navigation/types";
import { tailwind as tw } from "../tailwind";
import { QuestionCard } from "../components/Cards/QuestionCard";
import { Loading } from "../components/Loading";
import { Question } from "../types/question";
import { Block } from "../types/block";
import { CheckBoxItem } from "../types/checkBoxItem";
import { ActionSheetItem, ActionSheetAction } from "../types/actionSheet";
import {
  connectActionSheet,
  useActionSheet,
} from "@expo/react-native-action-sheet";
import { UserContext } from "../contexts/userContext";
import {
  GetQuestionBlockHistory,
  DeleteQuestionBlockHistory,
} from "../firebase/Questions";
import { useFocusEffect } from "@react-navigation/native"; // works
import { AlertAndReportError, GetProperWidth } from "./lib";
import { Timestamp } from "firebase/firestore";
import { UseSetHeaderLeftGoBack } from "../hooks/useSetHeaderLeftGoBack";

type Props = {
  navigation: StackNavigationProp<RootStackParamList, "blocked">;
};

const BlockedScreenNoAS = ({ navigation }: Props) => {
  const window = useWindowDimensions();
  const properWidth = GetProperWidth(window);
  const widthString =
    properWidth.num === 1 && properWidth.denom === 1
      ? "w-full"
      : `w-${properWidth.num}/${properWidth.denom}`;
  const { user } = useContext(UserContext);
  const [blockedQuestions, setBlockedQuestions] = useState<Question[]>([]);
  const [loading, setLoading] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const startAfterRef = useRef(Timestamp.now().toMillis());

  UseSetHeaderLeftGoBack(navigation, () => {
    navigation.navigate("home", { screen: "account_setting", params: {} });
  });

  useFocusEffect(
    React.useCallback(() => {
      startAfterRef.current = Timestamp.now().toMillis();
      fetchQuestions(startAfterRef.current);
      return () => {
        setBlockedQuestions([]);
      };
    }, [])
  );

  const fetchQuestions = async (startAfterMilSec: number) => {
    if (user === undefined) {
      return;
    }
    if (user.id === undefined) {
      return;
    }
    setLoading(true);
    console.log(startAfterMilSec);
    const additional = await GetQuestionBlockHistory(user.id, startAfterMilSec);
    if (additional.length > 0) {
      setBlockedQuestions((blockedQuestions) => [
        ...blockedQuestions,
        ...additional,
      ]);
      startAfterRef.current = additional[additional.length - 1].blockedAtMilSec;
    }
    setLoading(false);
  };

  const onEndReached = () => {
    fetchQuestions(startAfterRef.current);
  };

  const onRefresh = async () => {
    setRefreshing(true);
    setBlockedQuestions([]);
    startAfterRef.current = Timestamp.now().toMillis();
    await fetchQuestions(startAfterRef.current).catch((e) =>
      AlertAndReportError(e, "fetchQuestions on BlockedScreen ")
    );
    setRefreshing(false);
  };

  const { showActionSheetWithOptions } = useActionSheet();
  const openActionSheet = (
    actionSheetItem: ActionSheetItem,
    question: Question
  ) => {
    const options = actionSheetItem.actions.map((x) => x.label);
    const cancelButtonIndex = actionSheetItem.cancel_index;
    const destructiveButtonIndex = actionSheetItem.destructive_index;

    showActionSheetWithOptions(
      {
        options,
        cancelButtonIndex,
        destructiveButtonIndex,
      },
      (buttonIndex) => {
        buttonIndex !== undefined &&
          actionSheetItem.actions[buttonIndex].onPress(question);
      }
    );
  };
  const actions: ActionSheetAction[] = [
    {
      label: "ブロックを解除",
      onPress: async (QorQA) => {
        setLoading(true);
        if (QorQA.id === null) {
          return;
        }
        await DeleteQuestionBlockHistory(QorQA.id)
          .catch((e) =>
            AlertAndReportError(e, "DeleteBlocks on BlockedScreen ")
          )
          .then(() =>
            setBlockedQuestions(
              blockedQuestions.filter((q) => q.id !== QorQA.id)
            )
          );
        setLoading(false);
        Analytics.logEvent("blocked", { at: "BlockedScreen" });
        ShowToast("info", "この質問者のブロックを解除しました");
      },
    },
    {
      label: "キャンセル",
      onPress: () => {},
    },
  ];
  const actionSheetItem: ActionSheetItem = {
    actions: actions,
    cancel_index: 4,
    destructive_index: 3,
  };

  return (
    // TODO fix overlap at the bottom

    <SafeAreaView style={tw("w-full items-center bg-gray-300 flex-1")}>
      <View style={tw(`bg-gray-300 h-full ${widthString} flex flex-grow`)}>
        <View style={tw("items-center m-2")}>
          <Text style={tw("text-base")}>
            過去7日間にブロックされた質問のみ表示しています。それ以前の質問ブロックは解除できません。
          </Text>
        </View>
        <FlatList
          ListFooterComponent={<View style={tw("py-4")} />}
          data={blockedQuestions}
          contentContainerStyle={{ paddingTop: 0 }}
          renderItem={({ item }) => {
            const question: Question = item;
            const checkBoxItem: CheckBoxItem = {
              isChecked: false,
              onPressCheck: () => {},
            };

            return (
              <QuestionCard
                question={question}
                isNew={false}
                checkBoxItem={checkBoxItem}
                isPressable={false}
                isCaretVisible={true}
                onPress={() => {}}
                onPressCaret={() => {
                  openActionSheet(actionSheetItem, question);
                }}
                isMultiChoiceActive={false}
                showReplyToIcon={
                  question.replyTo !== undefined && question.replyTo !== null
                }
              />
            );
          }}
          keyExtractor={(_, index) => index.toString()}
          onEndReached={onEndReached}
          refreshControl={
            <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
          }
          removeClippedSubviews={true}
        />
        {loading && <Loading />}
      </View>
    </SafeAreaView>
  );
};

export const BlockedScreen = connectActionSheet(BlockedScreenNoAS);
