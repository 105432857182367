import React from "react";
import { Text, TouchableOpacity } from "react-native";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { tailwind as tw } from "../../tailwind";

type Label = "報告" | "ブロック" | "削除" | "閉じる";

type Props = {
  onPress: () => void;
  label: Label;
  isActive: boolean;
};

export const MultiChoiceActionButton = ({
  onPress,
  label,
  isActive,
}: Props) => {
  const getIconName = (label: Label) => {
    if (label === "報告") {
      return "alert-outline";
    } else if (label === "ブロック") {
      return "cancel";
    } else if (label === "削除") {
      return "trash-can-outline";
    } else {
      return "close";
    }
  };
  return (
    <TouchableOpacity
      style={tw(
        `flex-row justify-center h-10 items-center p-2 rounded-2xl w-full ${
          isActive ? "bg-bluegray-800" : "bg-white border-2 border-bluegray-800"
        }`
      )}
      onPress={onPress}
    >
      <MaterialCommunityIcons
        name={getIconName(label)}
        size={20}
        color={isActive ? "white" : "#1E293B"}
      />
      <Text
        style={tw(
          `ml-2 text-base ${isActive ? "text-white" : "text-bluegray-800"}`
        )}
      >
        {label}
      </Text>
    </TouchableOpacity>
  );
};
