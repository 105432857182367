import React, { useState, useEffect } from "react";
import * as Notifications from "expo-notifications";
import { AppNavigator } from "./navigation/AppNavigator";
import { ActionSheetProvider } from "@expo/react-native-action-sheet";
import { RootSiblingParent } from "react-native-root-siblings";
import * as Sentry from "sentry-expo";
import { UserContext } from "./contexts/userContext";
import { User } from "./types/user";
import { getAuth } from "firebase/auth";
import * as ScreenOrientation from "expo-screen-orientation";
import { Platform, PlatformIOSStatic } from "react-native";
import { RegisterForPushNotificationsAsync } from "./screens/lib";
import { UpdateUser } from "./firebase/Users";
import "setimmediate";

Sentry.init({
  dsn: "https://1199c8a7718d42be85a81917d1497494@o1059848.ingest.sentry.io/6048821",
  enableInExpoDevelopment: true,
  debug: false,
});

export default function App() {
  useEffect(() => {
    getAuth();
  }, [getAuth().currentUser]);

  useEffect(() => {
    Notifications.setNotificationHandler({
      handleNotification: async () => ({
        shouldShowAlert: true,
        shouldPlaySound: false,
        shouldSetBadge: true,
      }),
    });
  }, []);

  useEffect(() => {
    if (Platform.OS === "ios") {
      const platformIOS = Platform as PlatformIOSStatic;
      platformIOS.isPad && ScreenOrientation.unlockAsync();
    }
  });

  useEffect(() => {
    const subscription = Notifications.addPushTokenListener(() => {
      const currentUser = getAuth().currentUser;
      if (currentUser === null) return;
      RegisterForPushNotificationsAsync().then((pushToken) => {
        if (pushToken === null || pushToken === undefined) return;
        UpdateUser(currentUser.uid, { pushToken });
      });
    });
    return () => subscription.remove();
  }, []);

  const [user, setUser] = useState<User>();

  return (
    <UserContext.Provider value={{ user, setUser }}>
      <RootSiblingParent>
        <ActionSheetProvider>
          <AppNavigator />
        </ActionSheetProvider>
      </RootSiblingParent>
    </UserContext.Provider>
  );
}
