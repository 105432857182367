import { ref, getDownloadURL, uploadBytes } from "firebase/storage";
import { storage } from "./Init";

export const UploadImage = async (uri: string, path: string) => {
  const localUri = await fetch(uri);
  const blob = await localUri.blob();
  const storageRef = ref(storage, path);
  try {
    await uploadBytes(storageRef, blob);
    const downloadUrl = await getDownloadURL(storageRef);
    return downloadUrl;
  } catch (err) {
    console.log(err);
    return "";
  }
};
