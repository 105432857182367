import React from "react";
import { View, Text } from "react-native";
import { tailwind as tw } from "../tailwind";
import Unorderedlist from "react-native-unordered-list";

type Props = {
  header: string;
  texts: string[];
};

export const Bullets = ({ header, texts }: Props) => {
  return (
    <View style={tw("w-full mb-6")}>
      <View style={tw("border-2 border-bluegray-800 mb-2")}>
        <Text
          style={tw(
            "font-bold text-xl my-1 text-bluegray-800 text-center w-full p-1"
          )}
        >
          {header}
        </Text>
      </View>
      {texts.map((text) => (
        <Unorderedlist style={tw("text-lg font-bold mb-2")}>
          <Text style={tw("text-lg mb-2")}>{text}</Text>
        </Unorderedlist>
      ))}
    </View>
  );
};
