import React, { useContext, useState, useRef, useEffect } from "react";
import {
  FlatList,
  Text,
  View,
  RefreshControl,
  useWindowDimensions,
  SafeAreaView,
} from "react-native";
import { ShowToast } from "../lib";
import { StackNavigationProp } from "@react-navigation/stack";
import * as Analytics from "expo-firebase-analytics";
import { RootStackParamList } from "../navigation/types";
import { tailwind as tw } from "../tailwind";
import { QuestionCard } from "../components/Cards/QuestionCard";
import { Loading } from "../components/Loading";
import { Question, QA } from "../types/question";
import { Block } from "../types/block";
import { CheckBoxItem } from "../types/checkBoxItem";
import { ActionSheetItem, ActionSheetAction } from "../types/actionSheet";
import {
  connectActionSheet,
  useActionSheet,
} from "@expo/react-native-action-sheet";
import { UserContext } from "../contexts/userContext";
import {
  GetQuestions,
  DeleteQuestion,
  MoveQuestionFromTrash,
} from "../firebase/Questions";
import { AddQuestionBlockHistory } from "../firebase/Questions";
import { ReportQuestion } from "../firebase/Reports";
import { useFocusEffect } from "@react-navigation/native"; // works
import { AlertAndReportError, GetProperWidth } from "./lib";
import { Timestamp } from "firebase/firestore";
import { UseSetHeaderLeftGoBack } from "../hooks/useSetHeaderLeftGoBack";

type Props = {
  navigation: StackNavigationProp<RootStackParamList, "trash">;
};

const TrashScreenNoAS = ({ navigation }: Props) => {
  const window = useWindowDimensions();
  const properWidth = GetProperWidth(window);
  const widthString =
    properWidth.num === 1 && properWidth.denom === 1
      ? "w-full"
      : `w-${properWidth.num}/${properWidth.denom}`;
  const { user } = useContext(UserContext);
  const [trashedQuestions, setTrashedQuestions] = useState<Question[]>([]);
  const [loading, setLoading] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const startAfterRef = useRef(Timestamp.now());

  UseSetHeaderLeftGoBack(navigation, () => {
    navigation.navigate("home", { screen: "account_setting", params: {} });
  });

  useFocusEffect(
    React.useCallback(() => {
      startAfterRef.current = Timestamp.now();
      fetchQuestions(startAfterRef.current);
      return () => {
        setTrashedQuestions([]);
      };
    }, [])
  );

  const fetchQuestions = async (startAfter: Timestamp) => {
    if (user === undefined) {
      return;
    }
    if (user.id === undefined) {
      return;
    }
    setLoading(true);
    const additional = (await GetQuestions(
      "questions_trashed",
      user.id,
      startAfter,
      "askedAt",
      20
    ).catch((e) => AlertAndReportError(e, "GetQuestions on TrashScreen "))) as (
      | Question
      | QA
    )[];
    if (additional.length > 0) {
      setTrashedQuestions((trashedQuestions) => [
        ...trashedQuestions,
        ...additional,
      ]);
      startAfterRef.current = additional[additional.length - 1].askedAt;
    }
    setLoading(false);
  };

  const onEndReached = () => {
    fetchQuestions(startAfterRef.current);
  };

  const onRefresh = async () => {
    setRefreshing(true);
    setTrashedQuestions([]);
    startAfterRef.current = Timestamp.now();
    await fetchQuestions(startAfterRef.current).catch((e) =>
      AlertAndReportError(e, "fetchQuestions on TrashScreen ")
    );
    setRefreshing(false);
  };

  const { showActionSheetWithOptions } = useActionSheet();
  const openActionSheet = (
    actionSheetItem: ActionSheetItem,
    question: Question
  ) => {
    const options = actionSheetItem.actions.map((x) => x.label);
    const cancelButtonIndex = actionSheetItem.cancel_index;
    const destructiveButtonIndex = actionSheetItem.destructive_index;

    showActionSheetWithOptions(
      {
        options,
        cancelButtonIndex,
        destructiveButtonIndex,
      },
      (buttonIndex) => {
        buttonIndex !== undefined &&
          actionSheetItem.actions[buttonIndex].onPress(question);
      }
    );
  };
  const actions: ActionSheetAction[] = [
    {
      label: "不適切な質問を運営に報告",
      onPress: async (QorQA) => {
        setLoading(true);
        await ReportQuestion(QorQA, "questions_trashed").catch((e) =>
          AlertAndReportError(e, "ReportQuestion on TrashScreen ")
        );
        setLoading(false);
        Analytics.logEvent("reported", { at: "TrashScreen" });
        ShowToast("info", "不適切な質問として運営に報告しました。");
      },
    },
    {
      label: "質問者をブロック",
      onPress: async (QorQA) => {
        setLoading(true);
        if (QorQA.id === null) {
          return;
        }
        await AddQuestionBlockHistory(QorQA.id, QorQA).catch((e) =>
          AlertAndReportError(e, "AddBlock on TrashScreen ")
        );
        setLoading(false);
        Analytics.logEvent("blocked", { at: "TrashScreen" });
        ShowToast("info", "この質問者をブロックしました。");
      },
    },
    {
      label: "質問をゴミ箱から出す",
      onPress: async (QorQA) => {
        //TODO fix this any later
        setLoading(true);
        const moveTrashedQuestion = async (QorQA: any) => {
          const moveTo =
            "answer" in QorQA ? "questions_answered" : "questions_unanswered";
          await MoveQuestionFromTrash(QorQA.id!, moveTo).catch((e) =>
            AlertAndReportError(e, "MoveQuestionFromTrash on TrashScreen ")
          );
        };
        await moveTrashedQuestion(QorQA)
          .then(() =>
            setTrashedQuestions(
              trashedQuestions.filter((x) => x.id !== QorQA.id)
            )
          )
          .catch((e) =>
            AlertAndReportError(e, "moveTrashedQuestion on TrashScreen ")
          );
        setLoading(false);
        Analytics.logEvent("takenOutOfTrash", { at: "TrashScreen" });
        !("answer" in QorQA) &&
          navigation.navigate("home", {
            screen: "unanswered_questions",
            params: {
              questionToAdd: QorQA.id!,
            },
          });
        ShowToast("info", "質問をゴミ箱から取り出しました。");
      },
    },
    {
      label: "質問を今すぐ消去する",
      onPress: async (QorQA) => {
        setLoading(true);
        await DeleteQuestion(QorQA.id!, "questions_trashed")
          .then(() =>
            setTrashedQuestions(
              trashedQuestions.filter((x) => x.id !== QorQA.id)
            )
          )
          .catch((e) =>
            AlertAndReportError(e, "DeleteQuestion on TrashScreen ")
          );
        setLoading(false);
        Analytics.logEvent("deleted", { at: "TrashScreen" });
        ShowToast("info", "質問を消去しました。");
      },
    },
    {
      label: "キャンセル",
      onPress: (question) => {},
    },
  ];
  const actionSheetItem: ActionSheetItem = {
    actions: actions,
    cancel_index: 4,
    destructive_index: 3,
  };

  return (
    // TODO fix overlap at the bottom

    <SafeAreaView style={tw("w-full items-center bg-gray-300 flex-1")}>
      <View style={tw(`bg-gray-300 h-full ${widthString} flex flex-grow`)}>
        <View style={tw("items-center w-full h-8 justify-center")}>
          <Text style={tw("text-base")}>
            ここへの投入後7日後に自動消去されます。
          </Text>
        </View>
        <FlatList
          ListFooterComponent={<View style={tw("py-4")} />}
          data={trashedQuestions}
          contentContainerStyle={{ paddingTop: 0 }}
          renderItem={({ item }) => {
            const question: Question = item;
            const checkBoxItem: CheckBoxItem = {
              isChecked: false,
              onPressCheck: () => {},
            };

            return (
              <QuestionCard
                question={question}
                isNew={false}
                checkBoxItem={checkBoxItem}
                isPressable={false}
                isCaretVisible={true}
                onPress={() => {}}
                onPressCaret={() => {
                  openActionSheet(actionSheetItem, question);
                }}
                isMultiChoiceActive={false}
                showReplyToIcon={
                  question.replyTo !== undefined && question.replyTo !== null
                }
              />
            );
          }}
          keyExtractor={(_, index) => index.toString()}
          onEndReached={onEndReached}
          refreshControl={
            <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
          }
          removeClippedSubviews={true}
        />
        {loading && <Loading />}
      </View>
    </SafeAreaView>
  );
};

export const TrashScreen = connectActionSheet(TrashScreenNoAS);
