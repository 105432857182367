import { User } from "../types/user";
import AsyncStorage from "@react-native-async-storage/async-storage";

export const SetUser = async (userToSave: User) => {
  await AsyncStorage.setItem("@user", JSON.stringify(userToSave));
};

export const DeleteUser = async () => {
  await AsyncStorage.removeItem("@user");
};

export const GetUser = async () => {
  const user = await AsyncStorage.getItem("@user");
  return user === null ? null : (JSON.parse(user) as User);
};
