import React from "react";
import { View, Text, Image } from "react-native";
import { tailwind as tw } from "../../tailwind";
import { User } from "../../types/user";

type Props = {
  user: User;
};

export const UserProfileTexts = ({ user }: Props) => {
  return (
    <View>
      <View style={tw("flex-row items-center")}>
        <Image
          source={{ uri: user.iconImageUrl }}
          style={tw("h-16 w-16 rounded-full")}
        />
        <View style={tw("flex-1")}>
          <Text style={tw("ml-2 text-base")}>{user.name}</Text>
        </View>
      </View>
      <Text style={tw("text-base my-2")}>{user.profile}</Text>
      <Text style={tw("text-base my-2")}>{user.message}</Text>
    </View>
  );
};
