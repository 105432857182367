import React from "react";
import { TouchableOpacity, Text, Animated } from "react-native";
import { tailwind as tw } from "../../tailwind";

type Props = {
  onPress: () => void;
  buttonTranslationX: any;
};

export const ExistsUpdateButton = ({ onPress, buttonTranslationX }: Props) => {
  return (
    <Animated.View
      style={{
        ...tw("flex-row w-full justify-center absolute z-10 mt-5"),
        transform: [{ translateY: buttonTranslationX }],
      }}
    >
      <TouchableOpacity
        style={{
          ...tw("bg-bluegray-800 rounded-full border border-bluegray-800"),
          shadowColor: "#ccc",
          shadowOffset: {
            width: 0,
            height: 5,
          },
          shadowRadius: 5,
          shadowOpacity: 1,
          elevation: 2,
        }}
        onPress={() => onPress()}
      >
        <Text style={tw("text-white text-center text-base font-bold m-2")}>
          新着の質問があります
        </Text>
      </TouchableOpacity>
    </Animated.View>
  );
};
