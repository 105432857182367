import React from "react";
import { TouchableOpacity, Text } from "react-native";
import { tailwind as tw } from "../../tailwind";

type Props = {
  label: string;
  onPress: () => void;
};

export const SubmitButtonInactive = ({ label, onPress }: Props) => {
  return (
    <TouchableOpacity
      style={tw("flex-1 h-12 m-2 bg-white rounded-full justify-center")}
      onPress={() => onPress()}
    >
      <Text style={tw("text-base p-2 font-bold text-center text-bluegray-800")}>
        {label}
      </Text>
    </TouchableOpacity>
  );
};
