import React, { useContext, useEffect, useState } from "react";
import {
  View,
  ScrollView,
  SafeAreaView,
  useWindowDimensions,
  Platform,
} from "react-native";
import { StackNavigationProp } from "@react-navigation/stack";
import * as Analytics from "expo-firebase-analytics";
import { ShowToast } from "../lib";
import {
  AlertAndReportError,
  CreateDmAlert,
  GenerateUserPath,
  GetProperWidth,
  OnPressShare,
  RegisterPushToken,
  ShareLinkOnTwitter,
} from "./lib";
import { AccountListItem } from "../components/AccountListItem";
import { TwitterShareButton } from "../components/Buttons/TwitterShareButton";
import { UserProfileTexts } from "../components/Texts/UserProfileTexts";
import { Loading } from "../components/Loading";
import { RootStackParamList } from "../navigation/types";
import { RouteProp } from "@react-navigation/core";
import { tailwind as tw } from "../tailwind";
import * as db_user from "../firebase/Users";
import * as storage_user from "../local_storage/user";
import * as storage_all from "../local_storage/all";
import { UserContext } from "../contexts/userContext";
import { useScrollToTop } from "@react-navigation/native";
import { DialogBoxWithUpToTwoOptions } from "../components/DialogBoxWithUpToTwoOptions";
import { ProfileLinkCopyButton } from "../components/Buttons/ProfileLinkCopyButton";
import { User } from "../types/user";
import * as Clipboard from "expo-clipboard";
import * as Linking from "expo-linking";
import { useFocusEffect } from "@react-navigation/native"; // works
import { QuestionCountListItem } from "../components/QuestionCountListItem";
import Constants from "expo-constants";
import {
  CheckHasEnoughFollowers,
  GenerateAccoutDashboardLink,
  GetLastMembershipEndAtMilSec,
} from "../firebase/Functions";
import { ExistsTipsSetting, GetTipsSetting } from "../firebase/TipsSettings";
import { GetEmailAddressVerified } from "../firebase/EmailAddresses";
import { StripeSettingType } from "../types/stripeSettingType";
import dayjs from "dayjs";
import { ScheduleLastMembershipEndEmailNotif } from "../firebase/LastMembershipEndEmailNotif";
import { signOut } from "firebase/auth";
import { auth } from "../firebase/Init";
import { GetMultiAccountAuth } from "../firebase/MultiAccountAuthes";

type settingListItem = {
  iconName: string;
  title: string;
  onPress: () => void;
};

type Props = {
  navigation: StackNavigationProp<RootStackParamList, "account_setting">;
  route: RouteProp<RootStackParamList, "account_setting">;
};

export const AccountScreen = ({ navigation, route }: Props) => {
  const [isTerminateAlertShown, setIsTerminateAlertShown] = useState(false);
  const window = useWindowDimensions();
  const properWidth = GetProperWidth(window);
  const widthString =
    properWidth.num === 1 && properWidth.denom === 1
      ? "w-full"
      : `w-${properWidth.num}/${properWidth.denom}`;
  const [loading, setLoading] = useState(false);
  const [answeredCount, setAnsweredCount] = useState<number>();
  const [unansweredCount, setUnansweredCount] = useState<number>();
  const [signedUpOnTips, setSignedUpOnTips] = useState(false);
  const [screenName, setScreenName] = useState("");
  const [isTipsUpdateShareAlertShown, setIsTipsUpdateShareAlertShown] =
    useState(false);
  const [tipsUpdateShareAlertType, setTipsUpdateShareAlertType] = useState<
    | undefined
    | "acceptFree"
    | "acceptPublicAndPrivate"
    | "acceptPrivate"
    | "acceptPublic"
  >();
  const { user, setUser } = useContext(UserContext);

  useFocusEffect(
    React.useCallback(() => {
      if (user === undefined) {
        return;
      }
      db_user.GetUserActive(user.id).then(async (thisUser) => {
        if (thisUser === undefined) {
          return;
        }
        setAnsweredCount(thisUser.countAnswered);
        setUnansweredCount(thisUser.countUnanswered);
        setUser(thisUser);
        ExistsTipsSetting(thisUser.id).then((exists) =>
          setSignedUpOnTips(exists)
        );
      });
    }, [])
  );

  useEffect(() => {
    setIsTipsUpdateShareAlertShown(
      route.params.showTipsUpdateShareAlertType !== undefined
    );
    setTipsUpdateShareAlertType(route.params.showTipsUpdateShareAlertType);
  }, [route.params.showTipsUpdateShareAlertType]);

  const navigateToSetting = async (stripeSettingType: StripeSettingType) => {
    if (user === undefined) {
      alert("失敗しました。ログアウト後に再ログインしてお試しください。");
      return;
    }
    const userId = user.id;
    if (userId === undefined) {
      alert("失敗しました。ログアウト後に再ログインしてお試しください。");
      return;
    }
    const tipsSetting = await GetTipsSetting(userId);
    if (tipsSetting !== undefined) {
      navigation.navigate(`${stripeSettingType}_settings`);
      return;
    }
    const emailAddress = await GetEmailAddressVerified(userId);
    if (emailAddress !== undefined) {
      navigation.navigate(`${stripeSettingType}_stripe_registration`);
      return;
    }
    const multiAccountAuth = await GetMultiAccountAuth(user.twitterId);
    if (multiAccountAuth === undefined) {
      alert("失敗しました。ログアウト後に再ログインしてお試しください。");
      return;
    }
    const hasEnoughFollowers = await CheckHasEnoughFollowers(multiAccountAuth);
    if (hasEnoughFollowers === false) {
      alert(
        "本機能は1,000人以上のフォロワーを持つTwitterアカウントに限定して開放されています。"
      );
      return;
    }
    navigation.navigate(`${stripeSettingType}_email_registration`);
    return;
  };

  const profile: settingListItem = {
    iconName: "account-outline",
    title: "プロフィール設定",
    onPress: () => {
      navigation.navigate("profile");
    },
  };

  const detail: settingListItem = {
    iconName: "cog-outline",
    title: "詳細設定",
    onPress: () => {
      navigation.navigate("detail_setting");
    },
  };
  const reactivatePush: settingListItem = {
    iconName: "cog-outline",
    title: "プッシュ通知再有効化",
    onPress: async () => {
      user !== undefined &&
        (await RegisterPushToken(user).then(() =>
          ShowToast("success", "再有効化しました。")
        ));
    },
  };

  const tips_settings: settingListItem = {
    iconName: "cash-multiple",
    title: "Querie Tips設定",
    onPress: async () => {
      setLoading(true);
      await navigateToSetting("tips").finally(() => setLoading(false));
    },
  };

  const limited_setting: settingListItem = {
    iconName: "cash-multiple",
    title: "Querie Limited設定",
    onPress: async () => {
      setLoading(true);
      navigateToSetting("limited").finally(() => setLoading(false));
    },
  };

  const payout_schedule: settingListItem = {
    iconName: "cash-multiple",
    title: "収益支払い予定",
    onPress: async () => {
      setLoading(true);
      if (user === undefined) {
        alert("失敗しました。ログアウト後に再ログインしてお試しください。");
        return;
      } else if (user.id === undefined) {
        alert("失敗しました。ログアウト後に再ログインしてお試しください。");
        return;
      }
      navigation.navigate("payout");
      setLoading(false);
    },
  };

  const revenue_dashboard: settingListItem = {
    iconName: "cash-multiple",
    title: "収益実績確認",
    onPress: async () => {
      setLoading(true);
      const dashboardUrl = await GenerateAccoutDashboardLink();
      Linking.openURL(dashboardUrl.response);
      setLoading(false);
    },
  };

  const blocked: settingListItem = {
    iconName: "block-helper",
    title: "最近のブロック",
    onPress: () => {
      navigation.navigate("blocked");
    },
  };

  const trash: settingListItem = {
    iconName: "trash-can-outline",
    title: "ゴミ箱",
    onPress: () => {
      navigation.navigate("trash");
    },
  };

  const inquiry: settingListItem = {
    iconName: "email-outline",
    title: "問い合わせ",
    onPress: async () => {
      setLoading(true);
      const canOpenUrl = await Linking.canOpenURL(
        "twitter://messages/compose?recipient_id=1440949863097585665"
      ).catch((e) => {
        AlertAndReportError(e, "canOpenUrl on AcountScreen");
        return false;
      });
      setLoading(false);
      CreateDmAlert(canOpenUrl);
    },
  };

  const terms: settingListItem = {
    iconName: "file-document-outline",
    title: "利用規約",
    onPress: () => {
      Linking.openURL(`https://${Constants.manifest?.extra!.domain}/terms`);
    },
  };

  const privacy: settingListItem = {
    iconName: "file-document-outline",
    title: "プライバシーポリシー",
    onPress: () => {
      Linking.openURL(
        `https://${Constants.manifest?.extra!.domain}/privacy_policy`
      );
    },
  };

  const logout: settingListItem = {
    iconName: "logout-variant",
    title: "ログアウト",
    onPress: async () => {
      setLoading(true);
      await storage_user.DeleteUser().catch((e) => {
        AlertAndReportError(e, "DeleteUser on AcountScreen");
        return false;
      });
      await signOut(auth);
      setUser(undefined);
      navigation.navigate("home", { screen: "top" });
      setLoading(false);
      Analytics.logEvent("logout");
      ShowToast("info", `ログアウトしました。`);
    },
  };

  const terminate: settingListItem = {
    iconName: "account-cancel-outline",
    title: "退会",
    onPress: async () => {
      const userId = user?.id;
      if (userId === undefined) {
        alert("失敗しました。ログアウト後に再ログインしてお試しください。");
      } else {
        setLoading(true);
        const tipsSetting = await GetTipsSetting(userId);
        if (tipsSetting?.limitedPriceId !== undefined) {
          alert(
            "退会する前にQuerie Limitedを閉鎖する必要があります。\n\n「Querie Limited設定」からLimited閉鎖をお願いします。"
          );
        } else {
          const lastMembershipEndAtMilSecRes =
            await GetLastMembershipEndAtMilSec();
          const lastMembershipEndAtMilSec = lastMembershipEndAtMilSecRes.data;
          if (lastMembershipEndAtMilSec === null) {
            setIsTerminateAlertShown(true);
          } else {
            await ScheduleLastMembershipEndEmailNotif(
              userId,
              lastMembershipEndAtMilSec
            )
              .then(() => {
                const lastDayOfMembershipEnd = dayjs(
                  lastMembershipEndAtMilSec
                ).format("YYYY年MM月DD日");
                alert(
                  `退会する前に現在有効なQuerie Limited加入契約がすべて満了する必要があります。\n\n${lastDayOfMembershipEnd}に全ての加入者の契約が満了する予定です。満了後にご登録頂いたメールアドレスへお知らせのメールをお送りしますので、メール受領後に再度退会のお手続きをお願いいたします。`
                );
              })
              .catch((e) =>
                alert(
                  `エラーが発生しました。下記エラー文を添えて運営までお問い合わせください。\n${e}`
                )
              )
              .finally(() => setLoading(false));
          }
        }
        setLoading(false);
      }
    },
  };

  const topPart: settingListItem[] = [
    profile,
    detail,
    tips_settings,
    limited_setting,
  ];
  const onlyForStripeActivated: settingListItem[] = [
    payout_schedule,
    revenue_dashboard,
  ];
  const bottomPart: settingListItem[] = [
    blocked,
    trash,
    inquiry,
    terms,
    privacy,
    logout,
    terminate,
  ];
  const listItems: settingListItem[] =
    signedUpOnTips === true
      ? Platform.OS === "ios" && user?.pushAllowed === true
        ? [...topPart, reactivatePush, ...onlyForStripeActivated, ...bottomPart]
        : [...topPart, ...onlyForStripeActivated, ...bottomPart]
      : Platform.OS === "ios" && user?.pushAllowed === true
      ? [...topPart, reactivatePush, ...bottomPart]
      : [...topPart, ...bottomPart];

  const terminateUser = async () => {
    setLoading(true);
    await db_user
      .MoveUser(user!.id!, "users_active", "users_terminated")
      .catch((e) => {
        AlertAndReportError(e, "MoveUser on AcountScreen");
        return false;
      });
    await storage_all.Clear().catch((e) => {
      AlertAndReportError(e, "Clear on AcountScreen");
      return false;
    });
    navigation.navigate("home", { screen: "top" });
    setUser(undefined);
    setLoading(false);
    Analytics.logEvent("accountTerminated");
    ShowToast("info", "退会しました。");
    setIsTerminateAlertShown(false);
  };
  const ref = React.useRef(null);
  useScrollToTop(ref);
  const OnPressProfileLinkCopy = async (user: User) => {
    const beforeId = `https://${Constants.manifest?.extra!.domain}/user/`;
    const userPath = await GenerateUserPath(user);
    await Clipboard.getStringAsync()
      .then(() => {
        Clipboard.setString(beforeId + userPath);
        ShowToast("success", "募集URLをクリップボードにコピーしました");
      })
      .catch(() =>
        ShowToast(
          "error",
          "ご利用の環境ではコピーできません。機能を利用するにはアプリ版をご利用ください。"
        )
      );
  };

  return (
    <SafeAreaView style={tw("w-full items-center bg-gray-300 flex-1")}>
      <ScrollView style={tw(`${widthString} flex flex-grow`)} ref={ref}>
        <View style={tw("mx-4 mt-4 mb-2 p-4 bg-white")}>
          <UserProfileTexts user={user!} />
          <TwitterShareButton onPress={OnPressShare} user={user!} />
          <ProfileLinkCopyButton
            onPress={OnPressProfileLinkCopy}
            user={user!}
          />
        </View>
        <QuestionCountListItem
          label="未回答質問数"
          count={unansweredCount}
          iconName={"comment-question-outline"}
        />
        <QuestionCountListItem
          label="回答済質問数"
          count={answeredCount}
          iconName={"comment-arrow-right-outline"}
        />
        {listItems.map((x, idx) => (
          <AccountListItem key={idx} {...x} />
        ))}
      </ScrollView>
      <DialogBoxWithUpToTwoOptions
        title={"本当に退会しますか？"}
        message={
          "退会するとこれまでの回答がすべて削除され、誰からも一切閲覧できなくなります。よろしいですか？"
        }
        widthString={widthString}
        buttons={[
          {
            label: "退会する",
            onPress: async () => {
              await terminateUser();
              setIsTerminateAlertShown(false);
            },
          },
          {
            label: "退会しない",
            onPress: async () => {
              setIsTerminateAlertShown(false);
            },
          },
        ]}
        isShown={isTerminateAlertShown}
      />
      <DialogBoxWithUpToTwoOptions
        title={"設定更新をお知らせしますか？"}
        message={"フォロワーの皆さんにTips設定の変更をお知らせしますか？"}
        widthString={widthString}
        buttons={[
          {
            label: "お知らせする",
            onPress: async () => {
              user !== undefined &&
                tipsUpdateShareAlertType !== undefined &&
                (await ShareLinkOnTwitter(
                  user,
                  tipsUpdateShareAlertType === "acceptFree"
                    ? "無料質問の受付を開始しました！"
                    : tipsUpdateShareAlertType === "acceptPublicAndPrivate"
                    ? "有料Tips質問と非公開質問の受付を開始しました！"
                    : tipsUpdateShareAlertType === "acceptPublic"
                    ? "有料Tips質問の受付を開始しました！"
                    : "非公開質問の受付を開始しました！"
                ));
              setIsTipsUpdateShareAlertShown(false);
            },
          },
          {
            label: "しない",
            onPress: () => {
              setIsTipsUpdateShareAlertShown(false);
            },
          },
        ]}
        isShown={isTipsUpdateShareAlertShown}
      />
      {loading && <Loading />}
    </SafeAreaView>
  );
};
