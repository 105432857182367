import React from "react";
import { Linking, Text, View } from "react-native";
import { tailwind as tw } from "../../tailwind";
import { CheckBox } from "react-native-elements";
import Constants from "expo-constants";

type props = {
  isChecked: boolean;
  onCheckPressed: (isChecked: boolean) => void;
};

export const AgreeToTermsCheckbox = ({ isChecked, onCheckPressed }: props) => {
  return (
    <View style={tw("flex-row items-center")}>
      <CheckBox
        checkedColor="black"
        uncheckedColor="black"
        iconType="ionicon"
        checkedIcon="checkmark-circle-outline"
        uncheckedIcon="ellipse-outline"
        size={24}
        checked={isChecked}
        onPress={() => {
          isChecked ? onCheckPressed(false) : onCheckPressed(true);
        }}
      />
      <Text
        onPress={() =>
          Linking.openURL(`https://${Constants.manifest?.extra!.domain}/terms`)
        }
        style={tw(
          "justify-center items-center text-lg leading-10 -ml-4 underline font-bold"
        )}
      >
        利用規約
      </Text>
      <Text style={tw("justify-center items-center text-lg leading-10")}>
        に同意する。
      </Text>
    </View>
  );
};
