import { DialogBoxWithUpToTwoOptions } from "../DialogBoxWithUpToTwoOptions";

type Props = {
  widthString: string;
  onPresses: (() => void)[];
  isShown: boolean;
};

export const ApiPostRateLimitedHitDialog = ({
  widthString,
  onPresses,
  isShown,
}: Props) => (
  <DialogBoxWithUpToTwoOptions
    title={"ツイート上限に達しました"}
    widthString={widthString}
    message={[
      "一時的にQuerie.meからのツイート上限に達したため、下記ボタンいずれかを押したあとに遷移するTwitterアプリ/ウェブサイトからのツイートをお願いいたします。",
      "(制限は早ければ数分で解除され、解除後はアプリから直接ツイート出来るようになります。)\n",
      "\n【!注意!】現在Twitterアプリ/ウェブサイトでログインしているアカウントでのツイート画面が表示されます。",
      "遷移後の画面左上のアイコンを確認の上、もし切り替える場合はアイコンをタップ/クリックするとアカウントを切り替えることができます。",
    ].join("\n")}
    buttons={[
      {
        label: "　　ＯＫ　　",
        onPress: onPresses[0],
      },
      {
        label: "以後表示しない",
        onPress: onPresses[1],
      },
    ]}
    isShown={isShown}
  />
);
